import React, { useState, useMemo } from 'react';
import { 
    Flex,
    Grid,
    Box,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
 } from "@chakra-ui/react";
 import { BsSearch, BsXLg } from "react-icons/bs";

 import { useTranslation } from 'react-i18next';

 import { cdnUrl } from '../utils';

 import './fuluList.css';  // 引入 CSS 文件

 interface ImageData {
    src: string;
    title: string;
    description: string;
  }

  
function FuluList() {
  const { t, i18n } = useTranslation();

  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNum, setSelectedNum] = useState(0)

  const [searchValue, setSearchValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // 示例图片数据
  const pngIndices = useMemo(() => [25, 26, 118], []); // Memoize the array

  const images = Array(250).fill(null).map((_, index) => ({
    src: `https://fulutalisman.com/image/fulu2/FuluTalisman_${index + 1}${pngIndices.includes(index + 1) ? '.png' : '.jpg'}`,
    title: t(`fuluTitle${index + 1}`),
    description: t(`fuluDes${index + 1}`),
    number: index + 1
   }));

  const filteredImages = images.filter((_, index) => {
    if (searchQuery === '') return true;
    const searchNumber = parseInt(searchQuery);
    const mappedIndex = searchNumber > 250 ? 
      ((searchNumber - 1) % 250) + 1 : 
      searchNumber;
    return (index + 1) === mappedIndex;
   });

  // 处理搜索输入
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 只允许输入数字
    const value = e.target.value.replace(/[^0-9]/g, '');
    setSearchValue(value);
  };

  // 处理搜索按钮点击
  const handleSearch = () => {
    setSearchQuery(searchValue);
  };

  // 处理回车键搜索
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // 清空输入框
  const handleClearSearch = () => {
    setSearchValue('');
    setSearchQuery('');  // 清空搜索条件
  };

  const handleImageClick = (image: any, num: any) => {
    setSelectedImage(image);
    setIsModalOpen(true);
    setSelectedNum(num)
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const downloadImagetest = async(imageSrc: any) => {
    try {
        console.log(imageSrc)
        const response = await fetch(imageSrc);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = imageSrc.split('/').pop() || 'image';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('download error:', error);
      }
  };

  const downloadImage = async (imageSrc: string) => {
    try {
      console.log(imageSrc)
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = imageSrc.split('/').pop() || 'image';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('download error:', error);
    }
  };
  
  const flexWidth = i18n.language === 'zh' 
  ? '425px'   // 中文时的宽度
  : '535px';  // 英文时的宽度

    return (
        <Flex
            bgImage={`url(${cdnUrl}/fululist.svg)`}
            bgPosition="top"
            bgRepeat="no-repeat"
            bgSize="cover"
            //justifyContent='center'
            overflow="hidden"
            alignItems='center'
            direction='column'
            pt='5'
            className="expand-animation"
        >
            <Flex
                width={flexWidth}
                alignItems='center'
            >
                <Flex 
                    width="100%" 
                    justify="center" 
                >
                <Text
                    fontSize={{ '3xl': '36px', base: '36px' }}
                    fontWeight="700"
                    color="#E4D5BF"
                >
                    {t('fululist')}
                </Text>
                </Flex>
                {/*
                <Flex 
                    width="100%"
                    alignItems='center'
                    //justify="center"
                >
                
                <Text
                    fontSize="23px"
                    fontWeight="300"
                    fontFamily="'Times New Roman', Times, serif"
                    color="#E4D5BF"
                    marginLeft="auto" 
                    paddingLeft="4px" 
                >
                    Coming Soon
                </Text>
                </Flex>
                */}
            </Flex>
            <Flex
                mb='0'
                direction='column'
                mt={{ '3xl': '7', base: '3' }}
                width={{ '3xl': '1069.24px', base: '875px' }}
                height={{ '3xl': '622.24px', base: '505px' }}
                bgColor='white'
                //borderRadius="md"
            >
                <Flex
                    justifyContent='center'
                    bgColor='none'
                    pr='5'
                >
                    <InputGroup 
                        mt='2'
                        mb='2'
                        size="md" 
                        width='96%'
                        //maxWidth="300px"
                    >
                        <InputLeftElement pointerEvents="none">
                            <BsSearch />
                        </InputLeftElement>
                        <Input
                            placeholder={t(`inputSearch`)}
                            value={searchValue}
                            onChange={handleSearchChange}
                            onKeyPress={handleKeyPress}
                            borderColor="#FBC647"
                            _hover={{ borderColor: 'gray.400' }}
                            _focus={{ borderColor: 'blue.500', boxShadow: 'outline' }}
                        />
                        
                        <InputRightElement width="10.5rem">
                        <Button 
                            width='153px'
                            size="sm" 
                            onClick={handleSearch}
                            bgColor="#116682"
                            color='white'
                            _hover={{
                                bgColor: '#0D4F63'  // 这是一个稍深的蓝色
                            }}
                            fontSize={{ '3xl': '18px', base: '15px' }}
                        >
                            {t('search')}
                        </Button>
                        <Button
                            size="sm"
                            onClick={handleClearSearch}
                            variant="link"
                            color="black"
                            _hover={{ color: 'black' }}
                            >
                            <BsXLg />
                        </Button>
                        </InputRightElement>
                    </InputGroup>
                </Flex>
                <Grid
                    templateColumns="repeat(10, 1fr)"
                    gap={4}
                    p={4}
                    bg="white"
                    //borderRadius="md"
                    //border="1px solid black"
                    overflow="hidden"
                    overflowY="auto"
                >
                    {filteredImages.map((image) => (
                        <Box 
                            key={image.number}
                            cursor="pointer"
                            transition="transform 0.2s"
                            _hover={{ transform: 'scale(1.05)' }}
                            onClick={() => handleImageClick(image, image.number)}
                        >
                            <Image
                            src={image.src}
                            alt={image.title}
                            width="100%"
                            height="auto"
                            borderRadius="md"
                            />
                            <Flex justifyContent="center">
                            <Text>#{image.number}</Text>
                            </Flex>
                        </Box>
                    ))}
                </Grid>

                <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="xl" isCentered>

                    <ModalOverlay />
                    <ModalContent>
                    <ModalCloseButton 
                        color="#FFB84D" 
                        top="35px"    
                        right="35px" 
                        fontSize="20px" 
                        _hover={{
                            color: "red.500" 
                        }}/>
                    <ModalBody 
                        bgColor="#0C4D62"
                        justifyContent="center"
                        alignItems="center"
                        bgImage="url('/Home/fuluList/fuluMask.svg')"
                        bgPosition="top"
                        bgSize="cover"
                    >
                        <Flex
                            height="600px"
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Image
                                src={selectedImage?.src}
                                alt={selectedImage?.title}
                                width="200px"
                                height="200px"
                            />
                                <Text 
                                    color="#E4D5BF"
                                     
                                    fontSize="18px" 
                                    lineHeight="20.12px"
                                    letterSpacing="0.004em"
                                    mt="10"
                                    mb={4}
                                >
                                    #{selectedNum} {selectedImage?.title}
                                </Text>
                                <Text
                                    textAlign="justify"    
                                    width='480px'
                                    color="#E4D5BF"
                                    fontSize="14px"
                                    mb="5"
                                    sx={{ whiteSpace: 'pre-line' }}
                                >{selectedImage?.description}</Text>
                            <Button 
                                width="175px"
                                height="39px"
                                leftIcon={<Image src="/Home/DownIcon.svg" alt="Icon" boxSize="24px" />}
                                color="#043848"
                                bgColor="#C6AF8D"
                                onClick={() => downloadImagetest(selectedImage?.src)}
                                >{t('downLoadFulu')}</Button>
                        </Flex>
                    </ModalBody>
                    </ModalContent>
                </Modal>
            </Flex>
            <Flex mt='12' zIndex="10">
                <Text 
                    fontFamily="楷体"
                    fontSize={{ '3xl': '24px', base: '22px' }}
                    fontWeight="700"
                    color="#E4D5BF"
                    as="a"
                    href="http://graphmultiverse.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
                    _hover={{
                        transform: 'scale(1.1)',
                        filter: 'brightness(1.1) contrast(1.1)'  
                    }}
                    _active={{
                        transform: 'scale(0.95)', 
                    }}
                    >
                    {t('learnMore')} &#10148;
                </Text>
            </Flex>
        </Flex>
    )
}

export default FuluList;
