import React, { useState } from 'react';
import { 
    Flex, 
    Image,
    Text,
    Box,
    Button,
    AspectRatio,
 } from "@chakra-ui/react";

import { useTranslation } from 'react-i18next';

import FuluList from "../../../components/FuluList";

import { cdnUrl } from '../../../utils';

import Blueprint from '../../Blueprint';

function ContentThree() {

  const { t, i18n } = useTranslation();

  const [isImageVisible, setIsImageVisible] = useState(false);

  // 点击处理函数
  const handleClick = () => {
    setIsImageVisible(false);
  };

  const flexWidth = i18n.language === 'zh' 
  ? '425px'   // 中文时的宽度
  : '535px';  // 英文时的宽度
  
    return (
        <Flex
          width='100%'
          height='200vh'
          //backgroundImage='/Home/bg.svg'
          bgImage={`url(${cdnUrl}/BG2-1.svg)`}
          bgPosition="top"
          bgRepeat="no-repeat"
          bgSize="cover"
          //justifyContent='center'
          overflow="hidden"
          alignItems='center'
          direction='column'
        >
          <Blueprint />
          <Flex
            width='100%'
            height='100vh'
            position="relative"
            overflow="hidden"
            alignItems='center'
            justifyContent="center"
            direction='column'
          >
            <Box
              position="absolute"
              bottom="0"
              left="0"
              width="100%"
              height="80%"
              bgImage="url('/Home/Mask2.svg')"
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="cover"
            />
            <Flex
              pt={{ '3xl': '0', base: '6' }}
              width="100%"
              alignItems='center'
              justifyContent='center'
              direction='column'
            >
              {/*
              <Flex mb={{ '3xl': '2', base: '0' }}>
                <Button
                  bg="transparent" 
                  border="1px solid #E4D5BF"  
                  color="#E4D5BF" 
                  _hover={{
                    bg: "rgba(255, 165, 0, 0.1)",  
                    borderColor: "#F6AA5F", 
                  }}
                  onClick={() => navigate('/test')}
                >{t('makeBut')} &#10148;</Button>
              </Flex>
              */}
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                zIndex={1}
                cursor="pointer"
                onClick={() => {
                  handleClick()
                  console.log('Image clicked');
                }}
              >
                {isImageVisible ? (
                  <Flex
                    width='500px'
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Flex
                      width={flexWidth}
                      alignItems='end'
                      position="relative"
                    >
                      <Flex 
                        width="100%" 
                        justify="center" 
                        position="absolute" 
                      >
                        <Text
                          fontSize="36px"
                          fontWeight="700"
                          color="#E4D5BF"
                        >
                          {t('fululist')}
                        </Text>
                      </Flex>
                      <Flex 
                        width="100%"
                        alignItems='center'
                        //justify="center"
                      >
                        <Text
                          fontSize="23px"
                          fontWeight="300"
                          fontFamily="'Times New Roman', Times, serif"
                          color="#E4D5BF"
                          marginLeft="auto" 
                          paddingLeft="4px" 
                        >
                          Coming Soon
                        </Text>
                      </Flex>
                    </Flex>
                    <Image 
                      width={{ '3xl': '464px', base: '410px' }}
                      height={{ '3xl': '687px', base: '500px' }}
                      src="/Home/fuluImg.svg" 
                      transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
                      _hover={{
                        transform: 'scale(1.1)',
                        filter: 'brightness(1.1) contrast(1.1)'  
                      }}
                      _active={{
                        transform: 'scale(0.95)', 
                      }}
                    />
                    <Flex mt='2' zIndex="10">
                      <Text 
                        fontFamily="楷体"
                        fontSize={{ '3xl': '24px', base: '22px' }}
                        fontWeight="700"
                        color="#E4D5BF"
                        as="a"
                        href="http://graphmultiverse.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
                        _hover={{
                            transform: 'scale(1.1)',
                            filter: 'brightness(1.1) contrast(1.1)'  
                        }}
                        _active={{
                            transform: 'scale(0.95)', 
                        }}
                        >
                        {t('learnMore')} &#10148;
                      </Text>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex 
                    width={{ '3xl': '1250px', base: '1020px' }}
                    height={{ '3xl': '821.24px', base: '673.24px' }}
                  >
                    <FuluList />
                  </Flex>
                )}
              </Flex>
              
            </Flex>
            {/*
            <Flex 
              pr="3"
              width="100%"
              justifyContent="flex-end"
              position="absolute" 
              bottom={{ '3xl': '8px', base: '6px' }}
            >
              <Text color="#E4D5BF">
                Company Address:WeWork 21 Collyer Quay 
                21 Collyer Quay, Singapore 049320
              </Text>
            </Flex>
            */}
          </Flex>
        </Flex>
    )
}

export default ContentThree;