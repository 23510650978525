export const blueprint = {
    en: {
      translation: {
        "makeBut": "make memes",
        "luckTitle": "Bring good luck to your Collection",
        "luckTitleOne": "additional mode",
        "luckTitleTwo": "basemap mode",
        "uploadPhoto": "Upload your image",
        "uploadPhotoTwo": "Step2: Upload your image",
        "uploadPhotoTwo2": "(the system automatically removes the background)",
        "uploadStep2": "Upload image",
        "reset": "Reset",
        "addText": "AddText",
        "downloadPhoto": "Save",
        "bgOne": "Square",
        "bgTwo": "Rectangle",
        "selectFulu": "Choose talisman",
        "textInput": "Text Input",
        "inputText": "Input Text",
        "selectFonts": "Select Fonts",
        "inputNum": "Input Number",
        "selectTag": "Select Tag",
        "Tag1": "cute",
        "Tag2": "festive",
        "Tag3": "funny",
        "errorsTitle": "error message",
        "errors": "An error occurred during uploading",
        "srrors2": "File size over 10MB",
        "errorsClose": "confirmed ",
      }
    },
    zh: {
      translation: {
        "makeBut": "制作 memes",
        "luckTitle": "给您的收藏请符",
        "luckTitleOne": "附加模式",
        "luckTitleTwo": "底图模式",
        "uploadPhoto": "上传您的图片",
        "uploadPhotoTwo": "Step2: 上传您的图片（系统自动去除背景）",
        "uploadPhotoTwo2": "",
        "uploadStep2": "上传图片",
        "reset": "重置",
        "addText": "添加文字",
        "downloadPhoto": "下载图片",
        "bgOne": "正方形",
        "bgTwo": "矩形",
        "selectFulu": "选择符箓",
        "textInput": "文本输入",
        "inputText": "输入文字",
        "selectFonts": "选择字体",
        "inputNum": "输入编号",
        "selectTag": "选择标签",
        "Tag1": "可爱",
        "Tag2": "节日",
        "Tag3": "搞笑",
        "errorsTitle": "错误提示",
        "errors": "上传过程中发生错误",
        "srrors2": "文件大小超过 10MB",
        "errorsClose": "确认",
      }
    }
  };