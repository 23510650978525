export const tags = {
    "en": {
        "translation": {
            "tags1": "Career Success",
            "tags2": "Health & Wellness",
            "tags3": "Peace of Mind",
            "tags4": "Protection & Evil Warding",
            "tags5": "Relationships",
            "tags6": "Wealth Attraction",
            "tags7": "Luck Enhancement"
        }
    },
    "zh": {
        "translation": {
            "tags1": "事业类",
            "tags2": "健康类",
            "tags3": "安神类",
            "tags4": "平安驱邪类",
            "tags5": "情感类",
            "tags6": "招财类",
            "tags7": "转运类"
        }
    }
};
  
export const fuluTags = {
    "en": {
      "translation": {
        "fuluTags1": "Wealth Attraction",
        "fuluTags2": "Protection & Evil Warding",
        "fuluTags3": "Protection & Evil Warding",
        "fuluTags4": "Protection & Evil Warding",
        "fuluTags5": "Wealth Attraction",
        "fuluTags6": "Health & Wellness",
        "fuluTags7": "Relationships",
        "fuluTags8": "Protection & Evil Warding",
        "fuluTags9": "Protection & Evil Warding",
        "fuluTags10": "Relationships",
        "fuluTags11": "Career Success",
        "fuluTags12": "Protection & Evil Warding",
        "fuluTags13": "Protection & Evil Warding",
        "fuluTags14": "Protection & Evil Warding",
        "fuluTags15": "Protection & Evil Warding",
        "fuluTags16": "Protection & Evil Warding",
        "fuluTags17": "Peace of Mind",
        "fuluTags18": "Health & Wellness",
        "fuluTags19": "Luck Enhancement",
        "fuluTags20": "Luck Enhancement",
        "fuluTags21": "Health & Wellness",
        "fuluTags22": "Protection & Evil Warding",
        "fuluTags23": "Wealth Attraction",
        "fuluTags24": "Health & Wellness",
        "fuluTags25": "Protection & Evil Warding",
        "fuluTags26": "Protection & Evil Warding",
        "fuluTags27": "Protection & Evil Warding",
        "fuluTags28": "Peace of Mind",
        "fuluTags29": "Protection & Evil Warding",
        "fuluTags30": "Protection & Evil Warding",
        "fuluTags31": "Wealth Attraction",
        "fuluTags32": "Protection & Evil Warding",
        "fuluTags33": "Career Success",
        "fuluTags34": "Wealth Attraction",
        "fuluTags35": "Protection & Evil Warding",
        "fuluTags36": "Protection & Evil Warding",
        "fuluTags37": "Protection & Evil Warding",
        "fuluTags38": "Protection & Evil Warding",
        "fuluTags39": "Protection & Evil Warding",
        "fuluTags40": "Protection & Evil Warding",
        "fuluTags41": "Health & Wellness",
        "fuluTags42": "Protection & Evil Warding",
        "fuluTags43": "Protection & Evil Warding",
        "fuluTags44": "Wealth Attraction",
        "fuluTags45": "Wealth Attraction",
        "fuluTags46": "Luck Enhancement",
        "fuluTags47": "Wealth Attraction",
        "fuluTags48": "Protection & Evil Warding",
        "fuluTags49": "Protection & Evil Warding",
        "fuluTags50": "Protection & Evil Warding",
        "fuluTags51": "Protection & Evil Warding",
        "fuluTags52": "Protection & Evil Warding",
        "fuluTags53": "Protection & Evil Warding",
        "fuluTags54": "Relationships",
        "fuluTags55": "Protection & Evil Warding",
        "fuluTags56": "Protection & Evil Warding",
        "fuluTags57": "Protection & Evil Warding",
        "fuluTags58": "Protection & Evil Warding",
        "fuluTags59": "Protection & Evil Warding",
        "fuluTags60": "Protection & Evil Warding",
        "fuluTags61": "Protection & Evil Warding",
        "fuluTags62": "Protection & Evil Warding",
        "fuluTags63": "Health & Wellness",
        "fuluTags64": "Wealth Attraction",
        "fuluTags65": "Protection & Evil Warding",
        "fuluTags66": "Protection & Evil Warding",
        "fuluTags67": "Wealth Attraction",
        "fuluTags68": "Protection & Evil Warding",
        "fuluTags69": "Health & Wellness",
        "fuluTags70": "Protection & Evil Warding",
        "fuluTags71": "Protection & Evil Warding",
        "fuluTags72": "Protection & Evil Warding",
        "fuluTags73": "Protection & Evil Warding",
        "fuluTags74": "Health & Wellness",
        "fuluTags75": "Health & Wellness",
        "fuluTags76": "Protection & Evil Warding",
        "fuluTags77": "Health & Wellness",
        "fuluTags78": "Protection & Evil Warding",
        "fuluTags79": "Relationships",
        "fuluTags80": "Protection & Evil Warding",
        "fuluTags81": "Health & Wellness",
        "fuluTags82": "Protection & Evil Warding",
        "fuluTags83": "Protection & Evil Warding",
        "fuluTags84": "Protection & Evil Warding",
        "fuluTags85": "Relationships",
        "fuluTags86": "Protection & Evil Warding",
        "fuluTags87": "Protection & Evil Warding",
        "fuluTags88": "Protection & Evil Warding",
        "fuluTags89": "Protection & Evil Warding",
        "fuluTags90": "Protection & Evil Warding",
        "fuluTags91": "Protection & Evil Warding",
        "fuluTags92": "Wealth Attraction",
        "fuluTags93": "Wealth Attraction",
        "fuluTags94": "Wealth Attraction",
        "fuluTags95": "Protection & Evil Warding",
        "fuluTags96": "Protection & Evil Warding",
        "fuluTags97": "Protection & Evil Warding",
        "fuluTags98": "Protection & Evil Warding",
        "fuluTags99": "Protection & Evil Warding",
        "fuluTags100": "Health & Wellness",
        "fuluTags101": "Protection & Evil Warding",
        "fuluTags102": "Protection & Evil Warding",
        "fuluTags103": "Protection & Evil Warding",
        "fuluTags104": "Health & Wellness",
        "fuluTags105": "Health & Wellness",
        "fuluTags106": "Protection & Evil Warding",
        "fuluTags107": "Protection & Evil Warding",
        "fuluTags108": "Protection & Evil Warding",
        "fuluTags109": "Health & Wellness",
        "fuluTags110": "Protection & Evil Warding",
        "fuluTags111": "Health & Wellness",
        "fuluTags112": "Relationships",
        "fuluTags113": "Protection & Evil Warding",
        "fuluTags114": "Wealth Attraction",
        "fuluTags115": "Protection & Evil Warding",
        "fuluTags116": "Health & Wellness",
        "fuluTags117": "Protection & Evil Warding",
        "fuluTags118": "Protection & Evil Warding",
        "fuluTags119": "Protection & Evil Warding",
        "fuluTags120": "Luck Enhancement",
        "fuluTags121": "Health & Wellness",
        "fuluTags122": "Wealth Attraction",
        "fuluTags123": "Protection & Evil Warding",
        "fuluTags124": "Protection & Evil Warding",
        "fuluTags125": "Protection & Evil Warding",
        "fuluTags126": "Protection & Evil Warding",
        "fuluTags127": "Protection & Evil Warding",
        "fuluTags128": "Protection & Evil Warding",
        "fuluTags129": "Health & Wellness",
        "fuluTags130": "Protection & Evil Warding",
        "fuluTags131": "Protection & Evil Warding",
        "fuluTags132": "Protection & Evil Warding",
        "fuluTags133": "Protection & Evil Warding",
        "fuluTags134": "Protection & Evil Warding",
        "fuluTags135": "Protection & Evil Warding",
        "fuluTags136": "Protection & Evil Warding",
        "fuluTags137": "Relationships",
        "fuluTags138": "Relationships",
        "fuluTags139": "Protection & Evil Warding",
        "fuluTags140": "Protection & Evil Warding",
        "fuluTags141": "Protection & Evil Warding",
        "fuluTags142": "Health & Wellness",
        "fuluTags143": "Protection & Evil Warding",
        "fuluTags144": "Protection & Evil Warding",
        "fuluTags145": "Protection & Evil Warding",
        "fuluTags146": "Protection & Evil Warding",
        "fuluTags147": "Protection & Evil Warding",
        "fuluTags148": "Protection & Evil Warding",
        "fuluTags149": "Protection & Evil Warding",
        "fuluTags150": "Protection & Evil Warding",
        "fuluTags151": "Protection & Evil Warding",
        "fuluTags152": "Luck Enhancement",
        "fuluTags153": "Protection & Evil Warding",
        "fuluTags154": "Protection & Evil Warding",
        "fuluTags155": "Protection & Evil Warding",
        "fuluTags156": "Relationships",
        "fuluTags157": "Health & Wellness",
        "fuluTags158": "Luck Enhancement",
        "fuluTags159": "Health & Wellness",
        "fuluTags160": "Health & Wellness",
        "fuluTags161": "Health & Wellness",
        "fuluTags162": "Protection & Evil Warding",
        "fuluTags163": "Protection & Evil Warding",
        "fuluTags164": "Protection & Evil Warding",
        "fuluTags165": "Luck Enhancement",
        "fuluTags166": "Protection & Evil Warding",
        "fuluTags167": "Protection & Evil Warding",
        "fuluTags168": "Protection & Evil Warding",
        "fuluTags169": "Protection & Evil Warding",
        "fuluTags170": "Relationships",
        "fuluTags171": "Protection & Evil Warding",
        "fuluTags172": "Protection & Evil Warding",
        "fuluTags173": "Health & Wellness",
        "fuluTags174": "Protection & Evil Warding",
        "fuluTags175": "Protection & Evil Warding",
        "fuluTags176": "Protection & Evil Warding",
        "fuluTags177": "Protection & Evil Warding",
        "fuluTags178": "Wealth Attraction",
        "fuluTags179": "Wealth Attraction",
        "fuluTags180": "Protection & Evil Warding",
        "fuluTags181": "Protection & Evil Warding",
        "fuluTags182": "Wealth Attraction",
        "fuluTags183": "Wealth Attraction",
        "fuluTags184": "Protection & Evil Warding",
        "fuluTags185": "Protection & Evil Warding",
        "fuluTags186": "Protection & Evil Warding",
        "fuluTags187": "Health & Wellness",
        "fuluTags188": "Protection & Evil Warding",
        "fuluTags189": "Protection & Evil Warding",
        "fuluTags190": "Protection & Evil Warding",
        "fuluTags191": "Protection & Evil Warding",
        "fuluTags192": "Protection & Evil Warding",
        "fuluTags193": "Protection & Evil Warding",
        "fuluTags194": "Protection & Evil Warding",
        "fuluTags195": "Health & Wellness",
        "fuluTags196": "Health & Wellness",
        "fuluTags197": "Protection & Evil Warding",
        "fuluTags198": "Health & Wellness",
        "fuluTags199": "Protection & Evil Warding",
        "fuluTags200": "Protection & Evil Warding",
        "fuluTags201": "Health & Wellness",
        "fuluTags202": "Protection & Evil Warding",
        "fuluTags203": "Protection & Evil Warding",
        "fuluTags204": "Protection & Evil Warding",
        "fuluTags205": "Health & Wellness",
        "fuluTags206": "Health & Wellness",
        "fuluTags207": "Protection & Evil Warding",
        "fuluTags208": "Protection & Evil Warding",
        "fuluTags209": "Health & Wellness",
        "fuluTags210": "Relationships",
        "fuluTags211": "Protection & Evil Warding",
        "fuluTags212": "Protection & Evil Warding",
        "fuluTags213": "Protection & Evil Warding",
        "fuluTags214": "Protection & Evil Warding",
        "fuluTags215": "Luck Enhancement",
        "fuluTags216": "Protection & Evil Warding",
        "fuluTags217": "Protection & Evil Warding",
        "fuluTags218": "Protection & Evil Warding",
        "fuluTags219": "Protection & Evil Warding",
        "fuluTags220": "Protection & Evil Warding",
        "fuluTags221": "Protection & Evil Warding",
        "fuluTags222": "Protection & Evil Warding",
        "fuluTags223": "Luck Enhancement",
        "fuluTags224": "Luck Enhancement",
        "fuluTags225": "Protection & Evil Warding",
        "fuluTags226": "Luck Enhancement",
        "fuluTags227": "Luck Enhancement",
        "fuluTags228": "Peace of Mind",
        "fuluTags229": "Luck Enhancement",
        "fuluTags230": "Protection & Evil Warding",
        "fuluTags231": "Luck Enhancement",
        "fuluTags232": "Luck Enhancement",
        "fuluTags233": "Luck Enhancement",
        "fuluTags234": "Peace of Mind",
        "fuluTags235": "Luck Enhancement",
        "fuluTags236": "Peace of Mind",
        "fuluTags237": "Peace of Mind",
        "fuluTags238": "Protection & Evil Warding",
        "fuluTags239": "Protection & Evil Warding",
        "fuluTags240": "Health & Wellness",
        "fuluTags241": "Protection & Evil Warding",
        "fuluTags242": "Health & Wellness",
        "fuluTags243": "Protection & Evil Warding",
        "fuluTags244": "Protection & Evil Warding",
        "fuluTags245": "Peace of Mind",
        "fuluTags246": "Peace of Mind",
        "fuluTags247": "Peace of Mind",
        "fuluTags248": "Peace of Mind",
        "fuluTags249": "Protection & Evil Warding",
        "fuluTags250": "Peace of Mind"
      }
    },
    "zh": {
      "translation": {
        "fuluTags1": "招财类",
        "fuluTags2": "平安驱邪类",
        "fuluTags3": "平安驱邪类",
        "fuluTags4": "平安驱邪类",
        "fuluTags5": "招财类",
        "fuluTags6": "健康类",
        "fuluTags7": "情感类",
        "fuluTags8": "平安驱邪类",
        "fuluTags9": "平安驱邪类",
        "fuluTags10": "情感类",
        "fuluTags11": "事业类",
        "fuluTags12": "平安驱邪类",
        "fuluTags13": "平安驱邪类",
        "fuluTags14": "平安驱邪类",
        "fuluTags15": "平安驱邪类",
        "fuluTags16": "平安驱邪类",
        "fuluTags17": "安神类",
        "fuluTags18": "健康类",
        "fuluTags19": "转运类",
        "fuluTags20": "转运类",
        "fuluTags21": "健康类",
        "fuluTags22": "平安驱邪类",
        "fuluTags23": "招财类",
        "fuluTags24": "健康类",
        "fuluTags25": "平安驱邪类",
        "fuluTags26": "平安驱邪类",
        "fuluTags27": "平安驱邪类",
        "fuluTags28": "安神类",
        "fuluTags29": "平安驱邪类",
        "fuluTags30": "平安驱邪类",
        "fuluTags31": "招财类",
        "fuluTags32": "平安驱邪类",
        "fuluTags33": "事业类",
        "fuluTags34": "招财类",
        "fuluTags35": "平安驱邪类",
        "fuluTags36": "平安驱邪类",
        "fuluTags37": "平安驱邪类",
        "fuluTags38": "平安驱邪类",
        "fuluTags39": "平安驱邪类",
        "fuluTags40": "平安驱邪类",
        "fuluTags41": "健康类",
        "fuluTags42": "平安驱邪类",
        "fuluTags43": "平安驱邪类",
        "fuluTags44": "招财类",
        "fuluTags45": "招财类",
        "fuluTags46": "转运类",
        "fuluTags47": "招财类",
        "fuluTags48": "平安驱邪类",
        "fuluTags49": "平安驱邪类",
        "fuluTags50": "平安驱邪类",
        "fuluTags51": "平安驱邪类",
        "fuluTags52": "平安驱邪类",
        "fuluTags53": "平安驱邪类",
        "fuluTags54": "情感类",
        "fuluTags55": "平安驱邪类",
        "fuluTags56": "平安驱邪类",
        "fuluTags57": "平安驱邪类",
        "fuluTags58": "平安驱邪类",
        "fuluTags59": "平安驱邪类",
        "fuluTags60": "平安驱邪类",
        "fuluTags61": "平安驱邪类",
        "fuluTags62": "平安驱邪类",
        "fuluTags63": "健康类",
        "fuluTags64": "招财类",
        "fuluTags65": "平安驱邪类",
        "fuluTags66": "平安驱邪类",
        "fuluTags67": "招财类",
        "fuluTags68": "平安驱邪类",
        "fuluTags69": "健康类",
        "fuluTags70": "平安驱邪类",
        "fuluTags71": "平安驱邪类",
        "fuluTags72": "平安驱邪类",
        "fuluTags73": "平安驱邪类",
        "fuluTags74": "健康类",
        "fuluTags75": "健康类",
        "fuluTags76": "平安驱邪类",
        "fuluTags77": "健康类",
        "fuluTags78": "平安驱邪类",
        "fuluTags79": "情感类",
        "fuluTags80": "平安驱邪类",
        "fuluTags81": "健康类",
        "fuluTags82": "平安驱邪类",
        "fuluTags83": "平安驱邪类",
        "fuluTags84": "平安驱邪类",
        "fuluTags85": "情感类",
        "fuluTags86": "平安驱邪类",
        "fuluTags87": "平安驱邪类",
        "fuluTags88": "平安驱邪类",
        "fuluTags89": "平安驱邪类",
        "fuluTags90": "平安驱邪类",
        "fuluTags91": "平安驱邪类",
        "fuluTags92": "招财类",
        "fuluTags93": "招财类",
        "fuluTags94": "招财类",
        "fuluTags95": "平安驱邪类",
        "fuluTags96": "平安驱邪类",
        "fuluTags97": "平安驱邪类",
        "fuluTags98": "平安驱邪类",
        "fuluTags99": "平安驱邪类",
        "fuluTags100": "健康类",
        "fuluTags101": "平安驱邪类",
        "fuluTags102": "平安驱邪类",
        "fuluTags103": "平安驱邪类",
        "fuluTags104": "健康类",
        "fuluTags105": "健康类",
        "fuluTags106": "平安驱邪类",
        "fuluTags107": "平安驱邪类",
        "fuluTags108": "平安驱邪类",
        "fuluTags109": "健康类",
        "fuluTags110": "平安驱邪类",
        "fuluTags111": "健康类",
        "fuluTags112": "情感类",
        "fuluTags113": "平安驱邪类",
        "fuluTags114": "招财类",
        "fuluTags115": "平安驱邪类",
        "fuluTags116": "健康类",
        "fuluTags117": "平安驱邪类",
        "fuluTags118": "平安驱邪类",
        "fuluTags119": "平安驱邪类",
        "fuluTags120": "转运类",
        "fuluTags121": "健康类",
        "fuluTags122": "招财类",
        "fuluTags123": "平安驱邪类",
        "fuluTags124": "平安驱邪类",
        "fuluTags125": "平安驱邪类",
        "fuluTags126": "平安驱邪类",
        "fuluTags127": "平安驱邪类",
        "fuluTags128": "平安驱邪类",
        "fuluTags129": "健康类",
        "fuluTags130": "平安驱邪类",
        "fuluTags131": "平安驱邪类",
        "fuluTags132": "平安驱邪类",
        "fuluTags133": "平安驱邪类",
        "fuluTags134": "平安驱邪类",
        "fuluTags135": "平安驱邪类",
        "fuluTags136": "平安驱邪类",
        "fuluTags137": "情感类",
        "fuluTags138": "情感类",
        "fuluTags139": "平安驱邪类",
        "fuluTags140": "平安驱邪类",
        "fuluTags141": "平安驱邪类",
        "fuluTags142": "健康类",
        "fuluTags143": "平安驱邪类",
        "fuluTags144": "平安驱邪类",
        "fuluTags145": "平安驱邪类",
        "fuluTags146": "平安驱邪类",
        "fuluTags147": "平安驱邪类",
        "fuluTags148": "平安驱邪类",
        "fuluTags149": "平安驱邪类",
        "fuluTags150": "平安驱邪类",
        "fuluTags151": "平安驱邪类",
        "fuluTags152": "转运类",
        "fuluTags153": "平安驱邪类",
        "fuluTags154": "平安驱邪类",
        "fuluTags155": "平安驱邪类",
        "fuluTags156": "情感类",
        "fuluTags157": "健康类",
        "fuluTags158": "转运类",
        "fuluTags159": "健康类",
        "fuluTags160": "健康类",
        "fuluTags161": "健康类",
        "fuluTags162": "平安驱邪类",
        "fuluTags163": "平安驱邪类",
        "fuluTags164": "平安驱邪类",
        "fuluTags165": "转运类",
        "fuluTags166": "平安驱邪类",
        "fuluTags167": "平安驱邪类",
        "fuluTags168": "平安驱邪类",
        "fuluTags169": "平安驱邪类",
        "fuluTags170": "情感类",
        "fuluTags171": "平安驱邪类",
        "fuluTags172": "平安驱邪类",
        "fuluTags173": "健康类",
        "fuluTags174": "平安驱邪类",
        "fuluTags175": "平安驱邪类",
        "fuluTags176": "平安驱邪类",
        "fuluTags177": "平安驱邪类",
        "fuluTags178": "招财类",
        "fuluTags179": "招财类",
        "fuluTags180": "平安驱邪类",
        "fuluTags181": "平安驱邪类",
        "fuluTags182": "招财类",
        "fuluTags183": "招财类",
        "fuluTags184": "平安驱邪类",
        "fuluTags185": "平安驱邪类",
        "fuluTags186": "平安驱邪类",
        "fuluTags187": "健康类",
        "fuluTags188": "平安驱邪类",
        "fuluTags189": "平安驱邪类",
        "fuluTags190": "平安驱邪类",
        "fuluTags191": "平安驱邪类",
        "fuluTags192": "平安驱邪类",
        "fuluTags193": "平安驱邪类",
        "fuluTags194": "平安驱邪类",
        "fuluTags195": "健康类",
        "fuluTags196": "健康类",
        "fuluTags197": "平安驱邪类",
        "fuluTags198": "健康类",
        "fuluTags199": "平安驱邪类",
        "fuluTags200": "平安驱邪类",
        "fuluTags201": "健康类",
        "fuluTags202": "平安驱邪类",
        "fuluTags203": "平安驱邪类",
        "fuluTags204": "平安驱邪类",
        "fuluTags205": "健康类",
        "fuluTags206": "健康类",
        "fuluTags207": "平安驱邪类",
        "fuluTags208": "平安驱邪类",
        "fuluTags209": "健康类",
        "fuluTags210": "情感类",
        "fuluTags211": "平安驱邪类",
        "fuluTags212": "平安驱邪类",
        "fuluTags213": "平安驱邪类",
        "fuluTags214": "平安驱邪类",
        "fuluTags215": "转运类",
        "fuluTags216": "平安驱邪类",
        "fuluTags217": "平安驱邪类",
        "fuluTags218": "平安驱邪类",
        "fuluTags219": "平安驱邪类",
        "fuluTags220": "平安驱邪类",
        "fuluTags221": "平安驱邪类",
        "fuluTags222": "平安驱邪类",
        "fuluTags223": "转运类",
        "fuluTags224": "转运类",
        "fuluTags225": "平安驱邪类",
        "fuluTags226": "转运类",
        "fuluTags227": "转运类",
        "fuluTags228": "安神类",
        "fuluTags229": "转运类",
        "fuluTags230": "平安驱邪类",
        "fuluTags231": "转运类",
        "fuluTags232": "转运类",
        "fuluTags233": "转运类",
        "fuluTags234": "安神类",
        "fuluTags235": "转运类",
        "fuluTags236": "安神类",
        "fuluTags237": "安神类",
        "fuluTags238": "平安驱邪类",
        "fuluTags239": "平安驱邪类",
        "fuluTags240": "健康类",
        "fuluTags241": "平安驱邪类",
        "fuluTags242": "健康类",
        "fuluTags243": "平安驱邪类",
        "fuluTags244": "平安驱邪类",
        "fuluTags245": "安神类",
        "fuluTags246": "安神类",
        "fuluTags247": "安神类",
        "fuluTags248": "安神类",
        "fuluTags249": "平安驱邪类",
        "fuluTags250": "安神类"
      }
    }
};
  