import React, { useState, useRef, useMemo, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import { 
  Box, 
  Flex, 
  Button, 
  Input, 
  VStack, 
  HStack, 
  Image, 
  Select, 
  useNumberInput, 
  SimpleGrid, 
  Text as chakraText,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Textarea,
  useBreakpointValue,
  Spinner,
 } from '@chakra-ui/react';
import { Rect, Stage, Layer, Image as KonvaImage, Text, Transformer, Group, Circle, Line } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';
import ColorPicker from './colorSelect';
import { useTranslation } from 'react-i18next';
import { BsUpload } from "react-icons/bs";

// 定义元素类型
interface ElementBase {
  id: string;
  type: 'image' | 'text';
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
  rotation: number;
}

interface ImageElement extends ElementBase {
  type: 'image';
  imageObj: HTMLImageElement;
}

interface TextElement extends ElementBase {
  fontFamily: string;
  type: 'text';
  text: string;
  fontSize?: number;
  color?: string;
}

type ElementType = ImageElement | TextElement;

interface DraggableElementProps {
  element: ElementType;
  isSelected: boolean;
  onSelect: () => void;
  onChange: (newAttrs: ElementType) => void;
  onDelete?: (id: string) => void;
}

const DeleteButton: React.FC<{
  x: number;
  y: number;
  onClick: () => void;
}> = ({ x, y, onClick }) => {
  return (
    <Group onClick={onClick}>
      {/* 背景圆圈 */}
      <Circle
        x={x}
        y={y}
        radius={10}
        fill="black"
        opacity={0.8}
      />
      {/* 十字线 */}
      <Line
        points={[x - 5, y - 5, x + 5, y + 5]}
        stroke="white"
        strokeWidth={2}
      />
      <Line
        points={[x - 5, y + 5, x + 5, y - 5]}
        stroke="white"
        strokeWidth={2}
      />
    </Group>
  );
};

const DraggableElement: React.FC<DraggableElementProps> = ({ 
  element, 
  isSelected, 
  onSelect, 
  onChange,
  onDelete
}) => {
  const shapeRef = useRef<Konva.Image | Konva.Text>(null);
  const trRef = useRef<Konva.Transformer>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (isSelected && shapeRef.current && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  const commonProps = {
    x: element.x,
    y: element.y,
    scaleX: element.scaleX,
    scaleY: element.scaleY,
    rotation: element.rotation,
    draggable: true,
    onClick: onSelect,
    onTap: onSelect,
    onDragStart: () => {
      setIsDragging(true);  // 开始拖动时设置状态
    },
    onDragEnd: (e: Konva.KonvaEventObject<DragEvent>) => {
      setIsDragging(false);  // 结束拖动时重置状态
      onChange({
        ...element,
        x: e.target.x(),
        y: e.target.y(),
      });
    },
    onTransformEnd: () => {
      if (shapeRef.current) {
        const node = shapeRef.current;
        onChange({
          ...element,
          x: node.x(),
          y: node.y(),
          scaleX: node.scaleX(),
          scaleY: node.scaleY(),
          rotation: node.rotation(),
        });
      }
    },
  };

  return (
    <>
      {element.type === 'image' ? (
        <KonvaImage
            //border="1px solid black"
          ref={shapeRef as React.RefObject<Konva.Image>}
          image={element.imageObj}
          {...commonProps}
        />
      ) : (
        <Text
          ref={shapeRef as React.RefObject<Konva.Text>}
          text={element.text}
          fontSize={element.fontSize || 20}
          fontFamily={element.fontFamily || 'Arial'}
          fill={element.color || 'black'}
          //stroke={'black'}  // 设置边框颜色为红色
          //strokeWidth={0.5}
          {...commonProps}
        />
      )}
      {/* 分开渲染 Transformer 和 DeleteButton */}
      {isSelected && !isEditing && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => newBox}
        />
      )}
      {isSelected && !isEditing && !isDragging && (
        <DeleteButton
          x={element.x + (shapeRef.current?.width() || 0) * (element.scaleX || 1)}
          y={element.y}
          onClick={() => onDelete?.(element.id)}
        />
      )}
    </>
  );
};

const fontFamilyOptions = [
  'Arial',             
  'Times New Roman',   
  'Courier New',      
  'Georgia',           
  'Verdana',         
  '楷体',            
  '宋体',             
  '黑体',             
  'Fira Code',       
  'Roboto Mono',      
  'Consolas',          
  'Lato',              
  'Open Sans',        
  'Roboto',           
  'Montserrat',        
];

const ImageEditor: React.FC = () => {
  //const [background, setBackground] = useState<HTMLImageElement | null>(null);
  const [background, setBackground] = useState<HTMLImageElement | string | null>(null);
  const [elements, setElements] = useState<ElementType[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const stageRef = useRef<Konva.Stage>(null);

  const initialWidth = useBreakpointValue({
    base: 480,  // 默认值(小于xl的时候)
    //'3xl': 626,    // xl及以上的值
    '3xl': 575,
  }) ?? 575

  const initialHeight = useBreakpointValue({
    base: 430,  // 默认值(小于xl的时候)
    //'3xl': 579,    // xl及以上的值
    '3xl': 525,
  }) ?? 525

  const baseinitialWidth = useBreakpointValue({
    base: 480,  // 默认值(小于xl的时候)
    //'3xl': 626,    // xl及以上的值
    '3xl': 520,
  }) ?? 520

  const baseinitialHeight = useBreakpointValue({
    base: 430,  // 默认值(小于xl的时候)
    //'3xl': 579,    // xl及以上的值
    '3xl': 530,
  }) ?? 530

  const [textEdit, setTextEdit] = useState(true)

  const [hbWidth, setHbWidth] = useState(626)
  const [hbHeight, setHbHeight] = useState(579)

  const [color, setColor] = useState("#ff6347");
  const [fontSize, setFontSize] = useState(50);
  const [fontFamily, setFontFamily] = useState('');

  const { t, i18n } = useTranslation();
  const fontFamilyor = i18n.language === 'zh' 
  ? '楷体'   // 中文时的宽度
  : 'Source Han Serif CN';  // 英文时的宽度

  const fontWeight = i18n.language === 'zh' 
  ? '700'   // 中文时的宽度
  : '300';  // 英文时的宽度

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
  useNumberInput({
    step: 1,
    value: fontSize,
    defaultValue: fontSize,
    min: 0,
    max: 260,
    precision: 0,
    onChange: (valueString) => {
      const newSize = parseInt(valueString) || 50;
      handleFontSizeChange(newSize); // 直接传递数字
    }
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  useEffect(() => {
    if (initialWidth) {
      if (background) {
        const side = hbWidth / hbHeight
        if (hbHeight > hbWidth) {
          setHbHeight(initialHeight)
          const newWidth = initialHeight * side
          setHbWidth(newWidth)
        } else {
          setHbWidth(initialWidth)
          const newHeight = initialWidth / side
          setHbHeight(newHeight)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialWidth, initialHeight])

  const handleBackgroundUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const img = new window.Image();
      
      img.src = url;
      img.onload = () => {
        const side = img.width / img.height
        //const imgHeight = hbWidth / side
        const imgHeight = initialWidth / side
        
        // img.width = hbWidth
        // img.height = imgHeight

        // if (imgHeight > hbHeight) {
        //   const imgWidth = hbHeight * side
        //   setHbWidth(imgWidth)
        // } else {
        //   setHbHeight(imgHeight)
        // }
        if (imgHeight > initialHeight) {
          const imgWidth = initialHeight * side
          setHbHeight(initialHeight)
          setHbWidth(imgWidth)
        } else {
          setHbWidth(initialWidth)
          setHbHeight(imgHeight)
        }
        //setHbHeight(imgHeight)
        setBackground(img);
      };
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState('');
  
  const [uploading, setUploading] = useState(false)
  const handleBackgroundUploadTwo = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage(t('srrors2'));
        onOpen(); // 打开错误弹窗
        return; // 退出函数，不继续执行上传
      }

      setUploading(true)

      const url = URL.createObjectURL(file);
    const img = new window.Image();

    const uploadFile = async (retries: number = 2): Promise<any> => {
      try {
        // 创建 FormData 用于文件上传
        const formData = new FormData();
        formData.append('image', file); // 'image' 是后端接收的字段名

        // 发送 POST 请求到后端
        const response = await fetch('https://backend.fulutalisman.com/backend/api/upload', {  // 使用相对路径
        //const response = await fetch('http://localhost:3001/api/upload', {  // 使用相对路径
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('上传失败');
        }

        return response.json();
      } catch (error) {
        if (retries > 0) {
          console.log(`Upload failed, retry the remaining number of times: ${retries}`);
          await new Promise((resolve) => setTimeout(resolve, 3000)); // 延迟 3 秒重试
          return uploadFile(retries - 1); // 递归重试
        } else {
          throw error; // 重试次数用尽，抛出错误
        }
      }
    };

    try {
      const result = await uploadFile(); // 执行上传，最多重试 2 次

      if (result.success) {
        // 获取返回的图片 URL
        const resultUrl = result.resultUrl;
        const response = await fetch(resultUrl);
        if (!response.ok) {
          setUploading(false);
          throw new Error('获取处理后图片失败');
        }

        // 2. 获取图片的 Blob 数据
        const imageBlob = await response.blob();

        // 3. 创建图片对象
        const img = new window.Image();

        // 4. 将 Blob 转换为图片 URL
        const imageUrl = URL.createObjectURL(imageBlob);
        img.src = imageUrl;

        img.onload = () => {
          const side = img.width / img.height;
          const maxWidth = 200;
          const maxHeight = 200;
          const imgHeight = initialWidth / side

          // 计算新的宽度和高度
          let newWidth = maxWidth;
          let newHeight = maxWidth / side;

          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = maxHeight * side;
          }

          // 计算 scaleX 和 scaleY
          const scaleX = newWidth / img.width;
          const scaleY = newHeight / img.height;

          setHbHeight(baseinitialHeight)
          setHbWidth(baseinitialWidth)

          setBackground('#f6c346');

          // 更新元素
          setElements([
            ...elements,
            {
              type: 'image',
              imageObj: img,
              x: 100,
              y: 100,
              scaleX: scaleX,
              scaleY: scaleY,
              rotation: 0,
              id: Date.now().toString(),
            },
          ]);
        };

        setUploading(false);
      } else {
        setUploading(false);
        throw new Error(result.error || '上传失败');
      }
    } catch (error) {
      setUploading(false);
      setErrorMessage(t('errors'));
      onOpen(); // 打开错误弹窗
      console.error('上传错误:', error);
    }


      // img.src = url;
      // img.onload = () => {
      //   const side = img.width / img.height
      //   // 设定一个最大宽度或高度，假设最大宽度为 800px
      //   const maxWidth = 200;
      //   const maxHeight = 200;
        
      //   // 计算图片的新尺寸
      //   let newWidth = maxWidth;
      //   let newHeight = maxWidth / side;
        
      //   // 如果新高度超过最大高度，按比例调整宽度和高度
      //   if (newHeight > maxHeight) {
      //     newHeight = maxHeight;
      //     newWidth = maxHeight * side;
      //   }
        
      //   // 计算scaleX和scaleY
      //   const scaleX = newWidth / img.width;
      //   const scaleY = newHeight / img.height;
        
      //   // 更新元素
      //   setHbWidth(initialWidth)
      //   setHbHeight(initialHeight)
      //   setBackground('#f6c346')
      //   setElements([
      //       ...elements,
      //       {
      //         type: 'image',
      //         imageObj: img,
      //         x: 100,
      //         y: 100,
      //         scaleX: scaleX,
      //         scaleY: scaleY,
      //         rotation: 0,
      //         id: Date.now().toString(),
      //       },
      //   ]);
      // };
    }
  };
  
  const handleAddSticker = (sticker: { id: string, src: string, tags: string }) => {
    const img = new window.Image();
    img.src = sticker.src;
    img.onload = () => {
        const side = img.width / img.height;
    
        // 设定一个最大宽度或高度，假设最大宽度为 800px
        const maxWidth = 200;
        const maxHeight = 200;
        
        // 计算图片的新尺寸
        let newWidth = maxWidth;
        let newHeight = maxWidth / side;
        
        // 如果新高度超过最大高度，按比例调整宽度和高度
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = maxHeight * side;
        }
        
        // 计算scaleX和scaleY
        const scaleX = newWidth / img.width;
        const scaleY = newHeight / img.height;
        
        // 更新元素
        //setBackground('#f6c346')
        setElements([
            ...elements,
            {
              type: 'image',
              imageObj: img,
              x: 100,
              y: 100,
              scaleX: scaleX,
              scaleY: scaleY,
              rotation: 0,
              id: Date.now().toString(),
            },
        ]);
    };
  };

  //第二个将背景和图片结合得方法
  const handleAddStickerTwo = (sticker: { id: string, src: string, tags: string }) => {
    const img = new window.Image();
    img.src = sticker.src;
    img.onload = () => {
        const side = img.width / img.height;
        const imgHeight = initialWidth / side

        // 设定一个最大宽度或高度，假设最大宽度为 800px
        const maxWidth = 200;
        const maxHeight = 200;
        
        // 计算图片的新尺寸
        let newWidth = maxWidth;
        let newHeight = maxWidth / side;
        
        // 如果新高度超过最大高度，按比例调整宽度和高度
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = maxHeight * side;
        }
        
        // 计算scaleX和scaleY
        const scaleX = newWidth / img.width;
        const scaleY = newHeight / img.height;
        
        // 更新元素
        setHbHeight(baseinitialHeight)
          setHbWidth(baseinitialWidth)
        setBackground('#f6c346')
        setElements([
          {
              type: 'image',
              imageObj: img,
              x: 100,
              y: 100,
              scaleX: scaleX,
              scaleY: scaleY,
              rotation: 0,
              id: Date.now().toString(),
          },
          ...elements,  // 原有元素放在后面
      ]);
    };
  };

  const handleAddText = () => {
    setTextEdit(true)
    if (value == '') {
        setValue('Hello fulu')
        setElements([
          ...elements,
          {
            type: 'text',
            text: 'Hello fulu',
            x: 100,
            y: 100,
            fontSize: fontSize,
            fontFamily: fontFamily,
            color: 'black',
            scaleX: 1,
            scaleY: 1,
            rotation: 0,
            id: Date.now().toString(),
          },
        ]);
        return;
    }
    setElements([
      ...elements,
      {
        type: 'text',
        text: value,
        x: 100,
        y: 100,
        fontSize: fontSize,
        fontFamily: fontFamily,
        color: 'black',
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        id: Date.now().toString(),
      },
    ]);
  };

  const handleDownload = () => {
    setSelectedId(null);  
    setTimeout(() => {
      if (stageRef.current) {
        const dataURL = stageRef.current.toDataURL();
        const link = document.createElement('a');
        link.download = 'fuluImg.png';
        link.href = dataURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }, 0);  
  };

  const handleElementChange = (newAttrs: ElementType) => {
    const newElements = elements.map((el) => {
      if (el.id === newAttrs.id) {
        return newAttrs;
      }
      return el;
    });
    setElements(newElements);
  };

  const handleDeleteElement = (id: string) => {
    setElements(elements.filter(elem => elem.id !== id));
    setSelectedId(null);
  };


  const [value, setValue] = React.useState('')
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
    
    // 如果有选中的文本元素，同时更新它
    if (selectedId) {
      const updatedElements = elements.map(el => {
        if (el.id === selectedId && el.type === 'text') {
          return {
            ...el,
            text: e.target.value  // 更新文本内容
          };
        }
        return el;
      });
      setElements(updatedElements);
    }
  };

  const handleFontSizeChange = (e: { target: { value: number | string } } | number) => {
    // 如果传入的是数字，直接使用
    const newSize = typeof e === 'number' ? e : (parseInt(e.target.value.toString()) || 20);
    setFontSize(newSize);
    // 如果有选中的文本元素，更新它的字体大小
    if (selectedId) {
      const updatedElements = elements.map(el => {
        if (el.id === selectedId && el.type === 'text') {
          return {
            ...el,
            fontSize: newSize
          };
        }
        return el;
      });
      setElements(updatedElements);
    }
};
  
  const handleFontFamilyChange = (value: string) => {
    setFontFamily(value);
    // 如果有选中的文本元素，更新它的字体
    if (selectedId) {
      const updatedElements = elements.map(el => {
        if (el.id === selectedId && el.type === 'text') {
          return {
            ...el,
            fontFamily: value
          };
        }
        return el;
      });
      setElements(updatedElements);
    }
  };
  
  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
    // 如果有选中的文本元素，更新它的字体大小
    if (selectedId) {
      const updatedElements = elements.map(el => {
        if (el.id === selectedId && el.type === 'text') {
          return {
            ...el,
            color: color
          };
        }
        return el;
      });
      setElements(updatedElements);
    }
  };

  const handleReset = () => {
    setSelectedId(null)
    setHbWidth(626)
    setHbHeight(579)
    setBackground(null);
    setElements([]);
    //setTextEdit(false)
  };

  const tags = ['Tag1', 'Tag2', 'Tag3'];
  const generateStickers = () => {
    return Array.from({ length: 250 }, (_, i) => ({
      id: `fulu-${String(i + 1).padStart(3, '0')}`, // 生成 fulu-001 格式的ID
      //src: `/Home/fulu-${String(i + 1).padStart(3, '0')}.png`, // 图片路径
      src: '/Home/fuluList/fulu.jpg',
      tags: t(`fuluTags${i + 1}`) // 存储键名
    }));
  };

  // 示例贴图
  // const stickerImages = [
  //   '/Home/fuluImg.svg',
  //   '/path/to/sticker2.png',
  // ];

  
  const pngIndices = useMemo(() => [25, 26, 118], []);

  const stickerImages = useMemo(() => {
    return Array.from({ length: 250 }, (_, i) => ({
      id: `fulu-${String(i + 1).padStart(3, '0')}`,
      src: `https://graphmultiverse.io/image/fulu2/FuluTalisman_${i + 1}${pngIndices.includes(i + 1) ? '.png' : '.jpg'}`,
      tags: t(`fuluTags${i + 1}`)
    }));
  }, [t, pngIndices]);
  const [searchId, setSearchId] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  // 获取所有唯一的标签
  // const uniqueTags = useMemo(() => {
  //   const tags = new Set(stickerImages.map(sticker => sticker.tags));
  //   return Array.from(tags);
  // }, [stickerImages]);
  const uniqueTags = useMemo(() => {
    // Define how many tags you need based on your i18n tags structure
    const tagsCount = 7; // You have 7 tags in your example
    
    // Create tag keys, such as 'tags1', 'tags2', etc.
    const tagKeys = Array.from({ length: tagsCount }, (_, i) => `tags${i + 1}`);
    
    // Translate the tag keys using the `t` function from i18n
    return tagKeys.map(tagKey => t(tagKey));
  }, [t]);

  // 过滤图片
  const filteredStickers = useMemo(() => {
    const searchNumber = parseInt(searchId);
    return stickerImages.filter(sticker => {
      // 对于超过250的数字，通过取模映射到1-250范围内
      const mappedId = searchNumber > 250 ? 
        ((searchNumber - 1) % 250) + 1 : 
        searchNumber;
  
      const matchesSearch = searchId ? 
        sticker.id.includes(String(mappedId).padStart(3, '0')) : 
        true;
      
      const matchesTag = selectedTag ? 
        sticker.tags === selectedTag : 
        true;
      
      return matchesSearch && matchesTag;
    });
  }, [searchId, selectedTag, stickerImages]);
  useEffect(() => {
    // 如果当前选中的标签在新的语言下没有匹配的标签，重置 selectedTag
    if (!uniqueTags.includes(selectedTag)) {
      setSelectedTag('');
    }
  }, [t, uniqueTags, selectedTag]);

  //虚背景
  const generateStickers_2 = () => {
    return Array.from({ length: 2 }, (_, i) => ({
      id: `fulu-${String(i + 1).padStart(3, '0')}`, // 生成 fulu-001 格式的ID
      //src: `/Home/fulu-${String(i + 1).padStart(3, '0')}.png`, // 图片路径
      src: `/Home/blueprint/fulu/fulu-${i+1}.png`,
      tags: tags[Math.floor(Math.random() * tags.length)] // 存储键名
    }));
  };

  const stickerImages_2 = useMemo(() => {
    // Generate the stickers dynamically based on the current language
    return Array.from({ length: 250 }, (_, i) => ({
      id: `fulu-${String(i + 1).padStart(3, '0')}`, // ID format
      src: `https://graphmultiverse.io/image/fulu1/Fulu%20Talisman_${i + 1}.jpg`, // Image path
      tags: t(`fuluTags${i + 1}`) // Dynamically get the translated tag
    }));
  }, [t]);
  const [searchId2, setSearchId2] = useState('');
  const [selectedTag2, setSelectedTag2] = useState('');

  const filteredStickers_2 = useMemo(() => {
    const searchNumber = parseInt(searchId2);
    return stickerImages_2.filter(sticker => {
      const mappedId = searchNumber > 250 ? 
        ((searchNumber - 1) % 250) + 1 : 
        searchNumber;
   
      const matchesSearch = searchId2 ? 
        sticker.id.includes(String(mappedId).padStart(3, '0')) : 
        true;
      const matchesTag = selectedTag2 ? 
        sticker.tags === selectedTag2 : 
        true;
      return matchesSearch && matchesTag;
    });
   }, [stickerImages_2, searchId2, selectedTag2]);


  return (
    <Flex direction='column'>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader color="red.500">{t('errorsTitle')}</ModalHeader>
            <ModalBody>
              {errorMessage}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="red" onClick={onClose}>
                {t('errorsClose')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Flex
          mb='0'
          justifyContent='center'
          fontSize={{ '3xl': '38px', base: '32px' }}
          fontFamily={fontFamilyor}
          color='#E4D5BF'
          fontWeight={fontWeight}
        >
          {t('luckTitle')}
        </Flex>
        
        <Tabs 
          position='relative' 
          variant='unstyled'
          onChange={(index) => {
            setSelectedId(null)
            setHbWidth(626)
            setHbHeight(579)
            setBackground(null);
            setElements([]);
            // if (index === 0) {
            //   // 第一个标签被选中时的操作
            //   setBackground(null)

            // } else {
            //   // 第二个标签被选中时的操作
            //   console.log('选中了第二个标签');
            // }
          }}
        >
          <TabList
            color="#E4D5BF"
            display="flex" 
            justifyContent="center"  
            alignItems="center" 
            width="100%"
          >
            <Tab><Flex fontSize={{ '3xl': '32px', base: '28px' }}>{t('luckTitleOne')}</Flex></Tab>
            <Tab><Flex fontSize={{ '3xl': '32px', base: '28px' }}>{t('luckTitleTwo')}</Flex></Tab>
          </TabList>
          <TabIndicator mt='-1.5px' height='3px' bg='#F6AA5F' borderRadius='1px' />
          <TabPanels>
            <TabPanel>
              <Flex 
                width='100vw'
                justifyContent='center'
              >
                {textEdit && (
                  <VStack spacing={6} width="200px" mr='10'>
                    <VStack width="100%" spacing={3}>
                      <Flex
                        color='#E4D5BF'
                      >
                        {t(`textInput`)}
                      </Flex>
                      <Textarea
                        value={value}
                        onChange={handleChange}
                        placeholder={t(`inputText`)}
                        size="sm"
                        border="1px solid #F6AA5F"
                        borderRadius="10px"
                        color="#E4D5BF"
                        bgColor="#025F7D"
                        width="185px"  // 设置宽度为 300px
                        height="36px"  // 设置高度为 100px
                        resize="none"   // 不允许用户改变 Textarea 的大小
                      />
                      
                    </VStack>
                    <Flex width="100%" gap={2} justifyContent='center'>
                        {/*
                        <Input
                          type="number"
                          value={fontSize}
                          onChange={handleFontSizeChange}
                          placeholder='字体大小'
                          size='sm'
                          width="100px"
                        />
                        */}
                        <HStack 
                          maxW='320px'
                          width='185px'
                        >
                          <Button 
                            border="1px solid #F6AA5F"
                            borderRadius="10px"
                            color="#E4D5BF"
                            bgColor="#025F7D"
                            fontSize='20px'
                            justifyContent='center'
                            alignItems='center'
                            {...dec}
                          >-</Button>
                          <Input
                            border="1px solid #F6AA5F"
                            color='#E4D5BF'
                            bgColor='#025F7D'
                            {...input} 
                            />
                          <Button
                            border="1px solid #F6AA5F"
                            borderRadius="10px"
                            color="#E4D5BF"
                            bgColor="#025F7D"
                            fontSize='20px'
                            justifyContent='center'
                            alignItems='center'
                            {...inc}
                          >+</Button>
                        </HStack>
                      
                      </Flex>
                    <Select
                      width='144px'
                      height='43px'
                      color='#E4D5BF'
                      bgColor='#025F7D'
                      border='1px solid #F6AA5F'
                      borderRadius='8px'
                      placeholder={t(`selectFonts`)}
                      value={fontFamily}
                      onChange={(e) => handleFontFamilyChange(e.target.value)}
                      size='sm'
                      sx={{
                        "& option": {
                          backgroundColor: "#025F7D",  
                          color: "#E4D5BF", 
                        },
                        "& option:hover": {
                          backgroundColor: "#F6AA5F",  
                          color: "#FFFFFF", 
                        },
                        "&:focus": {
                          borderColor: "#F6AA5F", 
                        },
                      }}
                    >
                      {fontFamilyOptions.map(font => (
                        <option key={font} value={font}>
                          {font}
                        </option>
                      ))}
                    </Select>
                    <ColorPicker 
                      color={color}
                      handleColorChange={handleColorChange}
                    />
                  </VStack>
                )}
                <Flex
                  direction="column"
                >
                  <Flex 
                    direction="column"
                    position="relative"
                    width={{ '3xl': '683px', base: '580px' }}
                    height={{ '3xl': '630px', base: '536px' }}
                    justifyContent="center"
                    alignItems="center"
                    _before={{
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      bgImage: "url('/Home/blueprint/bg.svg')",
                      bgPosition: "top",
                      bgSize: "cover",
                      bgRepeat: "no-repeat",
                      pointerEvents: "none",
                      zIndex: 1  // 确保图片在上层
                    }}
                  >
                    {background ? (
                    <Stage
                      ref={stageRef}
                      width={hbWidth}
                      height={hbHeight}
                      onClick={(e) => {
                        const clickedOnEmpty = e.target === e.target.getStage();
                        if (clickedOnEmpty) {
                          setSelectedId(null);
                        }
                      }}
                    >
                      <Layer>
                        {typeof background === 'string' ? (
                            // 背景是纯色
                            <Rect
                              x={0}
                              y={0}
                              width={hbWidth}
                              height={hbHeight}
                              fill={background} // 填充纯色
                            />
                          ) : (
                          <KonvaImage
                            image={background}
                            width={hbWidth}
                            height={hbHeight}
                            // draggable  // 设置为可拖动
                            // onDragEnd={(e) => {
                            //     // 获取拖动结束后的新位置
                            //     const newX = e.target.x();
                            //     const newY = e.target.y();

                            //     // 更新背景位置（如果需要的话）
                            //     // setBackgroundPosition({ x: newX, y: newY }); // 你可以使用一个state来更新位置
                            // }}
                          />
                        )}
                        {elements.map((element) => (
                          <DraggableElement
                            key={element.id}
                            element={element}
                            isSelected={element.id === selectedId}
                            onSelect={() => {
                              setSelectedId(element.id); // 设置选中状态
                              if (element.type === 'text') {
                                setFontSize(element.fontSize || 20); // 更新字体大小
                                setFontFamily(element.fontFamily || 'Arial'); // 更新字体
                                setColor(element.color || 'black'); // 更新字体颜色
                                setValue(element.text); // 如果是文本，更新 value
                              }
                            }}
                            onChange={handleElementChange}
                            onDelete={handleDeleteElement}
                          />
                        ))}
                      </Layer>
                    </Stage>
                    ) : (
                      <Flex
                        bgColor="#0C4D62"
                        //width="626px"
                        //height="579px"
                        width={{ '3xl': '626px', base: '535px' }}
                        height={{ '3xl': '579px', base: '500px' }}
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        position="relative"  // 确保内容能够接收点击事件等
                      >
                        <Box
                          as="label" // 使 Input 变成按钮样式
                          htmlFor="background-upload"
                          width={{ '3xl': '270px', base: '200px' }}
                          height={{ '3xl': '270px', base: '200px' }}
                          border="2px dashed #F6AA5F"
                          borderRadius="20px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          cursor="pointer"
                          _hover={{ bg: 'blue.100' }} // 鼠标悬停的背景颜色
                          zIndex={10}
                          color='#F6AA5F'
                          fontSize='50px'
                          mb='10'
                        >
                          +
                          <Input
                            id="background-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleBackgroundUpload}
                            display="none" // 隐藏默认的文件输入框
                          />
                        </Box>
                        <Flex
                          color='#E4D5BF'
                          fontSize={{ '3xl': '26px', base: '20px' }}
                          fontWeight='300'
                        >
                          {t('uploadPhoto')}
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                  <Flex mt={{ '3xl': '6', base: '3' }} justifyContent="space-around">
                      <Button 
                        width='123px'
                        height='49px'
                        bgColor='#62270C'
                        color='#E4D5BF'
                        fontSize='16px'
                        borderRadius='10px'
                        onClick={handleReset}
                      >
                        {t('reset')}
                      </Button>
                      <Button 
                        width='123px'
                        height='49px'
                        bgColor='#0C4D62'
                        color='#E4D5BF'
                        fontSize='16px'
                        borderRadius='10px'
                        onClick={handleAddText}
                      >
                        {t('addText')}
                      </Button>
                      <Button 
                        width='123px'
                        height='49px'
                        bgColor='#025F7D'
                        color='#E4D5BF'
                        fontSize='16px'
                        borderRadius='10px'
                        onClick={handleDownload}
                      >
                        {t('downloadPhoto')}
                    </Button>
                  </Flex>
                </Flex>
                <Tabs 
                  mt='0'
                  ml='6'
                  position='relative' 
                  variant='unstyled'
                  width='185px '
                >
                  <TabList
                    color="#E4D5BF"
                    display="flex" 
                    justifyContent="center"  
                    alignItems="center" 
                    width="100%" 
                  >
                    <Tab>{t('bgOne')}</Tab>
                    <Tab>{t('bgTwo')}</Tab>
                  </TabList>
                  <TabIndicator mt='-1.5px' height='3px' bg='#F6AA5F' borderRadius='1px' />
                  <TabPanels>
                    <TabPanel>
                      <VStack spacing={5}>
                        <Input
                          width='150px'
                          height='43px'
                          color='#E4D5BF'
                          bgColor='#025F7D'
                          border='1px solid #F6AA5F'
                          placeholder={t(`inputNum`)}
                          value={searchId}
                          onChange={(e) => setSearchId(e.target.value)}
                        />
                        <Select
                          width={{ '3xl': '150px', base: '150px' }}
                          height='43px'
                          color='#E4D5BF'
                          bgColor='#025F7D'
                          border='1px solid #F6AA5F'
                          placeholder={t(`selectTag`)}
                          value={selectedTag}
                          onChange={(e) => setSelectedTag(e.target.value)}
                          sx={{
                            "& option": {
                              backgroundColor: "#025F7D",  
                              color: "#E4D5BF", 
                            },
                            "& option:hover": {
                              backgroundColor: "#F6AA5F",  
                              color: "#FFFFFF", 
                            },
                            "&:focus": {
                              borderColor: "#F6AA5F", 
                            },
                          }}
                        >
                          {uniqueTags.map(tag => (
                            <option key={tag} value={tag}>
                              {t(tag)}
                            </option>
                          ))}
                        </Select>
                        <Box
                          w="full"
                          h={{ '3xl': '500px', base: '410px' }}
                          overflowY="auto" // 启用垂直滚动
                          css={{
                            '&::-webkit-scrollbar': {
                              width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                              width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: 'gray.300',
                              borderRadius: '24px',
                            },
                          }}
                          px='6'
                          py='3'
                          bgColor='#025F7D'
                          border='1px solid #F6AA5F'
                          borderRadius='10px'
                        >
                          <SimpleGrid 
                            columns={[1]} 
                            spacing={6} 
                            w="full"
                          >
                            {filteredStickers.map((sticker: any) => {
                              const stickerNumber = parseInt(sticker.id.split('-')[1]);
                              return (
                                <Box
                                  justifyContent='center'
                                  key={sticker.id}
                                  onClick={() => handleAddSticker(sticker)}
                                  cursor="pointer"
                                  _hover={{ transform: 'scale(1.05)' }}
                                  transition="transform 0.2s"
                                >
                                  <Image
                                    src={sticker.src}
                                    alt={sticker.id}
                                    boxSize="100px"
                                    objectFit="contain"
                                  />
                                  <Flex 
                                    justifyContent='center'
                                    alignItems='center'
                                    color='#E4D5BF'
                                  >#{stickerNumber}</Flex>
                                </Box>
                              )
                            })}
                          </SimpleGrid>
                        </Box>
                      </VStack>
                    </TabPanel>
                    <TabPanel>
                      <VStack spacing={5}>
                        <Input
                          width='150px'
                          height='43px'
                          color='#E4D5BF'
                          bgColor='#025F7D'
                          border='1px solid #F6AA5F'
                          placeholder={t(`inputNum`)}
                          value={searchId2}
                          onChange={(e) => setSearchId2(e.target.value)}
                        />
                        <Select
                          width={{ '3xl': '150px', base: '150px' }}
                          height='43px'
                          color='#E4D5BF'
                          bgColor='#025F7D'
                          border='1px solid #F6AA5F'
                          placeholder={t(`selectTag`)}
                          value={selectedTag2}
                          onChange={(e) => setSelectedTag2(e.target.value)}
                          sx={{
                            "& option": {
                              backgroundColor: "#025F7D",  
                              color: "#E4D5BF", 
                            },
                            "& option:hover": {
                              backgroundColor: "#F6AA5F",  
                              color: "#FFFFFF", 
                            },
                            "&:focus": {
                              borderColor: "#F6AA5F", 
                            },
                          }}
                        >
                          {uniqueTags.map(tag => (
                            <option key={tag} value={tag}>
                              {t(tag)}
                            </option>
                          ))}
                        </Select>
                        <Box
                          w="full"
                          h={{ '3xl': '500px', base: '410px' }}
                          overflowY="auto" // 启用垂直滚动
                          css={{
                            '&::-webkit-scrollbar': {
                              width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                              width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: 'gray.300',
                              borderRadius: '24px',
                            },
                          }}
                          px='6'
                          py='3'
                          bgColor='#025F7D'
                          border='1px solid #F6AA5F'
                          borderRadius='10px'
                        >
                          <SimpleGrid 
                            columns={[1]} 
                            spacing={6} 
                            w="full"
                          >
                            {filteredStickers_2.map((sticker: any) => {
                              const stickerNumber = parseInt(sticker.id.split('-')[1]);
                              return (
                                <Box
                                  justifyContent='center'
                                  key={sticker.id}
                                  onClick={() => handleAddSticker(sticker)}
                                  cursor="pointer"
                                  _hover={{ transform: 'scale(1.05)' }}
                                  transition="transform 0.2s"
                                >
                                  <Image
                                    src={sticker.src}
                                    alt={sticker.id}
                                    boxSize="100px"
                                    objectFit="contain"
                                  />
                                  <Flex 
                                    justifyContent='center'
                                    alignItems='center'
                                    color='#E4D5BF'
                                  >#{stickerNumber}</Flex>
                                </Box>
                              )
                            })}
                          </SimpleGrid>
                        </Box>
                      </VStack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </TabPanel>
            {/*
          
          
            ---------------------------------------2
            
            
            */}
            <TabPanel>
              <Flex 
                width='100vw'
                justifyContent='center'
              >
                {textEdit && (
                  <VStack spacing={6} width="200px" mr='10'>
                    <VStack width="100%" spacing={3}>
                      <Flex
                        color='#E4D5BF'
                      >
                        {t(`textInput`)}
                      </Flex>
                      <Textarea
                        value={value}
                        onChange={handleChange}
                        placeholder={t(`inputText`)}
                        size="sm"
                        border="1px solid #F6AA5F"
                        borderRadius="10px"
                        color="#E4D5BF"
                        bgColor="#025F7D"
                        width="185px"  // 设置宽度为 300px
                        height="36px"  // 设置高度为 100px
                        resize="none"   // 不允许用户改变 Textarea 的大小
                      />
                      
                    </VStack>
                    <Flex width="100%" gap={2} justifyContent='center'>
                        {/*
                        <Input
                          type="number"
                          value={fontSize}
                          onChange={handleFontSizeChange}
                          placeholder='字体大小'
                          size='sm'
                          width="100px"
                        />
                        */}
                        <HStack 
                          maxW='320px'
                          width='185px'
                        >
                          <Button 
                            border="1px solid #F6AA5F"
                            borderRadius="10px"
                            color="#E4D5BF"
                            bgColor="#025F7D"
                            fontSize='20px'
                            justifyContent='center'
                            alignItems='center'
                            {...dec}
                          >-</Button>
                          <Input
                            border="1px solid #F6AA5F"
                            color='#E4D5BF'
                            bgColor='#025F7D'
                            {...input} 
                            />
                          <Button
                            border="1px solid #F6AA5F"
                            borderRadius="10px"
                            color="#E4D5BF"
                            bgColor="#025F7D"
                            fontSize='20px'
                            justifyContent='center'
                            alignItems='center'
                            {...inc}
                          >+</Button>
                        </HStack>
                      
                      </Flex>
                    <Select
                      width='144px'
                      height='43px'
                      color='#E4D5BF'
                      bgColor='#025F7D'
                      border='1px solid #F6AA5F'
                      borderRadius='8px'
                      placeholder={t(`selectFonts`)}
                      value={fontFamily}
                      onChange={(e) => handleFontFamilyChange(e.target.value)}
                      size='sm'
                      sx={{
                        "& option": {
                          backgroundColor: "#025F7D",  
                          color: "#E4D5BF", 
                        },
                        "& option:hover": {
                          backgroundColor: "#F6AA5F",  
                          color: "#FFFFFF", 
                        },
                        "&:focus": {
                          borderColor: "#F6AA5F", 
                        },
                      }}
                    >
                      {fontFamilyOptions.map(font => (
                        <option key={font} value={font}>
                          {font}
                        </option>
                      ))}
                    </Select>
                    <ColorPicker 
                      color={color}
                      handleColorChange={handleColorChange}
                    />
                  </VStack>
                )}
                <Flex
                  direction="column"
                >
                  <Flex 
                    direction="column"
                    position="relative"
                    width={{ '3xl': '683px', base: '580px' }}
                    height={{ '3xl': '630px', base: '536px' }}
                    justifyContent="center"
                    alignItems="center"
                    _before={{
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      bgImage: "url('/Home/blueprint/bg.svg')",
                      bgPosition: "top",
                      bgSize: "cover",
                      bgRepeat: "no-repeat",
                      pointerEvents: "none",
                      zIndex: 1  // 确保图片在上层
                    }}
                  >
                    {background ? (
                    <Stage
                      ref={stageRef}
                      width={hbWidth}
                      height={hbHeight}
                      onClick={(e) => {
                        const clickedOnEmpty = e.target === e.target.getStage();
                        if (clickedOnEmpty) {
                          setSelectedId(null);
                        }
                      }}
                    >
                      <Layer>
                        {typeof background === 'string' ? (
                            // 背景是纯色
                            <Rect
                              x={0}
                              y={0}
                              width={hbWidth}
                              height={hbHeight}
                              fill={background} // 填充纯色
                            />
                          ) : (
                          <KonvaImage
                            image={background}
                            width={hbWidth}
                            height={hbHeight}
                            // draggable  // 设置为可拖动
                            // onDragEnd={(e) => {
                            //     // 获取拖动结束后的新位置
                            //     const newX = e.target.x();
                            //     const newY = e.target.y();

                            //     // 更新背景位置（如果需要的话）
                            //     // setBackgroundPosition({ x: newX, y: newY }); // 你可以使用一个state来更新位置
                            // }}
                          />
                        )}
                        {elements.map((element) => (
                          <DraggableElement
                            key={element.id}
                            element={element}
                            isSelected={element.id === selectedId}
                            onSelect={() => {
                              setSelectedId(element.id); // 设置选中状态
                              if (element.type === 'text') {
                                setFontSize(element.fontSize || 20); // 更新字体大小
                                setFontFamily(element.fontFamily || 'Arial'); // 更新字体
                                setColor(element.color || 'black'); // 更新字体颜色
                                setValue(element.text); // 如果是文本，更新 value
                              }
                            }}
                            onChange={handleElementChange}
                            onDelete={handleDeleteElement}
                          />
                        ))}
                      </Layer>
                    </Stage>
                    ) : (
                      <Flex
                        bgColor="#0C4D62"
                        //width="626px"
                        //height="579px"
                        width={{ '3xl': '626px', base: '535px' }}
                        height={{ '3xl': '579px', base: '500px' }}
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        position="relative"  // 确保内容能够接收点击事件等
                      >
                        {uploading ? (
                          <Flex 
                            direction="column" 
                            alignItems="center" 
                            gap={4}
                          >
                            <Spinner
                              thickness="4px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="#F6AA5F"
                              size="xl"
                            />
                            <Box color="#E4D5BF" fontSize={{ '3xl': '26px', base: '20px' }}>
                              {t('uploading')}
                            </Box>
                          </Flex>
                        ) : (
                          <>
                            <Box
                              as="label"
                              htmlFor="background-upload-2"
                              width={{ '3xl': '270px', base: '200px' }}
                              height={{ '3xl': '270px', base: '200px' }}
                              border="2px dashed #F6AA5F"
                              borderRadius="20px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              cursor="pointer"
                              _hover={{ bg: 'rgba(246, 170, 95, 0.1)' }}
                              zIndex={10}
                              color="#F6AA5F"
                              fontSize="50px"
                              mb="10"
                            >
                              +
                              <Input
                                id="background-upload-2"
                                type="file"
                                accept="image/*"
                                onChange={handleBackgroundUploadTwo}
                                display="none"
                              />
                            </Box>
                            <Flex
                              direction="column"
                              alignItems="center"
                              color="#E4D5BF"
                              fontSize={{ '3xl': '26px', base: '20px' }}
                              fontWeight="300"
                            >
                              <Flex>{t('uploadPhotoTwo')}</Flex>
                              <Flex>{t('uploadPhotoTwo2')}</Flex>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    )}
                  </Flex>
                  <Flex mt={{ '3xl': '6', base: '3' }} justifyContent="space-around">
                      <Button 
                        width='123px'
                        height='49px'
                        bgColor='#62270C'
                        color='#E4D5BF'
                        fontSize='16px'
                        borderRadius='10px'
                        onClick={handleReset}
                      >
                        {t('reset')}
                      </Button>
                      <Button 
                        width='123px'
                        height='49px'
                        bgColor='#0C4D62'
                        color='#E4D5BF'
                        fontSize='16px'
                        borderRadius='10px'
                        onClick={handleAddText}
                      >
                        {t('addText')}
                      </Button>
                      <Button 
                        width='123px'
                        height='49px'
                        bgColor='#025F7D'
                        color='#E4D5BF'
                        fontSize='16px'
                        borderRadius='10px'
                        onClick={handleDownload}
                      >
                        {t('downloadPhoto')}
                    </Button>
                  </Flex>
                </Flex>
                <Flex 
                  ml='6'
                  direction='column'
                  width='185px '
                >
                  <VStack>
                    <Flex 
                      mb='3'
                      fontFamily="Source Han Serif CN" 
                      color='#E4D5BF' 
                      fontSize='14px'
                      letterSpacing='0.04em'
                    >Step1: {t('selectFulu')}</Flex>
                  </VStack>
                  <VStack spacing={5}>
                    <Input
                      width='150px'
                      height='43px'
                      color='#E4D5BF'
                      bgColor='#025F7D'
                      border='1px solid #F6AA5F'
                      placeholder={t(`inputNum`)}
                      value={searchId}
                      onChange={(e) => setSearchId(e.target.value)}
                    />
                    <Select
                      width={{ '3xl': '150px', base: '150px' }}
                      height='43px'
                      color='#E4D5BF'
                      bgColor='#025F7D'
                      border='1px solid #F6AA5F'
                      placeholder={t(`selectTag`)}
                      value={selectedTag}
                      onChange={(e) => setSelectedTag(e.target.value)}
                      sx={{
                        "& option": {
                          backgroundColor: "#025F7D",  
                          color: "#E4D5BF", 
                        },
                        "& option:hover": {
                          backgroundColor: "#F6AA5F",  
                          color: "#FFFFFF", 
                        },
                        "&:focus": {
                          borderColor: "#F6AA5F", 
                        },
                      }}
                    >
                      {uniqueTags.map(tag => (
                        <option key={tag} value={tag}>
                          {t(tag)}
                        </option>
                      ))}
                    </Select>
                    <Box
                      w={{ '3xl': '150px', base: '150px' }}
                      h={{ '3xl': '450px', base: '343px' }}
                      overflowY="auto" // 启用垂直滚动
                      css={{
                        '&::-webkit-scrollbar': {
                          width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                          width: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: 'gray.300',
                          borderRadius: '24px',
                        },
                      }}
                      px='6'
                      py='3'
                      bgColor='#025F7D'
                      border='1px solid #F6AA5F'
                      borderRadius='10px'
                    >
                      <SimpleGrid 
                        columns={[1]} 
                        spacing={6} 
                        w="full"
                      >
                        {filteredStickers.map((sticker: any) => {
                          const stickerNumber = parseInt(sticker.id.split('-')[1]);
                          return(
                            <Box
                              justifyContent='center'
                              key={sticker.id}
                              onClick={() => handleAddStickerTwo(sticker)}
                              cursor="pointer"
                              _hover={{ transform: 'scale(1.05)' }}
                              transition="transform 0.2s"
                            >
                              <Image
                                src={sticker.src}
                                alt={sticker.id}
                                boxSize="100px"
                                objectFit="contain"
                              />
                              <Flex 
                                justifyContent='center'
                                alignItems='center'
                                color='#E4D5BF'
                              >#{stickerNumber}</Flex>
                            </Box>
                          )
                        })}
                      </SimpleGrid>
                    </Box>
                  </VStack>
                  <Flex 
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                      <Flex
                        mt='5'
                        mb='1'
                        fontFamily="Source Han Serif CN" 
                        color='#E4D5BF' 
                        fontSize='14px'
                        letterSpacing='0.04em'
                      >
                        Step2: {t('uploadStep2')}
                      </Flex>
                      <Box
                        as="label"
                        htmlFor="background-upload-2"
                        width={{ '3xl': '150px', base: '150px' }}
                        height={{ '3xl': '49px', base: '49px' }}
                        borderRadius="10px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        cursor={uploading ? "not-allowed" : "pointer"}
                        _hover={{ bg: uploading ? 'none' : 'rgba(246, 170, 95, 0.1)' }}
                        zIndex={10}
                        color="#F6AA5F"
                        bgColor='#025F7D'
                        fontSize="25px"
                        mb="10"
                      >
                        {uploading ? (
                          <Spinner size="md" color="#F6AA5F" />
                        ) : (
                          <BsUpload />
                        )}
                        <Input
                          id="background-upload-2"
                          type="file"
                          accept="image/*"
                          onChange={handleBackgroundUploadTwo}
                          display="none"
                          disabled={uploading}
                        />
                      </Box>
                    </Flex>
                </Flex>
              </Flex>
            </TabPanel>
          </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default ImageEditor;