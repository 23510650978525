
import { 
    Flex, 
    Image,
    Text,
 } from "@chakra-ui/react";

import ContentOne from './ContentOne'
import ContentTwo from './ContentTwo'
import ContentThree from "./ContentThree";

function Home() {
    return (
        <Flex
          width='100%'
          justifyContent='center'
          overflow="hidden"
          alignItems='center'
          direction='column'
        >

            <ContentOne />
            <ContentTwo />
            <ContentThree />
        </Flex>
    )
}

export default Home;