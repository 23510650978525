import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { fuluResources } from './fuluInfo';
import { blueprint } from './blueprint';
import { fuluTags, tags } from './fuluTags';

// 翻译资源
const resources = {
  en: {
    translation: {
      welcome: 'Welcome',
      switchLanguage: '中文',
      fululist: 'List of talisman',
      word1: "The world's first Decentralized Religious PFP Ordinals Collection",
      word11: "",
      word2: 'A comprehensive fusion of religious, artistic, and portraiture elements',
      word22: "",
      word3: 'Every Ordinals Address and collection needs the Fulu Talisman to bring',
      word33: 'good fortune and blessings',
      pleasefulu: 'Request Talisman',
      fuluTitle: 'Talisman Declaration',
      fuluOneTitle: 'Introduction to Talismans',
      fuluOnePage: 'Talismans are cultural products of Chinese Daoism with a history spanning over 5,000 years. Daoists believe that talismans are the words of the Dao, the written will of the gods. They can be used to summon, control, and inspect spirits and deities, achieving goals such as expelling demons, curing illness, requesting blessings, and warding off disasters. In fact, talismans have existed since ancient times, originating when early people recorded astronomical and geographical changes using "cloud seal" symbols, which evolved into the primordial talismans. Later, the images of gods were drawn on talismanic paper to invite deities to descend, resulting in the creation of true form talismans.',
      fuluOnePageTwo: "Our Talisman collection is issued on the Bitcoin network's Ordinals protocol, combining various Daoist true-form and humanoid talismans, making it a unique digital artifact that blends religion, art, and profile pictures into one.",
      fuluTwoTitle: 'Talismans vs. Runes',
      fuluTwoPage: "The Bitcoin protocol Rune, created by Casey, culturally refers to the runes of the Norse tradition. These runes are religious symbols used for divination, empowerment, and more, with a history of nearly 2,000 years. Talismans, on the other hand, are China's version of runes. They first appeared on ceramics unearthed from the Hongshan culture of the Neolithic period, dating back 5,000 to 6,000 years. While there are 24 runes in the Norse tradition with various meanings, Chinese talismans have evolved over thousands of years, resulting in tens of thousands of different symbols, as numerous and radiant as the stars.",
      fuluTwoPageTwo: "Although thousands of talismans exist in history, many have been lost to time. From the vast river of history, we have unearthed 250 true-form and humanoid talismans that encompass the mainstream Daoist schools, forming the Ordinals collection. This is the world's first religious PFP collection issued on the Ordinals protocol.",
      fuluThreeTitle: 'Talisman Positioning',
      fuluThreePage: "The Talisman collection is an integral part of XianDAO’s goal to create the first Eastern cultural Meme IP. It is the ultimate expression of Eastern culture—it's Meme, a powerful Eastern Meme. Every player's wallet address will need a talisman to bring good fortune. Don’t sell your luck!",
      fuluFourTitle: 'Meme Culture',
      fuluFourPage: 'Richard Dawkins introduced the concept of the "Meme" in his book The Selfish Gene. Memes are binary opposites to genes: while genes ensure the biological continuity of human civilization, memes ensure the cultural continuity. Memes are concepts, cultures, and even civilizations. Eastern culture is the core Meme of human civilization, with Daoist culture being the core Meme of Eastern culture. Talismans are the core Meme of Daoist culture. Therefore, talismans represent humanity’s reverence for nature, the gods, and our longing for beautiful things, making them a profound Meme.',
      fuluFiveTitle: 'Ordinals Protocol',
      fuluFivePage: "We have chosen to issue the Talisman on Bitcoin's Ordinals protocol because Bitcoin is the most robust network in the world, capable of ensuring that talismans, which have been passed down for thousands of years, endure eternally. Casey’s creation of the Ordinals protocol was designed to create digital artifacts, and this is precisely where talismans belong. Great art and tradition will eternally live on Bitcoin's network and the Ordinals protocol.",
      fuluFivePageTwo: 'A talisman is the contract of Heaven, binding the true beings who follow it to descend. Holding it can stir the heavens and earth, shake the mountains and rivers, command the dragons and tigers, summon the ghosts and gods, heal decaying bodies, transcend life and death, and bring great prosperity to the nation while warding off disasters.',
      learnMore: 'Learn More',
      downLoadFulu: 'Download',
      inputSearch: 'Please input the talisman number',
      search: 'search',
    }
  },
  zh: {
    translation: {
      welcome: '欢迎',
      switchLanguage: 'English',
      fululist: '符箓一览',
      word1: '全球第一个道教的宗教PFP Ordinals',
      word11: '',
      word2: '集合宗教、艺术与头像类型于一身',
      word22: '',
      word3: '每一个Ordinals钱包地址都需要符箓Ordinals来增加运气',
      word33: '',
      pleasefulu: '请符',
      fuluTitle: '符箓宣言',
      fuluOneTitle: '符箓介绍',
      fuluOnePage: '符箓是中国道家的文化产物，拥有五千年的历史。道家道教认为，符箓是大道的文字，是神灵意旨的凭证，可以通过符箓调动、役使、考校鬼神，达到遣神役鬼、降妖镇魔、治病求福、祈福禳灾等目的。但其实符箓早在上古时期已经存在，古人记录天文地理变化制作“云篆”产生了先天符箓。后来将神灵形象绘制在符纸上请神下凡产生了真形符。',
      fuluOnePageTwo: '我们的符箓Ordinals合集便是发行在Bitcoin网络Ordinals协议上集合了道门各类的真形符与人形符，是宗教、艺术、头像类三位一体的数字文物。',
      fuluTwoTitle: '符箓与符文',
      fuluTwoPage: 'Casey创造的Bitcoin网络代币协议Rune在文化上指的是卢恩符文。是具有占卜、加持等功能的宗教色彩符号，有近2000年的历史。符箓，是中国的符文。符箓在新石器时代红山文化出土的陶瓷上已经出现，有5000~6000年历史。卢恩符文有24种以及不同的含义，而中国的符箓经过数千年的演化发展，存在上万种符以及不同寓意，多如繁星，璀璨至极。',
      fuluTwoPageTwo: '虽然历史上存在上万种符但很多已经难以寻找，我们从历史长河中挖掘探寻出250道真形与人形的符箓，涵盖了道教主流的门派，构成了符箓Ordinals 合集。这是世界上第一个基于Ordinals协议发行的宗教PFP Ordinals合集。',
      fuluThreeTitle: '符箓定位',
      fuluThreePage: '符箓合集本身是我们XianDAO所要打造的第一东方文化Meme IP的一部分。它是东方文化极致的体现，它就是Meme，是强大的东方Meme。每一个人玩家钱包地址里面都需要符箓带来好运。不要卖掉你的气运！',
      fuluFourTitle: 'Meme文化',
      fuluFourPage: '理查德·道金斯在其著作《自私的基因》中创造了Meme模因的概念。模因与基因是二元对立的，基因传承人类文明生物性的延续，模因传承人类文明文化性的延续。模因是概念，是文化，更是文明。而东方文化是人类文明的核心Meme，道家文化是东方文化的核心Meme，符箓是道家文化的核心Meme。所以符箓是代表着人类对大自然、神灵的敬畏以及对美好事物的向往的Meme。',
      fuluFiveTitle: 'Ordinals协议',
      fuluFivePage: '我们选择将符箓Ordinals在Btcoin网络的Ordinals协议发行。因为Btcoin是世界上最健壮的网络，可以让已经传承数千年之久的符箓继续永恒存在下去。而Casey所创造的Ordinals协议诞生初衷是创造数字文物，正是符箓应该存在的地方。伟大的艺术与传承在Btcoin网络与Ordinals协议永续存在。',
      fuluFivePageTwo: '符者，上天之合契也，群真随符摄召下降。持之，上动天地，下撼山川，明役龙虎，幽摄鬼神，功起朽骸，修脱生死，大臻邦家，小却灾祸。',
      learnMore: '了解更多',
      downLoadFulu: '下载符箓',
      inputSearch: '请输入符箓编号',
      search: '搜索',
    }
  }
};

const mergedResources = {
  en: {
    translation: {
      ...resources.en.translation,
      ...fuluResources.en.translation,
      ...blueprint.en.translation,
      ...tags.en.translation,
      ...fuluTags.en.translation
    }
  },
  zh: {
    translation: {
      ...resources.zh.translation,
      ...fuluResources.zh.translation,
      ...blueprint.zh.translation,
      ...tags.zh.translation,
      ...fuluTags.zh.translation
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: mergedResources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;