import { useState } from 'react';
import { Flex, Button, Input } from '@chakra-ui/react';

// const ColorPicker = ({ color, handleColorChange }) => {
//   const [color, setColor] = useState("#ff6347");

//   const handleColorChange = (event: any) => {
//     setColor(event.target.value);
//   };

const ColorPicker: React.FC<{
    color: any;
    handleColorChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }> = ({ color, handleColorChange }) => {

  return (
    <Flex
        alignItems="center"
        
    >
      <Input
        type="color"
        value={color}
        onChange={handleColorChange}
        width="50px"
        height="50px"
        p={0}
        m={0}
        border="none"
        borderRadius="15px"
        display="inline-block"
      />
    </Flex>
  );
};

export default ColorPicker;