
import { 
    Flex, 
    Image,
    Text,
    Box,
    AspectRatio,
 } from "@chakra-ui/react";

import { useTranslation } from 'react-i18next';
import { cdnUrl } from "../../../utils";

function ContentTwo() {

  const { t, i18n } = useTranslation();
  const flexWidth = i18n.language === 'zh' 
  ? '774px'   // 中文时的宽度
  : '1180px';  // 英文时的宽度

  const pleaseSize = i18n.language === 'zh' 
  ? '25px'   // 中文时的宽度
  : '16px';  // 英文时的宽度

  const fontFamilyor = i18n.language === 'zh' 
  ? '楷体'   // 中文时的宽度
  : 'Source Han Serif CN';  // 英文时的宽度

    return (
        <Flex
            width='100%'
            height='200vh'
            //backgroundImage='/Home/bg.svg'
            bgImage={`url(${cdnUrl}/BG2-1.svg)`}
            bgPosition="top"
            bgRepeat="no-repeat"
            bgSize="cover"
            //justifyContent='center'
            overflow="hidden"
            alignItems='center'
            direction='column'
        >
          <Flex
            pt="12"
            height='50%'
            justifyContent='center'
            overflow="hidden"
            alignItems='center'
            direction='column'
          >
            <Flex
              width={{ '2xl': '800px', base: '600px' }}
              height={{ '2xl': '258px', base: '230px' }}
              bgImage={`url(${cdnUrl}/Mask1.svg)`}
              alignItems='center'
              direction='column'
              position="relative"
              border="1px solid #466F77"
              _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: -1,
                width: '22px',
                height: '18px',
                borderBottomRightRadius: '25px',
                borderRight: '1px solid #466F77',
                borderBottom: '1px solid #466F77'
              }}
              _after={{
                content: '""',
                position: 'absolute',
                top: 0,
                right: -1,
                width: '22px',
                height: '18px',
                borderBottomLeftRadius: '20px',
                borderLeft: '1px solid #466F77',
                borderBottom: '1px solid #466F77'
              }}
            >
              <Image mt="-3.5%" src="/Home/yzImg.svg" />
              <Flex
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Flex>
                  <Text
                    color="#E4D5BF"
                    fontSize={{ '2xl': '42px', base: '32px' }}
                    fontFamily='楷体'
                  >符箓</Text>
                </Flex>
                <Text
                  color="#E4D5BF"
                  fontSize={{ '2xl': '26px', base: '22px' }}
                  fontFamily="Source Han Serif CN"
                >Fulu Talisman</Text>
              </Flex>
              <Flex
                mt='3'
                direction='column'
              >
                <Text 
                  fontSize={{ '2xl': '16px', base: '13px' }}
                  fontWeight="300"
                  lineHeight={{ '2xl': '22.99px', base: '18px' }}
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >·  {t('word1')}</Text>
                <Text 
                  fontSize={{ '2xl': '16px', base: '13px' }}
                  fontWeight="300"
                  lineHeight={{ '2xl': '22.99px', base: '18px' }}
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >{t('word11')}</Text>
                <Text 
                  fontSize={{ '2xl': '16px', base: '13px' }}
                  fontWeight="300"
                  lineHeight={{ '2xl': '22.99px', base: '18px' }}
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >·  {t('word2')}</Text>
                <Text 
                  fontSize={{ '2xl': '16px', base: '13px' }}
                  fontWeight="300"
                  lineHeight={{ '2xl': '22.99px', base: '18px' }}
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >{t('word22')}</Text>
                <Text 
                  fontSize={{ '2xl': '16px', base: '13px' }}
                  fontWeight="300"
                  lineHeight={{ '2xl': '22.99px', base: '18px' }}
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >· {t('word3')}</Text>
                <Text 
                  pl='2'
                  fontSize={{ '2xl': '16px', base: '13px' }}
                  fontWeight="300"
                  lineHeight={{ '2xl': '22.99px', base: '18px' }}
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >{t('word33')}</Text>
              </Flex>
            </Flex>
            <AspectRatio
              mb="3"
              width={{ '2xl': '800px', base: '600px' }}
              height={{ '2xl': '400px', base: '300px' }}
              border="1px solid #466F77"
              ratio={4/3}
            >
              {/*
              <iframe
                title='naruto'
                src='https://graphmultiverse.io/vedio/fulu.mp4'
                allowFullScreen
              />
              */}
              <Box
                as="iframe"
                title='video'
                src='https://www.youtube.com/embed/IwQEaLKScqs?playlist=IwQEaLKScqs&modestbranding=1'
                //width="full"
                //height="full"
                allowFullScreen
              />
              {/*
              
              <TwitterTweetEmbed
                tweetId='1879416362952761662'
              />
              */}
              {/*
              <iframe
                  title='naruto'
                  src='https://www.youtube.com/embed/QhBnZ6NPOY0'
                  allowFullScreen
                />
                */}
              {/*
              <Image src="/Home/bg.svg" alt='naruto' objectFit='cover' />
              */}
            </AspectRatio>
            <Flex 
              width={{ '2xl': '272px', base: '202px' }}
              height={{ '2xl': '74.36px', base: '53.36px' }}
              bgImage="/Home/please.svg" 
              bgSize="cover"
              _hover={{
                transform: 'scale(1.1)',
                filter: 'brightness(1.1) contrast(1.1)'  
              }}
              _active={{
                transform: 'scale(0.95)', 
              }}
              cursor="pointer"
              zIndex="11"
              alignItems="center"
              justifyContent="center"
            >
              <Text 
                fontFamily={fontFamilyor}
                color="#FFFCB9"
                fontSize={pleaseSize}
                fontWeight="700"
                lineHeight="34.49px"
                letterSpacing="0.001em"
              >{t('pleasefulu')}</Text>
            </Flex>
          </Flex>
         <Flex
          height="100vh"
          justifyContent="center"
          alignItems="center"
          direction="column"
          bgImage="url('/Home/bg-dafulu.svg')"
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="85% 85%"
          //justifyContent='center'
          overflow="hidden"
          width={flexWidth}
          textAlign="justify"    
         >
          <Text
            mb="2"
            fontFamily={fontFamilyor}
            fontWeight="700"
            fontSize={{ '5xl': '46px', '2xl': '36px', base: '30px' }}
            color="#E4D5BF"
            letterSpacing="0.04em"
            lineHeight="51.73px"
          >
            {t('fuluTitle')}
          </Text>
          <Flex
            direction="column"
            
          >
            <Text
              //mb="2"
              fontFamily="Source Han Serif CN"
              fontWeight="700"
              fontSize={{ '5xl': '25px', '2xl': '20px', base: '15px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="28.74px"
            >
              {t('fuluOneTitle')}
            </Text>
            <Text
              mt={{ '3xl': '2', base: '0' }}
              fontFamily="Source Han Serif CN"
              fontWeight="300"
              fontSize={{ '5xl': '18px', '2xl': '14px', base: '13px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="20.12px"
            >
              {t('fuluOnePage')}
            </Text>
            <Text
              mt={{ '3xl': '1', base: '0' }}
              mb="6"
              fontFamily="Source Han Serif CN"
              fontWeight="300"
              fontSize={{ '5xl': '18px', '2xl': '14px', base: '13px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="20.12px"
            >
              {t('fuluOnePageTwo')}
            </Text>
          </Flex>
          <Flex
            direction="column"
          >
            <Text
              //mb="2"
              fontFamily="Source Han Serif CN"
              fontWeight="700"
              fontSize={{ '5xl': '25px', '2xl': '20px', base: '15px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="28.74px"
            >
              {t('fuluTwoTitle')}
            </Text>
            <Text
              mt={{ '3xl': '2', base: '0' }}
              fontFamily="Source Han Serif CN"
              fontWeight="300"
              fontSize={{ '5xl': '18px', '2xl': '14px', base: '13px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="20.12px"
            >
              {t('fuluTwoPage')}
            </Text>
            <Text
              mt={{ '3xl': '1', base: '0' }}
              mb="6"
              fontFamily="Source Han Serif CN"
              fontWeight="300"
              fontSize={{ '5xl': '18px', '2xl': '14px', base: '13px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="20.12px"
            >
              {t('fuluTwoPageTwo')}
            </Text>
          </Flex>
          <Flex
            direction="column"
          >
            <Text
              //mb="2"
              fontFamily="Source Han Serif CN"
              fontWeight="700"
              fontSize={{ '5xl': '25px', '2xl': '20px', base: '15px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="28.74px"
            >
              {t('fuluThreeTitle')}
            </Text>
            <Text
              mt={{ '3xl': '2', base: '0' }}
              mb="6"
              fontFamily="Source Han Serif CN"
              fontWeight="300"
              fontSize={{ '5xl': '18px', '2xl': '14px', base: '13px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="20.12px"
            >
              {t('fuluThreePage')}
            </Text>
          </Flex>
          <Flex
            direction="column"
          >
            <Text
              //mb="2"
              fontFamily="Source Han Serif CN"
              fontWeight="700"
              fontSize={{ '5xl': '25px', '2xl': '20px', base: '15px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="28.74px"
            >
              {t('fuluFourTitle')}
            </Text>
            <Text
              mt={{ '3xl': '2', base: '0' }}
              mb="6"
              fontFamily="Source Han Serif CN"
              fontWeight="300"
              fontSize={{ '5xl': '18px', '2xl': '14px', base: '13px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="20.12px"
            >
              {t('fuluFourPage')}
            </Text>
          </Flex>
          <Flex
            direction="column"
          >
            <Text
              //mb="2"
              fontFamily="Source Han Serif CN"
              fontWeight="700"
              fontSize={{'5xl': '25px', '2xl': '20px', base: '15px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="28.74px"
            >
              {t('fuluFiveTitle')}
            </Text>
            <Text
              mt={{ '3xl': '2', base: '0' }}
              mb="6"
              fontFamily="Source Han Serif CN"
              fontWeight="300"
              fontSize={{ '5xl': '18px', '2xl': '14px', base: '13px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="20.12px"
            >
              {t('fuluFivePage')}
            </Text>
            <Text
              fontFamily="Source Han Serif CN"
              fontWeight="300"
              fontSize={{ '5xl': '18px', '2xl': '14px', base: '13px' }}
              color="#E4D5BF"
              letterSpacing="0.04em"
              lineHeight="20.12px"
            >
              {t('fuluFivePageTwo')}
            </Text>
          </Flex>
         </Flex>
          
        </Flex>
    )
}

export default ContentTwo;