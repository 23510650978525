export const fuluResources = {
  en: {
    translation: {
      "fuluTitle1": "Shangqing Wealth-Attracting Talisman",
      "fuluDes1": "Shangqing Zhao Gongming Wealth Talisman\nZhao Gongming, the leader of the Five Ways of Wealth, is the martial deity of wealth. He was accepted as a disciple by Zhang Daoling, the first generation of the Zhang family in the Han Dynasty, when he entered He Ming Mountain to practice Taoism. The image depicts him holding a 24-section iron whip in his right hand and an iron chain in his left hand. He is the guardian of all wealth, and grants fortune according to one's good deeds.",
      "fuluTitle2": "Shangqing Demon Breaking Talisman",
      "fuluDes2": "Shangqing Marshal Tianpeng Exorcism Talisman.Marshal Tianpeng is one of the Four Holy Deities of the Northern Pole, the protector of the North Emperor of Fengdu, and the embodiment of the military star of the Big Dipper. His image is depicted as having four heads and eight arms, radiating divine light. He holds a golden ruler and shakes the emperor’s bell, with a copper tooth and an iron gourd to dispel evil. This talisman is used for exorcism and protection, shielding one from illness and bringing blessings.",
      "fuluTitle3": "Shangqing Evil-Suppressing Talisman",
      "fuluDes3": "Shangqing Lei Zu Evil-Repelling Talisman\nLei Zu, the Heavenly Venerable of the Nine Heavens, is the embodiment of Yuching Zhenwang, the ninth son of the Primordial Supreme Heaven. He is the god of thunder and rain. His image is imposing, often depicted with three eyes, disheveled hair, holding a golden whip or iron hammer, riding on a black qilin. This talisman is used for warding off evil spirits, protecting households and individuals from evil disturbances.",
      "fuluTitle4": "Shangqing House Protection Talisman",
      "fuluDes4": "Shangqing Wang Lingguan Household Protection Talisman. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle5": "Shangqing Wealth-Attracting Talisman",
      "fuluDes5": "Shangqing Zhao Gongming Black Tiger Wealth Talisman\nZhao Gongming, the leader of the Five Ways of Wealth, is a Taoist deity who guards the Xuantan, and is revered as the Supreme Marshal of Xuantan. His image is often depicted with his mount, the Black Tiger, also known as the Black Tiger Xuantan. This talisman is used to attract wealth and good fortune, especially in career advancement and financial gains.",
      "fuluTitle6": "Shangqing Longevity Star Talisman",
      "fuluDes6": "Shangqing Nanji Changsheng Dadi Longevity Star Talisman. The Nanji Changsheng Dadi, also known as the Yuching Zhenwang, is one of the Four Lords of Taoism. He assists the Jade Emperor in governing the heavenly way. This talisman contains hidden symbols of the \"Fortune, Prosperity, and Longevity\" stars and is used to pray for health, long life, and good fortune.",
      "fuluTitle7": "Shangqing Harmony Talisman",
      "fuluDes7": "Shangqing Hehe Erxian Talisman.Hehe Erxian, also known as the \"Hehe Saints,\" are legendary Taoist figures who were close like brothers. The two saints, as close as brothers, both loved the same woman but gave up their desires for each other’s happiness. They later became monks and founded Hanshan Temple, later linked to Tang poet-monks \"Hanshan and Shide\" during the Qing Yongzheng era. This talisman is primarily used for marriage matters, to strengthen relationships and resolve conflicts, ensuring harmony between couples.",
      "fuluTitle8": "Shangqing Peace Talisman",
      "fuluDes8": "Shangqing Zhong Kui Peace Talisman. Zhong Kui, known as the \"True Lord of Household Protection\" or \"Demon-Exorcising Lord\" in Taoism, is one of the Three Demon Kings of Taoism. It is said that he was a man from Zhongnan Mountain during the Tang Dynasty. After failing the imperial examination due to his unattractive appearance, he took his own life in front of the palace hall. His image is depicted with a leopard head and iron face, often shown in blue robes, wearing morning boots, and holding a sword. This talisman is used to protect the household, repel evil, and bring blessings.",
      "fuluTitle9": "Shangqing Evil-Suppressing and Beneficial Talisman",
      "fuluDes9": "Shangqing Zhen Sha Evil-Repelling Talisman. This talisman is used to suppress evil energy and \"Sha Qi\" (negative energy). It combines the Taoist powers of the Shangqing school and is effective in transforming harmful energy into positive energy, restoring balance. It is ideal for clearing negative energy in homes or workplaces and restoring harmony, enhancing personal fortune and health.",
      "fuluTitle10": "Shangqing Guanyin Son-Birthing Talisman",
      "fuluDes10": "Shangqing Cihang Zhenren Son-Bearing Guanyin Talisman. Cihang Zhenren, also known as the Bodhisattva Avalokitesvara, is revered in both Taoism and Buddhism. According to legend, Cihang Zhenren was once Princess Miaoshan, who endured hardships to achieve spiritual success. The image is a serene, elegant female figure wearing a crown. This talisman is used by families wishing for children and protection for mothers during pregnancy.",
      "fuluTitle11": "Shangqing Wenchang Talisman",
      "fuluDes11": "Shangqing Kui Xing Wenchang Talisman. Kui Xing, the first star of the Big Dipper and one of the Twenty-Eight Mansions, was a mortal who excelled in the imperial examinations but was falsely accused and drowned himself in the sea. Deeply moved by his plight, the Jade Emperor bestowed upon him a vermilion brush and appointed him as the deity overseeing academic and literary success. His worship began during the Eastern Han Dynasty with the rise of the imperial examination system.Kui Xing is depicted with red hair and a blue face, holding a large dipper in his left hand and a massive brush in his right. He governs academic and official fortunes, blessing individuals with success in their examinations and careers.",
      "fuluTitle12": "Shangqing Evil-Repelling Talisman",
      "fuluDes12": "Shangqing Lei Zu Evil-Repelling Talisman. Lei Zu, the Supreme Lord of Nine Heavens and Universal Thunder Transformation, is the incarnation of Yuqing True King, the ninth son of the Primordial Celestial Venerable. He is the deity governing thunder and rain, known by the honorary title \"Lei Zu.\" Depicted as majestic and solemn, often with three eyes, disheveled hair, and holding a golden whip or iron hammer. This talisman is primarily used to repel evil spirits, protecting households and individuals from malevolent influences.",
      "fuluTitle13": "Shangqing All-Immunity Talisman",
      "fuluDes13": "Shangqing Jiang Taigong All-Immunity Protection Talisman. Jiang Taigong, also known as Lü Shang, styled Ziya, and nicknamed Feixiong or Taigong Wang, was a disciple of Yuanshi Tianzun of the Jade Void Palace. He was tasked by his master to descend to the mortal realm and oversee the enshrinement of 365 righteous deities. Revered for eliminating evil, subduing demons, and resolving inauspicious events, his talisman guards homes and blocks malevolent forces.",
      "fuluTitle14": "Shangqing All-Immunity Talisman",
      "fuluDes14": "Shangqing Jiang Taigong All-Immunity Protection Talisman. Jiang Taigong, also known as Lü Shang, styled Ziya, and nicknamed Feixiong or Taigong Wang, was a disciple of Yuanshi Tianzun of the Jade Void Palace. He was tasked by his master to descend to the mortal realm and oversee the enshrinement of 365 righteous deities. Revered for eliminating evil, subduing demons, and resolving inauspicious events, his talisman guards homes and blocks malevolent forces.",
      "fuluTitle15": "Shangqing Evil-Repelling Talisman",
      "fuluDes15": "Shangqing Deng Tianjun Evil-Repelling Talisman. Deng Tianjun, or Deng Bowen, is one of the Thunder Division deities, a great god of the Flame Emperor tasked with punishing the disloyal, unfilial, sinister ghosts, and harmful entities. He was the leader of the Deng tribe during the Yan-Huang era and served the Yellow Emperor in defeating Chiyou, earning the title of General. Deng Tianjun is depicted with a celestial crown, phoenix features, fleshy wings, and holding a thunder drill. This talisman effectively removes malevolent energy, protecting against evil influences.",
      "fuluTitle16": "Shangqing Peace Talisman",
      "fuluDes16": "Shangqing Marshal Yin Peace Talisman. Marshal Yin, or Yin Jiao, was a disciple of the Golden Tripod Mystic Transformation Master, one of the thirty-six marshals of the Thunder Division, and chief of the Earthly Tai Sui. Yin Jiao governs the yearly fortunes of humankind. Born to King Zhou and abandoned at birth, he earned the name \"Jiao\" and the surname Yin. This talisman stabilizes homes, dispels evil, neutralizes calamities, and ensures peace and safety.2",
      "fuluTitle17": "Qingwei Tranquil Heart Talisman",
      "fuluDes17": "Cao Guojiu, Supreme Lord of Transcendence in Huanghua Heavenly Realm, was born Yi, styled Gongbo and also called Jingxiu. He was the grandson of General Cao Bin and the brother of Empress Cao during Emperor Renzong’s reign. A disciple of Lü Dongbin, he is depicted wearing a gauze hat, red official robes, and holding wooden clappers, symbolizing the harmony of Yin and Yang. His talisman promotes mental peace, emotional balance, and spiritual tranquility.",
      "fuluTitle18": "Qingwei Health Talisman",
      "fuluDes18": "Han Xiangzi, Supreme Lord of Universal Enlightenment in Yaohua Heavenly Realm, was born Han Xiang and styled Qingfu. He was the nephew of the scholar Han Yu and learned Taoism from Zhongli Quan and Lü Dongbin. Achieving immortality in the Zhongnan Mountains, he is portrayed as a handsome youth holding a flute. His music makes flowers bloom and life thrive, symbolizing vitality, harmony, and eternal peace.",
      "fuluTitle19": "Qingwei Luck-Turning Talisman",
      "fuluDes19": "Iron Crutch Li. Iron Crutch Li, Supreme Lord of Ascending Lotus Transformation in Xihua Heavenly Realm, was born Li Xuan. He is the leader of the Eight Immortals and lived during the Tang Dynasty under Emperor Xuanzong. He studied Taoism in the Zhongnan Mountains under Tai Shang Lao Jun(An important deity in Chinese Taoist culture). Depicted with a lame leg, carrying a medicine gourd on his back, he wanders to heal the sick and save lives. His \"Blessing and Longevity Gourd\" contains elixirs of immortality, symbolizing resilience, blessings, and longevity.",
      "fuluTitle20": "Qingwei Luck-Turning Talisman",
      "fuluDes20": "Lan Caihe, Supreme Lord of Radiant Beauty in Wanhua Heavenly Realm, was originally named Xu Jian during the late Tang to Five Dynasties period. He is depicted in ragged clothes, with mismatched shoes—one boot and one bare foot—while carrying wooden clappers. He holds a flower basket filled with celestial treasures, symbolizing prosperity, abundance, and happiness. The talisman represents thriving life and flourishing success.",
      "fuluTitle21": "Qingwei Longevity Talisman",
      "fuluDes21": "Zhang Guolao, Supreme Lord of Profound Adaptability in Zhongtiao Heavenly Realm, was a Taoist cultivator known for his longevity and the art of reversing aging. He is depicted riding a white donkey backward, traveling across the land to enlighten people. His fish drum, which he frequently beats on his journeys, is said to foretell the past and future. This talisman symbolizes longevity and conveys the virtues of following destiny and living contentedly.",
      "fuluTitle22": "Qingwei Evil-Repelling Talisman",
      "fuluDes22": "Marshal Zhu, the Evil-Banishment Commander of the Fire Rhinoceros Thunder Palace, resides in the Shangqing Fire Rhinoceros Palace and presides over the Fire and Thunder Divisions. He commands wind, thunder, and rain, overseeing Fengdu and mountain rivers while leading celestial soldiers to subdue spirits across the three realms. During the Shang Dynasty, Marshal Zhu cultivated Taoism on Mount Taihua. He is depicted with a black face, angry expression, bulging eyes, red hair, and wearing black armor with gold trim. In his left hand, he holds an iron chain, and in his right hand, a golden hammer. This talisman is used to banish malevolent spirits, eradicate evil forces, and ward off inauspicious influences.",
      "fuluTitle23": "Qingwei Wealth-Attracting Talisman",
      "fuluDes23": "Marshal Zhao Gongming, the leader of the Five Wealth Gods and revered as the Martial God of Wealth, was a disciple of Zhang Daoling, the first-generation Celestial Master of the Han Dynasty. He is depicted holding a 24-section iron whip in his right hand and an iron chain in his left hand. He governs the world’s sources of wealth, bestowing riches upon those who perform virtuous deeds.",
      "fuluTitle24": "Qingwei Disease-Expelling Talisman",
      "fuluDes24": "Zhongli Quan, Supreme Lord of Salvation in the Terminal Disaster of Zhongnan Heavenly Realm, was also known as \"Heguzi\" and \"Zhengyangzi.\" As a historical figure from the Eastern Han to Wei-Jin period, he is one of the Five Northern Patriarchs of Taoism and a founding ancestor of Quanzhen Taoism, often called \"Zhengyang Patriarch.\" Zhongli Quan is depicted with a bare chest, holding a plantain leaf fan, with large eyes, a red face, and two top knots on his head. His fan is said to have the power to revive life, cure illnesses, and extend longevity.",
      "fuluTitle25": "Qingwei Evil-Repelling Talisman",
      "fuluDes25": "Jiang Taigong, also known as Lü Shang, styled Ziya, and nicknamed Feixiong or Taigong Wang, was a disciple of Yuanshi Tianzun of the Jade Void Palace. He was tasked by his master to descend to the mortal realm and oversee the enshrinement of 365 righteous deities. Revered for eliminating evil, subduing demons, and resolving inauspicious events, his talisman guards homes and blocks malevolent forces.",
      "fuluTitle26": "Qingwei Maternal Protection Talisman",
      "fuluDes26": "Marshal Gao, the Gold-Basin Child-Bestowing Commander of the Nine Heavens Golden Pavilion, is the leader of fertility among the Thunder Marshals, overseeing childbirth under the Nine Heavens Division of Birth. He was conceived through the Primordial Taiyi Essence and born into the Gao family of Cangzhou. He is depicted holding a golden basin in his left hand, a golden whip in his right hand, and riding a qilin. This talisman blesses women and children, aids childbirth, protects infants, resolves loneliness and disharmony, and dispels malevolent forces.",
      "fuluTitle27": "Qingwei Evil-Repelling Talisman",
      "fuluDes27": "Lü Chunyang. Lü Chunyang, the Supreme Lord of Pure Yang and Imperial Protector of Enlightenment, also known as Lü Dongbin, was a patriarch of the Taoist Alchemy School and is revered as \"Lü Zu\" or \"Pure Yang Patriarch.\" A disciple of Zhongli Quan, he later taught Taoism to Liu Haichan and Wang Chongyang, becoming one of the Five Northern Patriarchs of Quanzhen Taoism. He is often depicted carrying a long sword, renowned for its power to subdue and exorcise demons, protecting the people. The talisman symbolizes warding off evil, eliminating calamities, and ensuring safety.",
      "fuluTitle28": "Qingwei Peaceful Mind Talisman",
      "fuluDes28": "He Xiangu, the Compassionate Lady of Clear Radiance in Qingxia Heavenly Realm, was born He Qiong and is the only female among the Eight Immortals. She encountered Lü Dongbin at the age of thirteen, who gave her a celestial peach. She is depicted holding a lotus flower, symbolizing purity, untainted by worldly affairs, and spiritual transcendence.",
      "fuluTitle29": "Qingwei Evil-Repelling Talisman",
      "fuluDes29": "Marshal Zhu, the Evil-Banishment Commander of the Fire Rhinoceros Thunder Palace, resides in the Shangqing Fire Rhinoceros Palace and presides over the Fire and Thunder Divisions. He commands wind, thunder, and rain, overseeing Fengdu and mountain rivers while leading celestial soldiers to subdue spirits across the three realms. During the Shang Dynasty, Marshal Zhu cultivated Taoism on Mount Taihua. He is depicted with a black face, angry expression, bulging eyes, red hair, and wearing black armor with gold trim. In his left hand, he holds an iron chain, and in his right hand, a golden hammer. Expel unrighteous spirits, eradicate all evils in the world, and eliminate misfortunes.",
      "fuluTitle30": "Qingwei Hundred SolutionsTalisman",
      "fuluDes30": "This talisman is primarily used to resolve calamities, overcome misfortune, and repel evil, making it suitable for addressing minor spiritual disturbances, attracting auspicious energies, and mitigating bad luck.",
      "fuluTitle31": "Qingwei Evil-Repelling Wealth-Attracting Talisman",
      "fuluDes31": "Guandi, the Great Emperor of Subduing Demons in the Three Realms, is one of the Four Law Enforcement Marshals and the Martial God of Wealth. He was granted the title of Marquis during the Han Dynasty, King during the Song Dynasty, and Great Emperor during the Ming Dynasty, with additional honorary titles bestowed throughout history. His life is marked by loyalty, righteousness, bravery, and integrity, earning him the reputation as a paragon of trustworthiness. He governs the subjugation of evil, demon banishment, wealth creation, and promotion to higher positions.",
      "fuluTitle32": "Qingwei Peaceful House Protection Talisman",
      "fuluDes32": "Marshal Yin, also known as Yin Jiao, a disciple of the alchemist Shen Zhenren, is one of the Thirty-Six Marshals of the Thunder Division and holds the position of the Earth’s Tai Sui (Grand Year Star). He is responsible for managing the good and bad fortune of people throughout the year. Born as the son of King Zhou and abandoned in the wilderness, he was named after the land (Jiao). He can pacify the land, eliminate evil spirits, and protect homes.",
      "fuluTitle33": "Qingwei Career and Academic Talisman",
      "fuluDes33": "Kuixing, also known as the Lord of the Star of the Northern Dipper, is the first star of the Big Dipper and one of the Twenty-Eight Mansions. He once passed the highest exams three times, but was wronged and threw himself into the sea. The Jade Emperor, moved by his plight, granted him a red pen and tasked him with overseeing the imperial examination system.His deity worship began during the Eastern Han Dynasty with the rise of the imperial examination system. Kuixing is depicted with red hair and a blue face, holding a large dipper in his left hand and a giant brush in his right. He governs the civil exams and officials’ fortunes, ensuring success in examinations.",
      "fuluTitle34": "Qingwei Wealth-Attracting Talisman",
      "fuluDes34": "Liu Hai, also known as Liu Cao, styled Haichan, was a scholar during the Liao Dynasty and later served as Prime Minister under Emperor Liu Zongguang. He is famous for subduing a golden toad through wit, leading to his transcendence as an immortal. The toad is known for spitting out gold, symbolizing wealth and fortune.",
      "fuluTitle35": "Qingwei Peaceful House Protection Talisman",
      "fuluDes35": "Marshal Ma, titled the Gold-Armed Radiant Fire Rhinoceros Grand Immortal of the Thirty-Three Heavens, commands the Six Heavenly Officers and troops, subduing demons and spirits across the world. He is one of the Four Holy Protectors in Taoism, also known as the Zhengyi Spiritual Official and Emperor Huaguang. Born without a surname, he adopted the character \"Ma\" (Horse), as the southern direction corresponds to the Wu branch, which is associated with horses. Marshal Ma is capable of dispelling disasters, ensuring peace, protecting homes, and safeguarding individuals.",
      "fuluTitle36": "Qingwei Peaceful House Protection Talisman",
      "fuluDes36": "Qingwei Peaceful House Protection Talisman. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle37": "Qingwei Peaceful House Protection Talisman",
      "fuluDes37": "Qingwei Peaceful House Protection Talisman. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle38": "Qingwei Peaceful House Protection Talisman",
      "fuluDes38": "Qingwei Peaceful House Protection Talisman. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle39": "Qingwei Evil-Repelling and Disease-Expelling Talisman",
      "fuluDes39": "Marshal Wen, also known as Wen Qiong, is one of the Thirty-Six Heavenly Generals and one of the Four Law Enforcement Marshals. He is the first of the Ten Great Protectors of the East. A native of Wenzhou during the Tang Dynasty, he assisted General Guo Ziyi in suppressing rebellions. He is depicted with a blue face, red hair, wearing a helmet and armor, holding a jade ring in his left hand and an iron club in his right. His talisman is used to drive away evil, eliminate disasters, and protect from misfortune.",
      "fuluTitle40": "Qingwei Evil-Repelling and Disease-Expelling Talisman",
      "fuluDes40": "Marshal Wen, also known as Wen Qiong, is one of the Thirty-Six Heavenly Generals and one of the Four Law Enforcement Marshals. He is the first of the Ten Great Protectors of the East. A native of Wenzhou during the Tang Dynasty, he assisted General Guo Ziyi in suppressing rebellions. He is depicted with a blue face, red hair, wearing a helmet and armor, holding a jade ring in his left hand and an iron club in his right. His talisman is used to drive away evil, eliminate disasters, and protect from misfortune.",
      "fuluTitle41": "Qingwei Plague-Dispelling Talisman",
      "fuluDes41": "Marshal Kang. Marshal Kang, also known as the Earth’s Tai Sui, is one of the Ten Protectors under the East Emperor and one of the Thirty-Six Marshals of the Thunder Division. He is known as the Benevolent Marshal, governing the Four Directions and overseeing the local land deities. Marshal Kang, whose real name is Bao Yi, was a general of the Northern Song Dynasty, known for his kindness and compassion, never harming the young or weak, and caring for orphans and widows. People referred to him as “Benevolent to all directions.” He is depicted with thick eyebrows and a strong beard, holding a golden axe in one hand and a melon hammer in the other. His main duties involve delivering messages and dealing with pestilence.",
      "fuluTitle42": "Qingwei Evil-Repelling Peace Talisman",
      "fuluDes42": "Marshal Yin. Marshal Yin, also known as Yin Jiao, a disciple of the alchemist Shen Zhenren, is one of the Thirty-Six Marshals of the Thunder Division and holds the position of the Earth’s Tai Sui (Grand Year Star). He is responsible for managing the good and bad fortune of people throughout the year. Born as the son of King Zhou, he was abandoned in the wilderness after birth, hence his name Yin Jiao. He can pacify the land, eliminate evil spirits, and protect homes.",
      "fuluTitle43": "Qingwei Evil-Repelling Peace Talisman",
      "fuluDes43": "Marshal Yin. Marshal Yin, also known as Yin Jiao, a disciple of the alchemist Shen Zhenren, is one of the Thirty-Six Marshals of the Thunder Division and holds the position of the Earth’s Tai Sui (Grand Year Star). He is responsible for managing the good and bad fortune of people throughout the year. Born as the son of King Zhou, he was abandoned in the wilderness after birth, hence his name Yin Jiao. He can pacify the land, eliminate evil spirits, and protect homes.",
      "fuluTitle44": "Qingwei Wealth-Attracting Talisman",
      "fuluDes44": "Marshal Yue, also known as the Subduer of Demons and Bandits, is one of the Four Law Enforcement Marshals and one of the Thirty-Six Marshals of the Thunder Division. His real name is Fei, styled Pengju, a renowned general from Xiangzhou during the Song Dynasty who fought against the Jin Dynasty. He is depicted holding a long spear and an axe. He commands the army of nine celestial generals, helping people with righteousness and serving the country selflessly. He governs the banishment of demons and managing wealth.",
      "fuluTitle45": "Qingwei Wealth-Attracting Talisman",
      "fuluDes45": "Marshal Yue, also known as the Subduer of Demons and Bandits, is one of the Four Law Enforcement Marshals and one of the Thirty-Six Marshals of the Thunder Division. His real name is Fei, styled Pengju, a renowned general from Xiangzhou during the Song Dynasty who fought against the Jin Dynasty. He is depicted holding a long spear and an axe. He commands the army of nine celestial generals, helping people with righteousness and serving the country selflessly. He governs the banishment of demons and managing wealth.",
      "fuluTitle46": "Qingwei Luck-Turning Talisman",
      "fuluDes46": "The Qingwei Yueyun Talisman is specially designed for Yueyun, the son of Marshal Yue. Yueyun is the successor to his father’s divine power, primarily tasked with protecting homes and appeasing spirits. This talisman has the function of suppressing malevolent energy, clearing surrounding negative forces, and ensuring the safety of the wearer. Wearing this talisman enhances the wearer’s fortune, preventing misfortune and ensuring peace and prosperity.",
      "fuluTitle47": "Qingwei Wealth-Attracting Talisman",
      "fuluDes47": "Zhao Gongming, also known as the Shangqing Black Tiger Wealth Attracting Talisman, is the leader of the Five Wealth Gods. Appointed by Zhang Tianshi to guard the Xuantan, he is respected as the Supreme Marshal of the Xuantan in Taoism. His image features a black tiger as his mount, also known as the Black Tiger Xuantan. This talisman attracts wealth, promotes career advancement, and invites prosperity.",
      "fuluTitle48": "Qingwei House Protection and Peace Talisman",
      "fuluDes48": "Zhong Kui, the \"True Lord of the House\" or \"True Lord of Exorcism,\" is one of the Three Subduing Demon Emperors in Taoism. It is said that he was a scholar from Zhongnan Mountain during the Tang Dynasty, who died after failing the imperial exams, and his spirit fell in front of the palace. He is depicted with a leopard head, ringed eyes, an iron face, and often seen wearing a blue robe, morning boots, and holding a sword. His primary function is to ensure the safety of households, ward off evil, and bring blessings.",
      "fuluTitle49": "Qingwei Tai Sui Retreat and Demon-Dispersing Talisman",
      "fuluDes49": "Marshal Yin, also known as Yin Jiao, a disciple of the alchemist Shen Zhenren, is one of the Thirty-Six Marshals of the Thunder Division and holds the position of the Earth’s Tai Sui (Grand Year Star). He is responsible for managing the good and bad fortune of people throughout the year. Born as the son of King Zhou, he was abandoned in the wilderness after birth, hence his name Yin Jiao. He can pacify the land, eliminate evil spirits, and protect homes.",
      "fuluTitle50": "Qingwei Peaceful House Protection Talisman",
      "fuluDes50": "Qingwei Peaceful House Protection Talisman. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle51": "Qingwei Marshal Wang Talisman Secret",
      "fuluDes51": "Qingwei Marshal Wang Talisman Secret. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle52": "Qingwei Ma Gong White Snake Talisman Secret",
      "fuluDes52": "Qingwei Ma Gong White Snake Talisman. This is a unique talisman from the Qingwei sect, designed specifically to protect homes and suppress evil spirits. Ma Gong, also known as Marshal Ma, has a white snake under his command that is a powerful artifact for subduing demons and evil. The talisman combines Ma Gong's divine power with the snake's ability to suppress malevolent forces, ensuring peace and protecting the household from harm.",
      "fuluTitle53": "Qingwei Evil-Repelling and Warmth-Dispelling Talisman",
      "fuluDes53": "Marshal Wen. Yi Ling Zhao Wu Marshal Wen, also known as Wen Qiong, is one of the thirty-six Heavenly Generals and the first of the Ten Tai Bao of Dongyue. He was from Wenzhou, Tang Dynasty, and served under Guo Ziyi during the rebellion. He is depicted with a blue face and red hair, wearing a helmet and armor, holding a jade ring in his left hand and an iron mace in his right. This talisman is used to repel evil, cure pestilence, and prevent disasters.",
      "fuluTitle54": "Qingwei Barrier-Cutting Talisman",
      "fuluDes54": "Qingwei Kai Ge Talisman. This talisman is designed to mediate conflicts and communication barriers between individuals. The talisman master is Marshal Zhao, who can help open communication gaps and promote understanding and reconciliation. It is especially useful in resolving workplace or interpersonal tensions, helping to ease conflicts and improve communication.",
      "fuluTitle55": "Qingwei Divine Tiger Talisman",
      "fuluDes55": "Wei Shen Tiger Talisman. This talisman from the Qingwei sect is used to summon the power of the divine tiger. The tiger represents bravery and suppression, able to effectively drive away external evil spirits and demons. This talisman is suitable for use in homes and offices to suppress malevolent forces and drive away evil. Wearing this talisman increases the wearer’s authority, enhances personal fortune, and prevents malicious intrusions. It also provides spiritual encouragement and strength.",
      "fuluTitle56": "Qingwei Demon-Slaying Talisman",
      "fuluDes56": "Shui Fu Zhan Guai Marshal Yang. Yang Biao, also known as Marshal  Yang, is one of the Thirty-Six Heavenly Generals and one of the Ten Tai Bao of Dongyue. He is the chief of the Water Department responsible for slaying monsters and maintaining the peace of the water kingdom. He is depicted wearing battle armor and a general's helmet, with a strong, determined appearance and a sharp gaze, holding a sword to slay demons. His duties include exterminating monsters, driving away evil, and maintaining peace in the three realms.",
      "fuluTitle57": "Qingwei Messenger Talisman",
      "fuluDes57": "Zhang Jue, Jiutian Feijie Talisman. Zhang Jue, also known as Yang Gu Shen Jun, is a representative of the Qingwei sect. He is depicted wearing golden armor and red robes, with a fierce expression and holding an axe. His primary role is to capture evil spirits and suppress demons, clearing away harmful energy. The axe within the talisman symbolizes severing evil forces and swiftly eliminating negative energy to protect the wearer from harm.",
      "fuluTitle58": "Celestial Master Evil-Repelling Talisman",
      "fuluDes58": "Feng Du Killing Ghost Marshal  Lü. The Inspector and Commander of the Yang Thunder Division for the Nine Provinces, under the Supreme Lord of Thunder and Universal Transformation, serves as a great general beneath the throne of the Thunder Supreme Deity. Marshal Lü, also known as Qiu Gui, is a general under the control of the Thunder Supreme God. He is depicted with red hair, golden eyes, and green face with red arms. Marshal Lü specializes in exorcising evil spirits and punishing wrongdoers, particularly targeting those who are unfilial or engage in wickedness. His talisman is known for its strong power to expel evil and eliminate harmful forces.",
      "fuluTitle59": "Celestial Master Peaceful Evil-Repelling Talisman",
      "fuluDes59": "Xuantian Shangdi. The True Martial Emperor, also known as the Northern True Martial Emperor, is one of the gods of the north. He is believed to be the eighty-second incarnation of Tai Shang Lao Jun, who after receiving the energy of the sun, became the prince of Jing Le Kingdom. After 42 years of cultivation in Taihe Mountain, he was appointed by the Jade Emperor as the Northern True Martial Emperor. He is depicted wearing black robes and wielding a sword, treading on a tortoise and snake. This talisman is used for home protection, eliminating evil, safeguarding righteousness, prolonging life, and ensuring fertility.",
      "fuluTitle60": "Celestial Master Peaceful House Protection Talisman",
      "fuluDes60": "Celestial Master Peaceful House Protection Talisman. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle61": "Celestial Master Peaceful House Protection Talisman",
      "fuluDes61": "Celestial Master Peaceful House Protection Talisman. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle62": "Celestial Master Evil-Expelling Talisman",
      "fuluDes62": "Da Xie Mie Wu Marshal Zhu. The Great General Zhu, also known as Marshal Zhu, entered the Shangqing Fire Xi Palace and governed the Fire Department and the Thunder Department. He oversees the Wind, Thunder, and Rain Departments and commands the Fengdu and Yuedu. He also leads the Heavenly Generals and oversees the gods and spirits of the Three Realms. Marshal Zhu is depicted with a black face, glaring eyes, wearing a black robe with golden armor, holding an iron chain in his left hand and a golden hammer in his right. His talisman is used to expel evil gods and spirits, cleanse demonic forces, and eliminate misfortune.",
      "fuluTitle63": "Celestial Master Warmth-Dispelling Talisman",
      "fuluDes63": "Di Ji Tai Bao Marshal Kang. He is one of the Ten Tai Bao under the Eastern Yue Emperor, known as the Benevolent Holy General. He is also one of the \"Thirty-Six Generals of the Thunder Department\" and governs the gods of the four directions. Marshal Kang, originally named Bao Yi, was a general of the Northern Song Dynasty. Known for his compassion, he was beloved by the people and referred to as \"The Benevolent One.\" Depicted with thick brows and a powerful beard, he holds a golden axe in one hand and a melon hammer in the other. This talisman is used to transmit messages and eliminate pestilence.",
      "fuluTitle64": "Celestial Master Evil-Repelling Wealth-Attracting Talisman",
      "fuluDes64": "Jing Zhong Yi Yong Marshal Yue. The Heavenly Venerable Subduer of Demons and Bandits, one of the Four Protectors and one of the Thirty-Six Marshals of the Thunder Division. Named Fei, styled Pengju, he was a renowned general from Xiangzhou during the Song Dynasty and the foremost of the Four Great Generals of the Southern Song’s Restoration.Marshal Yue is depicted wielding a spear and a battle axe, exuding an aura of strength and righteousness. He commands the Nine Heavens' celestial soldiers, acts with integrity to save the people, protects the nation selflessly, and is responsible for subduing ghosts and managing the treasury of wealth.",
      "fuluTitle65": "Celestial Master Evil-Repelling Talisman",
      "fuluDes65": "Lei Ting Huo Bu Marshal Gou. Marshal Gou, also known as Gou Zhang, was originally named by the Jade Emperor as the Thunder God. He is depicted with a crown, a red face like blood, three furious eyes, holding a chisel in his left hand and a hammer in his right. His talisman is used to dispel fire evil, subdue demons, and eliminate evil magic.",
      "fuluTitle66": "Celestial Master House Protection Peace Talisman",
      "fuluDes66": "Liu Jia Liu Ding Marshal Jin. The Six Generals of Thunder and the Gods of the Six Ding and Six Jia, responsible for controlling wind, thunder, and spirits. Marshal Jin, known for his ability to protect homes, ensure peace, prevent disasters, and bring blessings, is a significant protector against misfortune.",
      "fuluTitle67": "Celestial Master Wealth-Attracting Talisman",
      "fuluDes67": "Jin Lun Ru Yi Marshal Zhao. Zhao Gongming, is the leader of the Five Paths of Wealth and the guardian of the Xuantan. He is depicted riding a black tiger, also known as Black Tiger Xuantan. His talisman is used for attracting wealth, improving career prospects, and bringing good fortune, especially for promotions and financial gains.",
      "fuluTitle68": "Celestial Master Evil-Expelling Protective Talisman",
      "fuluDes68": "Wu Lei Pan Guan Marshal Xin. Marshal Xin, also known as Xin Xing, is the Thunder God of the Thirty-Three Heavens. He was appointed by the Jade Emperor as the \"Thunder General.\" Originally named Xin Xing, after consuming a fire elixir, he transformed into the Thunder God and ascended to heaven. His talisman is used for eliminating evil spirits and protecting the body.",
      "fuluTitle69": "Celestial Master Disease-Expelling Protective Talisman",
      "fuluDes69": "Wu Lei Tian Yi Marshal Zhang. Marshal Zhang, also known as Zhang Yuan Bo, is depicted with flesh horns, red hair, a blue face, eagle-like eyes, a beaked nose, a blue body, fleshy wings, and dragon-like claws for hands and feet. He wears a red skirt and a celestial sash. Marshal Zhang summons and commands the Thunder God, wielding the imperial thunder summoning banner and carrying a giant axe at his waist, shaking the flag to invoke thunder.He is primarily responsible for protecting the body and dispelling illnesses, particularly yin-related ailments such as schizophrenia, ghostly afflictions, and possession-related disorders.",
      "fuluTitle70": "Celestial Master Evil-Repelling Talisman",
      "fuluDes70": "Shen Lei Zhi Shou Marshal Shi. Marshal Shi, also known as Shi Yuan, is the leader of the Divine Thunder, responsible for executing divine justice. He is one of the Thirty-Six Generals of the Thunder Department and the leader of the Five Thunders. Depicted with a black face and red robe, wearing a general's headband and a long scarf, he carries out orders from the heavens to maintain order and eliminate chaos. His talisman is used to execute divine punishment and restore justice.",
      "fuluTitle71": "Celestial Master Evil-Repelling Wealth-Attracting Talisman",
      "fuluDes71": "General of the Nine Heavens, Guan Yu. Named Yu, courtesy name Yun Chang, the Great Emperor of Demon Subjugation in the Three Realms, one of the Four Guardian Generals, and the God of Wealth in martial arts. He was awarded the title of Marquis in the Han Dynasty, King in the Song Dynasty, and Great Emperor in the Ming Dynasty, with honorary titles added throughout the ages. His life was marked by loyalty, righteousness, courage, and integrity, earning him fame throughout the world. He governs the subjugation of evil, exorcising demons, fostering wealth, and promoting career advancement.",
      "fuluTitle72": "Celestial Master Sea Navigation Peace Talisman",
      "fuluDes72": "Marshal Wu of the Surging Sea Tides. Marshal Wu, named Wu Yuan, courtesy name Zi Xu, is one of the Thirty-Six Marshals of the Thunder Department, responsible for the position of Surging Sea Tides. He holds a steel whip, wears a cloth cap or celestial crown, has three eyes, and is accompanied by the Twelve Tide Gods or the Eighteen Tide Gods. He governs: subduing demons, managing water affairs, regulating tides, and ensuring the safety of maritime travel.",
      "fuluTitle73": "Celestial Master House Protection and Bodyguard Talisman",
      "fuluDes73": "Dou kou kui shen Marshal Ma. The Great Fire-Rhinoceros Celestial Master of the Thirty-Three Heavens, the Commander of Six Heavens, subduing demons across the world. He is one of the Four Guardian Saints in Taoism. The Divine Marshal Ma, originally named Sheng, is also known as the Great Emperor of Huaguang. His surname, Ma, was taken from the southern region, which is associated with the horse. He can resolve disasters, ensure peace, and protect homes.",
      "fuluTitle74": "Celestial Master Disease-Expelling Talisman",
      "fuluDes74": "Marshal Yi of the Celestial Command, Tiangong. Full title: Grand Marshal of the Celestial Father, Guardian of the Nine Heavens, and Manager of the Five Directions. He is the incarnation of the Northern Dipper's Breaking Army Star and the successor to the Golden Eyebrow Celestial Lord. As a Taoist protector deity, he is depicted as a fierce, three-headed, six-armed warrior, towering fifty feet, with red hair, fiery red or black robes, and a golden crown. He holds an axe, bow, sword, bell, halberd, and rope. He primarily expels evil, prevents disease, and averts misfortunes.",
      "fuluTitle75": "Celestial Master Disease-Expelling Talisman",
      "fuluDes75": "Marshal Wu of the Southern Polar Region, Wu Mingda. Marshal Wu Mingda, also known as the Southern Palace Fire God and Teacher Wu Zhenjun, is one of the Thirty-Six Marshals of the Thunder Department.With a green body and red hair, flesh horns, and a yellow forehead band, he holds a black flag in his left hand, marked with the Li trigram, and a flaming sword in his right hand. Riding a fire dragon, his body emits flames, appearing awe-inspiring and fearsome. He governs: dispelling illness, repelling evil, praying for blessings, and conducting exorcisms.",
      "fuluTitle76": "Celestial Master Evil-Repelling Talisman",
      "fuluDes76": "General Meng of the Ghost Torture, Meng Yuan. Named Meng E, also known as Meng Shan, is one of the Thirty-Six Marshals of the Thunder Department. He is the Executioner of Ghosts and Demons in the Underworld, and one of the Ten Guardians of the Eastern Mountain. He is depicted with red date-colored skin, round eyes, square mouth, beard, and a purple cloth cap. He wears iron armor, a blue robe, boots, and a short skirt, wielding a large eight-cornered iron hammer. He governs: torturing ghosts, capturing souls, managing the underworld, and subduing evil spirits.",
      "fuluTitle77": "Celestial Master Disease-Expelling Demon-Expelling Talisman",
      "fuluDes77": "Marshal Pang of the Demon Subjugation, Pang Qiao. Marshal Pang, originally named Pang Qiao, courtesy name Changqing, is one of the Thirty-Six Marshals of the Thunder Department. He is depicted with sharp eyes and a sacred aura, wearing thunder armor and holding a thunder hammer. He governs: expelling dark magic, eliminating old evils, changing fate, and preventing illness.",
      "fuluTitle78": "Celestial Master Evil-Suppressing Talisman",
      "fuluDes78": "Marshal Song of the Flowing Gold Fire Bell, Song Wuji. Marshal Song Wuji, also known as the Nine Phoenix Jade Emperor Song Di, is one of the Thirty-Six Marshals of the Thunder Department, responsible for repelling evil, protecting followers, and exorcising demons. He has a blue face, three eyes, three heads, six arms, and golden armor. He holds a fire gun, fire rope, fire sword, and fire bell, riding on fire clouds. He governs: repelling evil, exorcising filth, commanding thunder, and protecting followers.",
      "fuluTitle79": "Celestial Master Son-Birthing Talisman",
      "fuluDes79": "Marshal Zhang of the Three Five Plum Immortals, Zhang Silang. Marshal Zhang Silang, also known as the Emperor of the Three Wu Plum Immortals, is one of the Thirty-Six Marshals of the Thunder Department. He participates in celestial affairs with other deities, maintaining the balance and harmony between heaven and earth. He is depicted in splendid Taoist robes, with a friendly and kind expression. He governs: aiding childbirth, exorcising demons, and capturing monsters.",
      "fuluTitle80": "Celestial Master Evil-Repelling Talisman",
      "fuluDes80": "Marshal Chen of the Taoist Priests, Chen Yuan. Marshal Chen Yuan, also known as Chen Yuanxuan, is one of the Thirty-Six Marshals of the Thunder Department. He manages the Taoist priesthood's affairs. With three golden eyes, his face is pale with red hair. He wears a golden crown, a golden armor robe, and holds a golden spear, with a fire pot on his back and a white snake at his feet. He governs: managing fortune, summoning deities, exorcising demons, and assisting in rituals.",
      "fuluTitle81": "Celestial Master Evil-Repelling Disease-Expelling Talisman",
      "fuluDes81": "Shenwei Evil-Repelling Marshal Zhou. His given name is Ju Fu (or Guang Ze), and he is one of the Thirty-Six Marshals of the Thunder Sect in Taoism. He is commonly referred to as the \"Northern Wind Wheel Ghost-Repelling Marshal.\" With red hair, sharp teeth, and a face like indigo, his hair is colored with cinnabar. He has silver teeth and giant eyes, holding a golden whip or a large moon, and stepping on a wind-fire wheel. His main function is to dispel filth, repel evil spirits, cure diseases, and eliminate demons.",
      "fuluTitle82": "Celestial Master Waterway Protection Talisman",
      "fuluDes82": "Shui Fu Demon-Slaying General Marshal Yang. His name is Yang Biao. He is one of the Thirty-Six Marshals of the Thunder Sect, one of the Ten Great Protectors of the Eastern Mountain, and a General of the Earth Deity, responsible for slaying demons in the Water Palace. Dressed in battle armor, wearing a marshal’s helmet, his posture is agile, with a stern face and sharp, dignified eyes. He holds a demon-slaying sword. His main duties include slaying monsters and demons, maintaining peace in the Water Palace, patrolling the Three Realms, and protecting against water-related dangers.",
      "fuluTitle83": "Celestial Master River and Sea Peace Talisman",
      "fuluDes83": "Dou Fu Spirit-Commanding General Marshal Liu. His given name is Ji, courtesy name is Shengu, and he is also known as Dou Fu Spirit-Commander, Xuanhua, and Ciji Zhenjun. He is one of the Thirty-Six Marshals of the Thunder Sect, one of the Twenty-Four Heavenly Lords of the Thunder Sect, and the Ninth Heaven Cloud Path Protecting General. A leader of the Cave-Xuan, with a black face, a fierce expression, disheveled robes, red hair, holding a golden whip, and wielding a fire wheel while wearing golden armor and riding on a water chariot. His main duties include controlling thunder, lightning, rain, snow, and hail, guiding the souls of the deceased, ensuring peaceful harvests, and protecting the safety of rivers and seas.",
      "fuluTitle84": "Celestial Master Evil-Repelling and Evil-Dispelling Talisman",
      "fuluDes84": "Yiling Zhaowu General Marshal Wen. His name is Wen Qiong, one of the Thirty-Six Heavenly Generals and one of the Four Protectors of the Law. He is the first of the Ten Great Protectors of the Eastern Mountain. A native of Wenzhou in the Tang Dynasty, he fought alongside Guo Ziyi to quell rebellions. A god with a green face and red hair, he wears a helmet and armor, with a jade ring in his left hand and an iron mace in his right. He can pacify evil, dispel pestilence, and prevent disasters.",
      "fuluTitle85": "Celestial Master Birth Protection Talisman",
      "fuluDes85": "Golden Palace Birth Monitoring General Marshal Gao. Known as the Nine Heavens Xu Lin Golden Palace Birth Monitoring General, he is in charge of childbirth among the Thunder Generals, and his position is within the Nine Heavens Birth Monitoring Bureau, overseeing the matters of childbirth, promoting safe delivery, and sending children. He received energy from the Primordial Taiyi and was born in the family of Gao Chun Gong in Cangzhou. He holds a golden basin in his left hand, a golden whip in his right, and rides a qilin. He protects women and children, can promote birth, safeguard life, and ward off evil spirits.",
      "fuluTitle86": "Celestial Master Evil-Repelling Disease-Expelling Talisman",
      "fuluDes86": "Fei Jie Retribution General Marshal Zhang. His given name is Jue (or Yù, Fán), courtesy name Yuanbo. He is an important deity of the Thunder Sect and is known as one of the \"Three Thunder Generals.\" With a red face, wearing golden armor, and wielding an iron axe, he rides on lightning and fire. He governs thunder, lightning, rain, and storms, punishes evil, rewards good, announces decrees, and cures diseases.",
      "fuluTitle87": "Celestial Master Disease-Expelling Peace Talisman",
      "fuluDes87": "Earth Minister Tai Sui Marshal Yin. His surname is Yin and his given name is Jiao. He is one of the Thirty-Six Marshals of the Thunder Sect, revered as \"The True General of the Earth Minister Tai Sui,\" and \"The Chief Marshal of Earth Minister, Repelling Evil in the Court.\" With a bluish face, wearing a twelve-skull necklace, a crimson robe, bare feet, and holding a yellow axe and a golden bell in his hands. He governs the misfortunes and blessings of the human world, expels evil spirits, rewards good deeds, punishes wrongdoers, and dispels disasters.",
      "fuluTitle88": "Celestial Master Bodyguard Peace Talisman",
      "fuluDes88": "Chongxiao Lord Thunder General Marshal Deng. His name is Xie, and his given name is Bowen. He is the leader of the Three Thunder Generals and the head of the Thirty-Six Heavenly Generals. Revered as the \"Taiyi Thunder Supreme Transformation Celestial Lord\" and \"Nine Heavens Flaming Fire Rule Decree General.\" With red hair and a golden crown, three eyes, and a blue face, he has a phoenix beak and fleshy wings. He holds a thunderbolt drill in his left hand and a thunder hammer in his right, his body adorned with golden rings and a red skirt. He governs wind and rain, protects the body, and ensures safety.",
      "fuluTitle89": "Celestial Master Peace Talisman",
      "fuluDes89": "Dongyu Ghost-Interrogation General Marshal Ning Amulet. This talisman is specifically designed to invoke the powers of General Ning, who governs the underworld and captures evil spirits. He is known for his expertise in judging and punishing wicked ghosts in the netherworld. The talisman has powerful abilities to repel ghosts, purify the surroundings, and protect the wearer from the influence of spirits and evil entities, ensuring the peace of households and individuals.",
      "fuluTitle90": "Celestial Master Demon-Subduing Talisman",
      "fuluDes90": "Five Thunder Law Enforcement General Marshal Bi. His given name is Zongyuan or Qie, and he is also known as the \"Yin Thunder God,\" one of the Thirty-Six Marshals of the Thunder Sect. Revered as the \"Three Realms True Black Spirit Auxiliary Transformation Celestial Lord,\" he is responsible for regulating natural disasters, checking the activities of evil spirits, and guiding the souls of the deceased to their rightful paths. Wearing a jade crown, with a slightly red face, no beard, golden armor, black robe, and boots, he holds an axe. He governs the weather, oversees the appearance of spirits, and guides souls.",
      "fuluTitle91": "Celestial Master Demon-Subduing Talisman",
      "fuluDes91": "Northern Polar Ice Pool Marshal Li. His given name is Xuan Zhen (or Zi Zhen), and he is an important warrior god in Taoism, often considered the incarnation of Zhenwu Dadi. He is famous as the \"General of the Frozen Ice Pool,\" with his birthday on the 17th day of the eighth month of the lunar calendar. With a black face and a dark robe, he holds an ice bag in his left hand and a snow wheel in his right. His main functions include summoning wind and snow, reducing fever and cooling, slaying demons, sweeping away evil spirits, and controlling the fate of life and death.",
      "fuluTitle92": "Celestial Master Bodyguard Wealth-Attracting Talisman",
      "fuluDes92": "Chongxiao Spirit Officer Marshal Ma. General Marshal Ma's original name is Ma Rongrong, given name Lingyao, and also known as Ma Sheng. He is referred to as Ma Tianjun, Three-Eyed Spirit Light, Hua Guang Tianwang, and Wuxian Hua Guang Dadi. He is one of the Thirty-Six Marshals of the Thunder Sect and the head of the Four Law Protectors in Taoism. With three heads and nine eyes, his appearance is unique and fierce. He wears a golden floral hat, a red brocade robe, and holds a golden spear and gold bricks, stepping on a fire wheel, and accompanied by thousands of fire crows. His main duties include driving away evil spirits, curing diseases, assisting childbirth, and protecting wealth.",
      "fuluTitle93": "Celestial Master Evil-Repelling and Demon-Dispelling Talisman",
      "fuluDes93": "Shangqing Thunder Marshal Marshal Cheng. His full name is Marshal Cheng, also known as Cheng Thunder Marshal, one of the Thirty-Six Marshals of the Thunder Sect. He is responsible for communication and coordination between the Thunder Sect and the Shangqing Sect. Wearing armor and holding weapons infused with lightning, his face is serious and his demeanor is heroic. His main duties include controlling thunder and lightning and dispelling evil spirits and disasters.",
      "fuluTitle94": "Celestial Master Wealth-Attracting Talisman",
      "fuluDes94": "Zhengyi Xuantan General Marshal Zhao. He is the leader of the Five Wealth Gods. Appointed by Zhang Tianshi to guard the Xuantan, he is respected as the Supreme Marshal of the Xuantan in Taoism. His image features a black tiger as his mount, also known as the Black Tiger Xuantan. This talisman attracts wealth, promotes career advancement, and invites prosperity.",
      "fuluTitle95": "Celestial Master Evil-Suppressing Disease-Removing Talisman",
      "fuluDes95": "Southern Nine Phoenix Filth-Repelling General Song. Known as the Flowing Golden Fire Bell Song General, the essence of the sun, General Song's given name is Di, courtesy name Wuji. With a green face, three eyes, three heads, six arms, golden armor, and holding a spear, he rides on a fire wheel and controls divine energy to transform into a golden dragon. He spits fire to burn evil spirits and diseases. His functions include cleansing filth, repelling evil spirits, curing diseases, summoning rain, and clearing the skies.",
      "fuluTitle96": "Celestial Master Evil-Repelling Peace Talisman",
      "fuluDes96": "Du Lei Tai Sui Marshal Yin. Marshal Yin, born Yin Jiao, is a disciple of the esteemed Master Shen Zhen, one of the Thirty-Six Marshals of the Thunder Sect, and the True General of Earth Minister Tai Sui. He governs people's fortunes and misfortunes throughout the year. The son of King Zhou, abandoned in the wilderness after birth, hence his surname Yin and given name Jiao. He can stabilize the land, dispel evil spirits, and protect households.",
      "fuluTitle97": "Celestial Master Peaceful House Protection Talisman",
      "fuluDes97": "Celestial Master Peaceful House Protection Talisman. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle98": "Celestial Master Peace Talisman",
      "fuluDes98": "Fei Jie Retribution General Marshal Zhang. His given name is Jue (or Yù, Fán), courtesy name Yuanbo. He is an important deity of the Thunder Sect and one of the \"Three Thunder Generals.\" With a red face, golden armor, and an iron axe, he rides on lightning and fire. He governs thunder, lightning, rain, and storms, punishes evil, rewards good, announces decrees, and cures diseases.",
      "fuluTitle99": "Celestial Master Bodyguard Peace Talisman",
      "fuluDes99": "Chongxiao Lord Thunder General Marshal Deng. His name is Xie, and his given name is Bowen. He is the leader of the Three Thunder Generals and the head of the Thirty-Six Heavenly Generals. Revered as the \"Taiyi Thunder Supreme Transformation Celestial Lord,\" and \"Nine Heavens Flaming Fire Rule Decree General.\" With red hair and a golden crown, three eyes, and a blue face, he has a phoenix beak and fleshy wings. He holds a thunderbolt drill in his left hand and a thunder hammer in his right, his body adorned with golden rings and a red skirt. He governs wind and rain, protects the body, and ensures safety.",
      "fuluTitle100": "Celestial Master Evil-Repelling Disease-Expelling Talisman",
      "fuluDes100": "Shenwei Evil-Repelling General Marshal Zhou. His given name is Ju Fu (or Guang Ze), and he is one of the Thirty-Six Marshals of the Thunder Sect. He is commonly referred to as the \"Northern Wind Wheel Ghost-Repelling General.\" With red hair, sharp teeth, and a face like indigo, his hair is colored with cinnabar. He has silver teeth and giant eyes, holding a golden whip or a large moon, and stepping on a wind-fire wheel. His main functions are to dispel filth, repel evil spirits, cure diseases, and eliminate demons.",
      "fuluTitle101": "Celestial Master Evil-Repelling Peace Talisman",
      "fuluDes101": "Thunder Gate Demon-Slaying Marshal Zou. His given name is Quan, courtesy name Jieyi, and he is also known as the Fengdu Demon-Slaying General or Fengdu Execution Platform Iron Body General. He is one of the Thirty-Six Marshals of the Thunder Sect and also one of the Thirty-Six Marshals of Fengdu. His birthday is on the 4th day of the 7th lunar month. He has red hair, a blue indigo face, an iron face, and a bronze hammer, wearing golden armor. He holds an iron tablet (or iron collar). His main functions include exterminating demons, driving away evil, purifying altars, and curing diseases.",
      "fuluTitle102": "Celestial Master Demon-Slaying and Demon-Expelling Talisman",
      "fuluDes102": "Thunder Fire Division General Marshal Gou. His original name is Gou Zhang, also known as Gou Yichong, Gou Liujie, or Yang Lei Shenjun. He is also called the Nine Heavens Decree Spirit Transformation True Monarch. Marshal Gou typically has a golden crown, a black or blue face, three glaring eyes, red hair, golden armor, and red clothing, holding a golden hammer drill. His main duties include controlling fire evils, expelling malice with five thunders, and eradicating demonic spirits.",
      "fuluTitle103": "Celestial Master Demon-Expelling Talisman",
      "fuluDes103": "Liu Jia Liu Ding Golden General. His name is Jin Changsheng, given name Teng or You, Gong. He is one of the Thirty-Six Marshals of the Thunder Sect and the chief of the Thunder Hall in the North Pole. He is also known as the Imperial Tiger God of the Golden Cauldron. He commands twelve armies and oversees twenty-four treatments. He has three faces and six arms, red hair, copper eyes, wearing golden armor and a dark robe, exuding wrathful power that reverberates through the Nine Mountains, shaking the underworld. His main duties include driving away ghosts and subduing evil spirits.",
      "fuluTitle104": "Celestial Master Evil-Repelling Disease-Expelling Talisman",
      "fuluDes104": "Heavenly Chief Marshal Marshal Yi. The full name of this deity is Zushi Jiutian Shangfu Wufang Du Zongguan Beiji Zuoyuan Shangjiang Du Tong Tiangong Marshal Zhenjun. His surname is Bian, and his given name is Zhuang. He is the incarnation of the North Dipper's Broken Army Star and the successor of Golden Eyebrow Lao Jun. He is a protector deity of Taoism under the Purple Emperor. This fierce figure has three heads and six arms, standing fifty feet tall, with red hair and scarlet or black clothing, wearing a dark crown, golden or red armor, and barefoot. He wields an axe, bow and arrows, sword, bell, halberd, and rope. His main duties include eliminating evil, preventing disease, and warding off disasters.",
      "fuluTitle105": "Celestial Master Warmth-Dispelling Talisman",
      "fuluDes105": "Southern Polar Wu Yang Marshal Wu. His name is Wu Mingda, also known as Southern Palace Fire Court Patriarch Wu Yang Thunder Master. He is one of the Thirty-Six Marshals of the Thunder Sect, the chief of the Southern Fire Court, and the Golden Fire Bell General of the South. With a fiery appearance, blue body, red hair, and flesh-like horns, his yellow forehead has the Li trigram. He holds a black flag in his left hand and a fire sword in his right. He rides a fire dragon and emits flames from his body. His main duties include collecting evil spirits, driving away evil, dispelling bad luck, and curing plague.",
      "fuluTitle106": "Celestial Master Demon-Expelling Talisman",
      "fuluDes106": "Flowing Golden Fire Bell General Marshal Song. His name is Song Wuji, courtesy name Di, also known as Nine Phoenix Jade-Hua General Song, Southern Phoenix Defiling General Song Di, and Fire Bell Thunder General Song. He is one of the Thirty-Six Marshals of the Thunder Sect and a protective deity in Taoism responsible for driving away evil spirits, protecting followers, and exorcising demons. He has a blue face, three eyes, three heads, and six arms, wearing golden armor. He holds a fire spear, fire rope, fire sword, and fire bell, riding a fire cloud. His main duties include driving away evil, exorcising filth, controlling thunder, subduing demons, and protecting believers.",
      "fuluTitle107": "Celestial Master Demon-Expelling Talisman",
      "fuluDes107": "Zhengyi Lu Guan General Marshal Chen. His name is Chen Yuan Yuan. He is one of the Thirty-Six Marshals of the Thunder Sect and a Lu Guan responsible for managing the fortunes of the Zhengyi Sect. He has three golden eyes, a pale face, and red hair. He wears a golden hat and robe, holding a golden spear, with a fire gourd on his back, riding a fire chariot, and wearing purple boots. His main duties include managing fortunes, summoning spirits, exorcising evil, subduing demons, and assisting in rituals.",
      "fuluTitle108": "Celestial Master Evil-Repelling and Demon-Expelling Talisman",
      "fuluDes108": "Shangqing Thunder Marshal Cheng. His full name is Cheng or Cheng Thunder Marshal, sometimes called Marshal Cheng Lei. He is one of the Thirty-Six Marshals of the Thunder Sect and responsible for communication and coordination between the Thunder Sect and the Shangqing Sect. He wears armor and holds lightning-infused weapons, with a serious and heroic appearance. His main duties include controlling thunder and lightning, driving away evil spirits, and preventing disasters.",
      "fuluTitle109": "Celestial Master Warmth-Dispelling Talisman",
      "fuluDes109": "Doughmouth Headquartered General Ma. His original name is Ma Rongrong, courtesy name Dezhi, and his given name is Lingyao, also known as Ma Sheng. He is one of the Thirty-Six Marshals of the Thunder Sect, overseeing the Southern Polar Tian Shu Court, the Wind and Fire Station, and leading the Six Heavenly Forces. He has a green face, red hair, six arms, blue skin, and a golden floral hat. His main duties include driving away evil spirits, curing diseases, warding off disasters, and subduing evil demons.",
      "fuluTitle110": "Celestial Master Waterway Peace Talisman",
      "fuluDes110": "Northern Polar Ice Pool General Marshal Li. His given name is Li Feng, courtesy name Qingtian. He is one of the Thirty-Six Marshals of the Thunder Sect and governs the Northern Ice Pool. He has a stern appearance, wearing armor and a crown, with a winter cloak, holding an ice sword. His main duties include using ice powers to subdue demons, summon frost and snow, regulate weather, and ensure the safety of waters.",
      "fuluTitle111": "Celestial Master Evil-Expelling Disease-Expelling Talisman",
      "fuluDes111": "Thunder Heaven Healer Marshal Tao. His given name is Gongji, and he is one of the Thirty-Six Marshals of the Thunder Sect. He is also known as the Nine Heavens Chief Physician Marshal and the Thunder Sound Response Transformation True Monarch. He serves as the main marshal in theHeavenly Medical Eight Trigrams Cave God Five Thunder Great Law, a ritual presided over by the Thunder Ancestor. He wears the Tian Ding crown, with a phoenix beak, flesh wings, and bare feet. He holds a sword in his right hand and a medicine bottle in his left. His main duties include driving away evil, healing diseases, adjusting yin and yang, exterminating evil spirits, rescuing from drought, and protecting health with medicine.",
      "fuluTitle112": "Celestial Master Son-Birthing Talisman",
      "fuluDes112": "Sanwu Mei Xian Marshal Zhang. His name is Zhang Silang, also known as Shangqing Sanwu Mei Xian Dadi, Jiutian Fuyuan Kaihua Lingying Zhang Dadi, and Qiqu Yu Sheng Tianzun. He is one of the Thirty-Six Marshals of the Thunder Sect and one of the Sanwu Heavenly Immortals, participating in the heavenly affairs to maintain balance and harmony between heaven and earth. He wears a magnificent Daoist robe and crown, with a kind and approachable face. His main duties include aiding childbirth and driving away demons and monsters.",
      "fuluTitle113": "Celestial Master Evil-Repelling Protective Talisman",
      "fuluDes113": "Five Thunder Judge Marshal Xin. His name is Xin Xing, courtesy name Zhenyu, and his given name is Hanchen. He is venerated in Taoism as \"Taiyi Five Thunder Judge of Heaven,\" \"Thunder's Thirty-Three Heavens Grand Marshal,\" and \"Thunder's Official Decree Chief Xin Tianjun.\" He is one of the Thirty-Six Marshals of the Thunder Sect and serves as the main bookkeeper in the Thunder Sect, overseeing the execution of justice. He wears a cow-ear hat, red hair, an iron face, and silver teeth like swords. He is dressed in a green cloud cloak and black boots, holding a thunder book in his left hand and a thunder pen in his right, with flames above. His main duties include executing punishments, supervising good and evil, maintaining order, and protecting from evil spirits.",
      "fuluTitle114": "Celestial Master Demon-Expelling Wealth-Attracting Talisman",
      "fuluDes114": "Chongxiao Spirit Officer Marshal Ma. His original name is Ma Rongrong, given name Lingyao, also known as Ma Sheng, Ma Tianjun, Three-Eyed Lingguang, Huaguang Tianwang, and Five Manifestations Huaguang Dadi. He is one of the Thirty-Six Marshals of the Thunder Sect and the leader of the Four Protecting Marshals of Taoism. He has three heads and nine eyes, with a fierce and mighty appearance. He wears a golden flower hat, a red brocade robe, and wields a golden spear and golden bricks. He rides a fire wheel, carries a fire gourd, and is accompanied by thousands of fire crows. His main duties include driving away evil, curing diseases, subduing demons, assisting childbirth, and protecting wealth.",
      "fuluTitle115": "Celestial Master Peace Talisman",
      "fuluDes115": "Head of Divine Thunder Marshal Shi. His given name is Yuan or Yuanxin, and he is also known as the Head of Divine Thunder Marshal and the Extermination and Law Enforcement Marshal Shi. He is the leader of the Five Thunder gods and the head of the Divine Thunder Court. He is one of the Thirty-Six Marshals of the Thunder Sect, responsible for executing punishments and maintaining order. He has a black face, red robe, wears a general's headscarf, and carries a long scarf. His main duties include carrying out divine will, executing punishments, and maintaining order.",
      "fuluTitle116": "Celestial Master Disease-Expelling Talisman",
      "fuluDes116": "Five Thunder Medical Marshal Marshal Zhang. His given name is Yuanbo. He is one of the Thirty-Six Marshals of the Thunder Sect and a fast-moving messenger of the Thunder Sect, also known as the Taiyi Swift and Direct Symbol Messenger, or the Thunder Six Swift Direct Symbol Messenger. He has red hair, a Xiazhi crown, a blue face, three eyes, emits fire, and wears a red robe with a green flying belt. He is dressed in golden armor and holds a fire halberd, with a demonic appearance and fangs protruding from his mouth. He rides a fire dragon. His main duties include healing diseases, combating illness, sending messages of divine retribution, and transmitting divine judgments.",
      "fuluTitle117": "Celestial Master Demon-Expelling Talisman",
      "fuluDes117": "Yiling Zhaowu Marshal Wen. His original name is Wen Qiong, courtesy name Ziyu, and his childhood name is Zhuolang. He is one of the Thirty-Six Marshals of the Thunder Sect and the chief of the Ten Great Protectors of the Eastern Mountains. He is a Taoist protector deity. He has a blue face, red hair and beard, golden eyes, and sharp fangs. He wears a purple scarf and a black robe with iron armor, with a golden belt around his waist, green boots on his feet, and holds a jade ring in his left hand and an iron tablet or wolf's fang staff in his right. His main duties include driving away evil, protecting the righteous, inspecting the Five Mountains, controlling life and death, healing diseases, saving people, and dispelling evil.",
      "fuluTitle118": "Celestial Master Demon-Expelling Talisman",
      "fuluDes118": "Eastern Prison Ghost-Torturer Marshal Ning. He is one of the Thirty-Six Marshals of the Thunder Sect, primarily serving in the Eastern Prison and specializing in torturing ghosts. He wears Taoist robes and a crown, with a serious and dignified expression and sharp eyes. He holds a ghost-torturing staff. His main duties include torturing ghosts, assisting in legal matters, and driving away evil spirits and demons.",
      "fuluTitle119": "Celestial Master Evil-Repelling Wealth-Attracting Talisman",
      "fuluDes119": "Loyal and Brave General Marshal Yue. Marshal Yue is the famous general Yue Fei from the Southern Song Dynasty, courtesy name Pengju, from Tangyin, Xiangzhou (modern-day Henan). He is one of the Thirty-Six Marshals of the Thunder Sect and one of the Four Protecting Marshals of Taoism. He is the god of the Eastern Mountain's Fast Response Office. He has three towering peaks, wearing a general's helmet, a silver snowflake armor, and holding a divine spear. He exudes an air of heroic vitality. His main duties include collecting ghosts, subduing evil spirits, guarding wealth, calming the mind, rewarding good, and punishing evil.",
      "fuluTitle120": "Celestial Master Luck-Turning Talisman",
      "fuluDes120": "Thunder Court Demon-Slaying Marshal Pang. His original name is Pang Qiao, courtesy name Changqing. He is one of the Thirty-Six Marshals of the Thunder Sect and is bestowed the title of Marshal Hunqi. He has sharp eyes, with an expression of divine majesty. He wears thunder battle armor and wields a thunder hammer. His main duties include driving away Yin demons, eliminating old evils, turning misfortune into fortune, and curing diseases and disasters.",
      "fuluTitle121": "Celestial Master Warmth-Dispelling Talisman",
      "fuluDes121": "Earth Spirit Marshal Kang. His original name is Kang Baoyi, and his Daoist title is Miaowei. He is one of the Thirty-Six Marshals of the Thunder Sect and one of the Ten Protectors under the Eastern Emperor. He is dressed in armor or Daoist robes, with a golden helmet or crown, his face kind yet dignified, with eyes that show compassion and authority. He holds a golden axe in his left hand and a melon hammer in his right. His main duties include overseeing the land gods, delivering plague notices, guarding the peace of the land, and aiding the poor and sick.",
      "fuluTitle122": "Celestial Master Evil-Repelling Wealth-Attracting Talisman",
      "fuluDes122": "Nine Heavens Demon Subduing Marshal Guan. His name is Guan Yu, courtesy name Yun Chang, also known as \"Demon Subduing Celestial Lord,\" \"Exterminating Demon Great Emperor,\" and \"Guan Sheng Di Jun,\" commonly referred to as Guan Di. He is one of the Thirty-Six Marshals of the Thunder Sect and one of the Four Protecting Marshals of Taoism. He is the mighty deity who subdues demons across the Three Realms, the God of Wealth in martial arts. He has a red face, long beard, phoenix eyes, and a firm expression. He wears a green robe with golden armor and wields a Green Dragon Crescent Blade. He rides the Red Hare horse. His main duties include subduing evil demons, promoting wealth and career advancement, curing diseases, removing disasters, punishing rebels, and inspecting the underworld.",
      "fuluTitle123": "Celestial Master Peace Talisman",
      "fuluDes123": "Fengdu Ghost Killer Marshal Marshal Lü. His name is Lü Kui, courtesy name Guixiang, believed to be a descendant of the Song Dynasty Chancellor Lü Gongzhu and the famous Lü Mengzheng. He is one of the Thirty-Six Marshals of the Thunder Sect and serves under the Thunder Sound Supreme Deity. He has a stern face, red hair, golden eyes, a green face, and red arms, dressed in Taoist robes or armor. He holds a ghost-destroying weapon. His main duties include slaying evil ghosts, punishing wrongdoers, and maintaining peace in the underworld.",
      "fuluTitle124": "Celestial Master Five Thunder Evil-Repelling Talisman",
      "fuluDes124": "Five Thunder Law Enforcing Marshal Bi. His original name is Tian Hua, courtesy name Zongyuan. He is one of the Thirty-Six Marshals of the Thunder Sect and the deputy marshal of the Five Thunder Sect responsible for expelling evil. He is dressed in a black robe with a Daoist crown, his face stern, with sharp eyes like lightning, holding a thunder charm and sword. His body is surrounded by thunder or dark energy. His main duties include commanding the Five Thunders, exterminating plagues and evil spirits, managing the weather, punishing the wicked, and guarding the Jingyang Palace.",
      "fuluTitle125": "Celestial Master Peace Talisman",
      "fuluDes125": "Doufu Spiritual Officer Liu. His name is Liu Ji, courtesy name Shenguang, also known as Doufu Shuling, Xuanhua, and Ciji Zhenjun. He is one of the Thirty-Six Marshals of the Thunder Sect and one of the Twenty-Four Heavenly Lords of the Thunder Sect. He is the general responsible for protecting the heavenly roads. He has a black face, an angry expression, red hair, and wields a golden whip and a fire wheel. His main duties include controlling winds, thunder, rain, snow, and hail, guiding the deceased to the afterlife, ensuring farming and marine safety, and delivering messages.",
      "fuluTitle126": "Celestial Master Evil-Repelling Talisman",
      "fuluDes126": "Fengdu Ghost Torturer Marshal Meng. His name is Meng E, and his original name is Meng Shan. He is one of the Thirty-Six Marshals of the Thunder Sect and serves as a mighty enforcer in Fengdu, one of the Ten Protectors of the Eastern Emperor. He has a red date-like face, round eyes, a square mouth, a mustache, a purple headscarf, and iron armor. He wears a black robe over a green shirt and boots, and he wields a large eight-cornered iron hammer. His main duties include torturing ghosts, capturing souls, managing the underworld’s prison matters, and subduing evil spirits.",
      "fuluTitle127": "Celestial Master Demon-Slaying Talisman",
      "fuluDes127": "Water Realm Monster Slayer Marshal Yang. His name is Yang Biao. He is one of the Thirty-Six Marshals of the Thunder Sect and one of the Ten Protectors of the Eastern Emperor. He serves as the marshal of the Water Realm to slay monsters. He is dressed in battle armor and a general's helmet, with a strong and stern expression, wielding a monster-slaying sword. His main duties include exterminating monsters and evil spirits, maintaining peace in the Water Realm, and inspecting the Three Realms.",
      "fuluTitle128": "Celestial Master Demon-Subduing Talisman",
      "fuluDes128": "Thunder Gate Fire Vault Marshal Zhu. His name is Zhu Yan. He is one of the Thirty-Six Marshals of the Thunder Sect and is in charge of the Thunder Gate Fire Vault, overseeing and regulating the power of thunder and fire. He has a dark face with a fierce appearance, surrounded by multiple eyes, and is dressed in golden armor with green boots. He carries a soul-chasing bag, holds an iron chain in his left hand, and a golden hammer in his right. He rides a black tiger. His main duties include punishing evil witches, capturing ghosts and gods across the Three Realms, and guiding people to enlightenment.",
      "fuluTitle129": "Celestial Master Son-Birthing and Disease-Treating Talisman",
      "fuluDes129": "Golden Quarters Superintendent Marshal Gao. His given name is Yuan or Diao, and his Dharma name is Yuan. He is one of the Thirty-Six Marshals of the Thunder Sect and the Supreme Marshal of the Nine Heavens. He is the divine supervisor of the Heavenly Records. He has a refined appearance, holding a golden basin in his left hand and a golden whip in his right, riding a qilin. His main duties include aiding childbirth, protecting life, curing diseases, and removing evil spirits.",
      "fuluTitle130": "Celestial Master Sea Navigation Safety Talisman",
      "fuluDes130": "Cang Hai Tidal Marshal Wu. His name is Wu Yuan, courtesy name Zixu. He is one of the Thirty-Six Marshals of the Thunder Sect and serves as the Marshal responsible for the surging tides of the Cang Hai Sea. He holds a steel whip, wears a headscarf or Tian Ding crown, has three eyes, and is accompanied by the Twelve or Eighteen Tide Gods. His main duties include subduing monsters and demons, managing water affairs, regulating tides and floods, and protecting sailors and navigation safety.",
      "fuluTitle131": "Deng Tianjun Talisman Secret",
      "fuluDes131": "Shangqing Deng Tianjun Evil-Repelling Talisman. Deng Tianjun, also known as Deng Bowen, is one of the Thunder Gods. He is the Supreme Deity of Thunder, in charge of executing divine orders to punish unfaithful and unfilial people, as well as malevolent spirits. As the leader of the Deng clan during the Yanhuang period, he followed Emperor Huang in the battle against Chi You and was later enshrined as a general. Deng Tianjun is often depicted wearing a heavenly crown, with phoenix beak, meat wings on his back, and holding a thunder drill. This talisman is highly effective in expelling negative energies and protecting individuals from evil influences.",
      "fuluTitle132": "Xin Tianjun Talisman Secret",
      "fuluDes132": "Xing Tianjun of the Thunder Division. Known by the personal name Hanchen (or Xin Xing, courtesy name Zhenyu), Xing Tianjun is one of the thirty-six Marshals of the Thunder Division in Daoism. His full title is \"Thunderclap Supreme Commander, Magistrate of the Thunder Division.\" He is also known as \"Silver-toothed Fierce Official Xing Tianjun\" and \"Supreme Taoist of the Five Thunder Judges.\" It is said that Xing Tianjun, originally named Xin Xing, mistakenly brought home five transformed thunder gods disguised as chickens to his mother, leading to her death by lightning strike. He is depicted with a cow-ear cap, red hair, an iron face, and silver teeth like swords. He wears a green cloud cloak, black boots, and holds a thunder ledger in his left hand and a lightning pen in his right, with flames emanating from the pen. He is responsible for protecting and guiding the common people and executing the divine thunder orders.",
      "fuluTitle133": "Xin Tianjun Talisman Secret",
      "fuluDes133": "Xing Tianjun of the Thunder Division. Known by the personal name Hanchen (or Xin Xing, courtesy name Zhenyu), Xing Tianjun is one of the thirty-six Marshals of the Thunder Division in Daoism. His full title is \"Thunderclap Supreme Commander, Magistrate of the Thunder Division.\" He is also known as \"Silver-toothed Fierce Official Xing Tianjun\" and \"Supreme Taoist of the Five Thunder Judges.\" It is said that Xing Tianjun, originally named Xin Xing, mistakenly brought home five transformed thunder gods disguised as chickens to his mother, leading to her death by lightning strike. He is depicted with a cow-ear cap, red hair, an iron face, and silver teeth like swords. He wears a green cloud cloak, black boots, and holds a thunder ledger in his left hand and a lightning pen in his right, with flames emanating from the pen. He is responsible for protecting and guiding the common people and executing the divine thunder orders.",
      "fuluTitle134": "Messenger Talisman Secret",
      "fuluDes134": "Messenger Talisman of the Red Raven. This talisman is used to summon the Red Raven messenger spirit, primarily for expelling evil spirits and harmful energies. The Red Raven is a Daoist deity known for clearing negative energies and alleviating disasters. This talisman helps the wearer eliminate surrounding negative influences, purify the environment, and ensure personal safety and stability. It is suitable for preventing personal or family misfortune, disasters, or unknown disturbances.",
      "fuluTitle135": "Yòu Lu Messenger Talisman Secret",
      "fuluDes135": "\"Yòu Lu Messenger\"refers to the \"Dulu Messenger,\" a title derived from the ancient official position of \"Dulu Shi,\" the shorthand for which is \"Dulu.\" In the Daoist underworld hierarchy, the Dulu Messenger is responsible for recording the good and bad deeds of humans and judging them, overseeing the \"Book of Life and Death.\" This position holds great importance in the afterlife, linked to the record of good and evil on Earth and the judgment after death.",
      "fuluTitle136": "Marshal Guan Talisman Secret",
      "fuluDes136": "Marshal Guan. Also known as \"Guan Sheng Dijun\" and \"The Demon-Subjugating Great Emperor of the Three Realms, Majestic and Far-Reaching Celestial Venerable Guan Sheng Dijun,\" Marshal Guan, commonly referred to as \"Guan Di,\" was originally a famed general of the Shu Han state during the Three Kingdoms period, renowned for his loyalty and righteousness.He became a legendary figure in history, famously forming the \"Oath of the Peach Garden\" with Liu Bei and Zhang Fei, representing an ideal of brotherhood. After his death, Guan Yu's spirit of loyalty and righteousness was widely celebrated, eventually deified, and incorporated into the Taoist pantheon.Marshal Guan embodies the virtues of integrity, loyalty, filial piety, righteousness, wisdom, and faith. His responsibilities include granting official positions and wealth, blessing academic success, healing diseases, eliminating disasters, exorcising evil spirits, punishing traitors, inspecting the underworld, and protecting merchants by attracting wealth.He is revered both as the God of War and the God of Wealth, earning titles such as \"Heavenly Venerable Subjugator of Demons\" and \"Great Emperor Subjugator of Demons,\" and is recognized as one of the Four Guardian Marshals in Taoism.",
      "fuluTitle137": "Hehe Three Harmony Marshal Tian Talisman Secret",
      "fuluDes137": "Tian Du Marshalrefers to the three brothers Tian Gouliu, Tian Hongyi, and Tian Zhibiao, who were honored as the gods of music, drama, and harmony. During the Tang Dynasty, they were summoned to the imperial court as royal musicians. Their talents in music and drama allowed them to cure diseases through their art. Tian Gouliu is depicted with a smiling face, black ornaments by his mouth, wearing a half-red, half-green robe and black boots, holding a golden instrument. Tian Hongyi, also known as Zhong Tian Hongyi, is depicted similarly with a white face, smiling, and holding a silver ingot in his left hand and his right hand hanging by his side. They are mainly associated with resolving emotional conflicts, changing luck, protecting households, and reconciling disputes.",
      "fuluTitle138": "Hehe Three Harmony Marshal Tian Talisman Secret",
      "fuluDes138": "Tian Du Marshalrefers to the three brothers Tian Gouliu, Tian Hongyi, and Tian Zhibiao, who were honored as the gods of music, drama, and harmony. During the Tang Dynasty, they were summoned to the imperial court as royal musicians. Their talents in music and drama allowed them to cure diseases through their art. Tian Gouliu is depicted with a smiling face, black ornaments by his mouth, wearing a half-red, half-green robe and black boots, holding a golden instrument. Tian Hongyi, also known as Zhong Tian Hongyi, is depicted similarly with a white face, smiling, and holding a silver ingot in his left hand and his right hand hanging by his side. They are mainly associated with resolving emotional conflicts, changing luck, protecting households, and reconciling disputes.",
      "fuluTitle139": "Yan Mo Luo Zhu Marshal Talisman Secret",
      "fuluDes139": "Marshal Zhu (Zhu Yan), named Yan, is an official general tasked with assisting in the revitalization of the Daoist path. He is ordered to examine and punish those who do not believe. He governs the divisions of wind, thunder, and rain, commands the spirits of Fengdu and the mountains, and oversees the celestial generals and armies. He expels improper spirits, cleanses evil influences from the world, and removes misfortune. For those who do not follow the righteous path, he can sever and bind their souls, suspend them in the void, and enact judgment to warn the ignorant and transform the mundane. He is commonly referred to as the \"Thunder Department General in charge of punishing heretics\" or \"Fire Rhino Thunder Department General punishing evil sorcerers and heretics.\" Marshal Zhu is depicted in various ways, often showing a black face with an angry expression, ringed eyes, red hair raised upwards, black robes, golden armor, sweat pants, and green boots. He holds an iron chain in his left hand and a golden hammer or iron scepter in his right, carrying a soul-chasing bag on his back and riding a black tiger.",
      "fuluTitle140": "Divine Tiger General Lu Talisman Secret",
      "fuluDes140": "Divine Tiger General Lu. The true names of Divine Tiger General are Zhao Hou and Liang Wei. His main role is to guide the souls in rituals, especially in ceremonies for purifying and retrieving souls. The Divine Tiger General's talisman holds significant religious importance in Daoism, particularly in rituals to help souls reach transcendence and peace. Through specific talismans and magical practices, this general aids souls in achieving peace and liberation.",
      "fuluTitle141": "Mighty Left Great Power Heavenly Marshal He Talisman Secret",
      "fuluDes141": "Marshal He (He Chang), also known as the Great Saint of the Yang Spirit, is also called \"Divine Tiger Xiong Zuo Yu Cao, Commander of the Three Souls of the Hundred Festivals.\" He belongs to the Divine Tiger Pursuit and Retrieval Division under the Northern Kuai Xuan Fan Mansion. In Daoist rituals and ceremonies, he is frequently summoned to retrieve the souls of the deceased. His main functions include driving away evil, avoiding disasters, and protecting believers for peace and good fortune.",
      "fuluTitle142": "Wen Gong Talisman Secret",
      "fuluDes142": "Yiling Zhaowu Marshal Wen, originally named Wen Qiong, courtesy name Ziyu, and childhood name Zhuolang. He is one of the thirty-six marshals of the Thunder Department and the leader of the Ten Great Protectors of the East. He is a Daoist guardian deity. With a blue face, red hair, and red beard, he has golden eyes and fangs, with a mouth like a firepot. His appearance is fierce and majestic, wearing a purple scarf, black robes, and iron armor, with a golden belt around his waist and green boots on his feet. He holds a jade ring in his left hand and an iron scepter or wolf-tooth staff in his right. His duties include: driving away evil, aiding righteousness, patrolling the Five Mountains, overseeing life and death, healing, and dispelling evil.",
      "fuluTitle143": "Yòu Marshal Zhu Talisman Secret",
      "fuluDes143": "Thunder Gate Fire Warehouse Marshal Zhu, originally named Zhu Yan. He is one of the thirty-six marshals of the Thunder Department and is in charge of the Thunder Gate Fire Warehouse, overseeing the power of thunder and fire. He has a black face with an evil expression, ringed eyes, wearing golden armor, green boots, and carrying a soul-chasing bag. He holds an iron chain in his left hand and a golden hammer in his right, riding a black tiger. His duties include: punishing heretics, commanding the spirits of the three realms, warning the ignorant, and investigating spirits.",
      "fuluTitle144": "Marshal Kang Talisman Secret",
      "fuluDes144": "Earth Spirit Marshal Kang, originally named Kang Baoyi, Daoist title Miaowei. He is one of the thirty-six marshals of the Thunder Department and one of the Ten Protectors of the East. He wears armor or a Daoist robe, a golden helmet or Daoist crown, and has a kind yet solemn appearance with eyes full of compassion and authority. He holds a golden axe in his left hand and a melon hammer in his right. His duties include: overseeing the earth gods of the four directions, sending messages, preventing plagues, guarding the land’s peace, and assisting the poor and sick.",
      "fuluTitle145": "Yòu Marshal Kang Talisman Secret",
      "fuluDes145": "Earth Spirit Marshal Kang, originally named Kang Baoyi, Daoist title Miaowei. He is one of the thirty-six marshals of the Thunder Department and one of the Ten Protectors of the East. He wears armor or a Daoist robe, a golden helmet or Daoist crown, and has a kind yet solemn appearance with eyes full of compassion and authority. He holds a golden axe in his left hand and a melon hammer in his right. His duties include: overseeing the earth gods of the four directions, sending messages, preventing plagues, guarding the land’s peace, and assisting the poor and sick.",
      "fuluTitle146": "Zhang Taibao Talisman Secret",
      "fuluDes146": "Xuanling Zhaoqing Marshal Zhang, one of the Ten Great Protectors of the Yue Mansion. His birth name was Zhang Xun, born in the second year of Emperor Zhongzong's reign during the Tang Dynasty. During the Anshi Rebellion, he raised an army to defend Yongqiu and resisted the rebel forces. Unfortunately, he died heroically, and a shrine was erected for him by the people. He was posthumously honored as \"Commander of Heaven and Slayer of Evil\" and \"Loyal and Brave Marshal of East Ping.\" He has the power to eliminate grievances, avert disasters, and pray for clear skies and rain.",
      "fuluTitle147": "Fengdu Marshal  Wei Talisman Secret",
      "fuluDes147": "Marshal Wei, named Wei Boxian, is known as the \"Great Saint of the Nine Ugly Spirits\" and the \"Demonic Leader of the Nine Springs in Fengdu's Inner Hall.\" He commands all the marshals of the Netherworld in the Northern Yin Fengdu.His appearance is fearsome, with a green ghostly face, three eyes, golden pupils, and fiery red hair. He wears a necklace of nine skulls, an iron serpent crown, a black robe with tied sleeves, golden armor, and black boots. He sits majestically atop nine white pythons, wielding a flaming sword.His primary responsibilities include slaying evil spirits, rectifying injustices, and eradicating malevolent forces.",
      "fuluTitle148": "Fengdu Marshal  Wei Talisman Secret",
      "fuluDes148": "Marshal Wei, named Wei Boxian, is known as the \"Great Saint of the Nine Ugly Spirits\" and the \"Demonic Leader of the Nine Springs in Fengdu's Inner Hall.\" He commands all the marshals of the Netherworld in the Northern Yin Fengdu.His appearance is fearsome, with a green ghostly face, three eyes, golden pupils, and fiery red hair. He wears a necklace of nine skulls, an iron serpent crown, a black robe with tied sleeves, golden armor, and black boots. He sits majestically atop nine white pythons, wielding a flaming sword.His primary responsibilities include slaying evil spirits, rectifying injustices, and eradicating malevolent forces.",
      "fuluTitle149": "Eight Demons Nine Serpents Marshal Yang Talisman Secret",
      "fuluDes149": "Water Court Demon Slayer Marshal Yang(Yang Biao), one of the thirty-six marshals of the Thunder Department and one of the Ten Great Protectors of the East, holds the position of Earth Spirit Marshal and Water Court Demon Slayer. Wearing battle armor and a general's helmet, he has a strong and agile build, a resolute face, sharp and authoritative eyes, and holds a sword for slaying demons. His duties include: slaying monsters and demons, maintaining peace in the Water Court, patrolling the Three Realms, and providing water-based protection.",
      "fuluTitle150": "Eight Demons Nine Serpents Marshal Yang Talisman Secret",
      "fuluDes150": "Water Court Demon Slayer Marshal Yang(Yang Biao), one of the thirty-six marshals of the Thunder Department and one of the Ten Great Protectors of the East, holds the position of Earth Spirit Marshal and Water Court Demon Slayer. Wearing battle armor and a general's helmet, he has a strong and agile build, a resolute face, sharp and authoritative eyes, and holds a sword for slaying demons. His duties include: slaying monsters and demons, maintaining peace in the Water Court, patrolling the Three Realms, and providing water-based protection.",
      "fuluTitle151": "Marshal Liu Talisman Secret",
      "fuluDes151": "Doufu Shuling Marshal Liu. He also known as Ji, with the courtesy name Shen Guang, or alternatively, Fu or Hou. Also called Doufu Shuling, Xuanhua, Ciji Zhenjun, among other names. One of the Thirty-Six Marshals of the Thunder Department and the Twenty-Four Heavenly Lords of the Thunder Department, he serves as the Chief General of the Heavenly Palace, Protector of the Nine Heavens, and Guard of the Cloud Road. He is depicted with three eyes, disheveled hair, holding a golden whip or iron hammer. This deity governs thunder, rain, snow, hail, and wind, guides the souls of the deceased, and protects agriculture and sea traffic.",
      "fuluTitle152": "Female Right Wu Luan Qiao General Talisman Secret",
      "fuluDes152": "Lei Fu Qu Mo Marshal Pang. Marshal Pang, originally named Pang Qiao, with the courtesy name Chang Qing. He is one of the Thirty-Six Marshals of the Thunder Department, bestowed with the title of \"Marshal of the Mixed Qi.\" He is depicted with sharp eyes, a holy and dignified aura, wearing thunder armor, and holding a thunder hammer. He is responsible for driving away evil spirits, dispelling bad omens, changing fortune, and curing diseases.",
      "fuluTitle153": "Dan Tian Fire Bell Song General Talisman Secret",
      "fuluDes153": "Liu Jin Huo Ling Marshal Song. Liu Jin Huo Ling Marshal Song, named Song Wuji, courtesy name Di, also called Jiufeng Yuhua Si Marshal Song, Nanfang Jiufeng Po Hui Marshal Song Di, and Huo Ling Du Lei Marshal Song. One of the Thirty-Six Marshals of the Thunder Department, this deity is responsible for repelling evil, protecting believers, and exorcising ghosts. He is depicted with a blue face, three eyes, three heads, six arms, and golden armor. He holds a fire spear, fire rope, fire sword, and fire bell, riding on a fire cloud. He repels evil, supervises thunder, combats demons, and protects the faithful.",
      "fuluTitle154": "North Polar Cang Marshal Wu  Talisman Secret",
      "fuluDes154": "Cang Hai Yong Chao Marshal Wu, named Wu Yuan, courtesy name Zi Xu. He is one of the Thirty-Six Marshals of the Thunder Department, overseeing the tides of the Cang Hai (Ocean). He is depicted with a steel whip, wearing a head covering, and possessing three eyes. He is accompanied by twelve tide gods or eighteen tide gods. His responsibilities include subduing demons, managing water affairs, regulating tides, and ensuring the safety of sailors.",
      "fuluTitle155": "Black-Faced Catching Disease Lei Marshal Talisman Secret",
      "fuluDes155": "Marshal Lei, also known as Lei Hong, the Swift Black-Faced Thunder Marshal, and also called Chidian Hongguang Wang Lei Hong Dazhen, is depicted holding a command talisman and wearing an iron chain. He is mainly invoked to resolve grievances, lift punishments, and redeem lives.",
      "fuluTitle156": "Summon Master Protecting Birth Marshal Gao Talisman Secret",
      "fuluDes156": "Marshal Gao, also known as Yuan or Diao, is one of the Thirty-Six Marshals of the Thunder Department, a fierce and born Marshal from the Nine Heavens, also called the Nine Heavens Monitoring Deity. He is depicted with a crown-like jade ornament, holding a golden basin in his left hand and a golden whip in his right hand, riding a qilin. He is responsible for granting offspring, ensuring safe childbirth, curing illnesses, and protecting life.",
      "fuluTitle157": "Heavenly Doctor Marshal Zhao Talisman Secret",
      "fuluDes157": "Hun Yuan Ba Gua Tian Yi Zhu Shuai Marshal Zhao, also known as Zhao Bang Ying, is the Supreme Marshal of the Thunder Medicine Department and the leading figure in the Thirty-Six Thunder Medicine Marshals. His medical arts can heal living beings and treat lingering souls. He subdues evil and calms plagues, keeping away demonic forces and curing epidemics.",
      "fuluTitle158": "Hanging Ghost Marshal Wu Talisman Secret",
      "fuluDes158": "Geng Yin Tai Sui Wu Huan Great General. One of the Sixty Jiazi Gods, he is depicted as a tiger, holding an immortal pill. Born in Xinchang during the Yuan Dynasty, he served as the Deputy Magistrate of Liyang County in the Xuande era, helping the weak, suppressing the strong, and eliminating corruption. His main role is to bring good fortune and avoid misfortune.",
      "fuluTitle159": "Fengdu Marshal Deng Talisman Secret",
      "fuluDes159": "Feng Du Tai Shou Guo Mie Xie Jing Marshal Deng. Known as Ai Ji Ming, this Marshal is associated with ghost exorcism, promoting virtuous people, resolving disputes, inspecting corruption, and appointing officials. He is often found in the Feng Du's examination laws and is known for his ability to expel evil and cure plagues.",
      "fuluTitle160": "Wind-Wheel Demon-Cleansing Marshal Zhou Talisman Secret",
      "fuluDes160": "Marshal Zhou, also known as Ju Fu (or Guang Ze), is one of the Thirty-Six Marshals of the Thunder Department. He is typically referred to as \"Northern Wind Wheel Exorcist Marshal Zhou.\" He is depicted with red hair, fangs, and a blue face, carrying a golden whip or large crescent blade, with feet on a wind and fire wheel. He is responsible for driving out filth, exorcising evil, healing diseases, and eliminating demons.",
      "fuluTitle161": "Dongqing Zhang Shenjun Talisman Secret",
      "fuluDes161": "Fei Jie Bao Ying Marshal Zhang, named Jue (also written as Yu or Fan), courtesy name Yuan Bo, is one of the important deities in the Thunder Department of Taoism. He, along with Marshal Deng and Marshal Xin, is known as the \"Three Marshals of Thunder.\" Depicted with a red face, golden armor, holding an iron axe, riding on lightning and fire. He governs thunder, clouds, lightning, and rain, punishes the five sins, rewards the virtuous, punishes the wicked, transmits orders, and heals diseases.",
      "fuluTitle162": "She Ling Cheng Shizhe Talisman Secret",
      "fuluDes162": "She Ling Cheng Shizhe Talisman Secret. This is a talisman used to summon the divine power of Cheng Shizhe, the deity in charge of guarding households and the community in Taoism. Cheng Shizhe is responsible for protecting local people. The talisman can resolve unstable energy fields, enhancing the security and stability of the home. It is particularly useful in preventing external intrusions and ensuring family safety, especially during unstable times or disasters, quickly restoring peace. Its power is strong enough to call upon Cheng Shizhe's protection.",
      "fuluTitle163": "Left Demon-Subduing Marshal Gou Talisman Secret",
      "fuluDes163": "Lei Ting Huo Bu Marshal Gou, originally named Gou Zhang, also known as Gou Yi Chong or Gou Liu Ji, is titled \"Yang Lei Shen Jun\" and also called \"Jiutian Ying Yun Xuan Ling Yan Hua Zhen Jun.\" He is typically depicted with a golden crown, black or blue face, three glaring eyes, red hair, golden armor, red clothing, and red shoes, holding a golden hammer and drill. He governs the expulsion of fire evils, the five thunder strikes, and sweeping away demons and evil spirits.",
      "fuluTitle164": "Right Demon-Subduing Marshal Marshal Bi Talisman Secret",
      "fuluDes164": "Marshal Bi, with the courtesy name Zong Yuan or Zai, titled \"Yin Lei Shen Jun,\" is one of the Thirty-Six Marshals of the Thunder Department. He is revered as \"San Jie Tong Zhen Hei Ling Fu Hua Tian Zun\" (Three Realms Unified True Black Spirit Assistant of Heavenly Transformation), \"Wu Lei Dang Xiong Deputy Marshal\" (Five Thunder Sweep Evil Deputy Marshal), and \"Lei Men You Fa Mo Tian Li\" (Thunder Gate Right Demon-Exterminator Heavenly Officer). He wears a jade crown, has a slightly red face, no beard, golden armor, black clothes, and black shoes, and holds an axe. He governs the heavens, earth, water, and droughts, inspects the actions of spirits, and guides souls for spiritual purification.",
      "fuluTitle165": "Marshal Pang Talisman Secret",
      "fuluDes165": "Lei Fu Qu Mo Marshal Pang.Pang Marshal , originally named Pang Qiao, courtesy name Chang Qing, is one of the Thirty-Six Marshals of the Thunder Department, bestowed with the title of \"Mixed Qi Marshal.\" He is depicted with sharp eyes, a dignified aura, wearing thunder armor, and holding a thunder hammer. He is responsible for driving away evil spirits, eliminating bad omens, changing fortunes, and curing diseases.",
      "fuluTitle166": "Shenxiao Chief Marshal Shi Talisman Secret",
      "fuluDes166": "Marshal Shi, named Yuan or Yuan Xin, also known as \"Shen Lei Zhu Shou Marshal Shi\" and \"Zhu Lu Zhi Fa Marshal Shi,\" is one of the Thirty-Six Marshals of the Thunder Department and the leader of the Five Thunders. He resides in the capital of divine thunder and is a key marshal in charge of administering divine punishment. He is depicted with a black face, red robe, wearing a Guan Shuai headscarf and a long cloth belt. He governs the transformation on behalf of Heaven, executes unjust punishment, and maintains order.",
      "fuluTitle167": "Marshal Xie Talisman Secret",
      "fuluDes167": "Dou Zhong Yun Huo Da Sheng Yu Fan Marshal Xie. One of the six messengers often summoned during battles, residing in the Outer Court of the Upper Clear Yin. Yu Fan and Miao Fan are considered the messengers in charge of water and fire during the battles. Together with Lei Zu, they punish evildoers, resolve grievances, and assist the country and people. They deal with matters related to water, droughts, locusts, and more, often bringing these matters to Lei Zu and reporting to Yu Fan and Miao Fan.",
      "fuluTitle168": "Marshal Ning Talisman Secret",
      "fuluDes168": "Dong Yu Kao Gui Marshal Ning. One of the Thirty-Six Marshals of the Thunder Department, primarily serving in the Eastern Prison, specializing in the torture and examination of ghosts. He wears Taoist robes and a Taoist crown, with a serious and dignified expression, sharp eyes, and holds a ghost torture staff. He is responsible for torturing ghosts, investigating evil, assisting in legal matters, and exorcising evil spirits.",
      "fuluTitle169": "Marshal Ren Talisman Secret",
      "fuluDes169": "Marshal Ren, known as Chi Yang, is one of the six frequently summoned messengers during Taoist battles, residing in the Outer Court of the Upper Clear Yin. He is often depicted as a foreign person with three eyes and two arms, or with three heads and six arms, in a furious appearance. He acts on behalf of Heaven, assisting the country and people.",
      "fuluTitle170": "Marshal Gao Talisman Secret",
      "fuluDes170": "Jin Que Jian Sheng Marshal Gao, also known as Yuan or Diao, is one of the Thirty-Six Marshals of the Thunder Department, a fierce-born Marshal from the Nine Heavens and the Nine Heavens Monitoring Deity. He is depicted with a jade crown, holding a golden basin in his left hand and a golden whip in his right hand, riding a qilin. He is responsible for granting offspring, ensuring safe childbirth, curing illnesses, and protecting life.",
      "fuluTitle171": "Marshal Wang Talisman Secret",
      "fuluDes171": "Marshal Wang Talisman Secret. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle172": "Marshal Liu Talisman Secret",
      "fuluDes172": "Dou Fu Shu Ling Marshal Liu, named Ji, courtesy name Shen Guang, also known as Fu or Hou, is one of the Thirty-Six Marshals of the Thunder Department and one of the Twenty-Four Heavenly Kings of the Thunder Department. He holds the title of General of the Heavenly Court and is the protector of the Nine Heavens. He is depicted with a black face, a fierce expression, red hair, holding a golden whip, fire wheel, and wearing golden armor. He governs wind, thunder, rain, snow, and hail, guides the souls of the deceased to the afterlife, ensures good harvests, and brings peace to rivers and seas.",
      "fuluTitle173": "Marshal Zhang Talisman Secret",
      "fuluDes173": "Fei Jie Bao Ying Marshal Zhang, named Jue (also written as Yu or Fan), courtesy name Yuan Bo, is an important deity in the Thunder Department of Taoism. Along with Marshal Deng and Marshal Xin, they are known as the \"Three Marshals of Thunder.\" Depicted with a red face, golden armor, holding an iron axe, riding on lightning and fire. He governs thunder, clouds, lightning, rain, and destroys the five major sins, rewarding the virtuous, punishing the wicked, transmitting orders, and healing diseases.",
      "fuluTitle174": "Marshal Wang Talisman Secret",
      "fuluDes174": "Marshal Wang Talisman Secret. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle175": "Primordial General Talisman Secret",
      "fuluDes175": "Primordial General Talisman Secret. Wang Lingguan, originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle176": "Right Primordial General Talisman Secret",
      "fuluDes176": "Dao Jiao Right Chief Generals. The right chief generals in Taoism include figures such as Marshal Deng, Marshal Zhang, Marshal Pang, Marshal Gou, Marshal Zhu, and Marshal Yue. These generals are often referred to as \"Grand Marshals\" or simply \"Marshals\" during their duties in exterminating demons and evil spirits. They hold the highest command in military operations and also oversee and command other divine generals in Taoist rituals.",
      "fuluTitle177": "Right Primordial General Talisman Secret",
      "fuluDes177": "Dao Jiao Right Chief Generals. The right chief generals in Taoism include figures such as Marshal Deng, Marshal Zhang, Marshal Pang, Marshal Gou, Marshal Zhu, and Marshal Yue. These generals are often referred to as \"Grand Marshals\" or simply \"Marshals\" during their duties in exterminating demons and evil spirits. They hold the highest command in military operations and also oversee and command other divine generals in Taoist rituals.",
      "fuluTitle178": "Marshal Ma Talisman Secret",
      "fuluDes178": "Chong Xiao Ling Guan Marshal Ma, originally named Ma Rongrong, courtesy name Ling Yao, also known as Ma Sheng, is one of the Thirty-Six Marshals of the Thunder Department and the leader of the Four Protecting Generals in Taoism. He is depicted with three heads and nine eyes, a fierce and intimidating appearance. He wears a golden flower hat, a red embroidered robe, and holds a golden spear and bricks. His feet are on a fire wheel, he carries a fire ladle on his back, and is accompanied by countless fire crows. He governs the expulsion of evil, the elimination of plagues, healing of diseases, the subjugation of demons, aiding childbirth, and ensuring prosperity and wealth.",
      "fuluTitle179": "Marshal Ma Talisman Secret",
      "fuluDes179": "Chong Xiao Ling Guan Marshal Ma, originally named Ma Rongrong, courtesy name Ling Yao, also known as Ma Sheng, is one of the Thirty-Six Marshals of the Thunder Department and the leader of the Four Protecting Generals in Taoism. He is depicted with three heads and nine eyes, a fierce and intimidating appearance. He wears a golden flower hat, a red embroidered robe, and holds a golden spear and bricks. His feet are on a fire wheel, he carries a fire ladle on his back, and is accompanied by countless fire crows. He governs the expulsion of evil, the elimination of plagues, healing of diseases, the subjugation of demons, aiding childbirth, and ensuring prosperity and wealth.",
      "fuluTitle180": "Mie Wu Zhu Lei Jun Talisman Secret",
      "fuluDes180": "Da Xie Mie Wu Marshal Zhu. He enters and exits the Upper Clear Fire Rhinoceros Palace, overseeing the fire department of the Thunder Department. He governs the wind, thunder, and rain, commanding the spirits of Fengdu and Yue Du, leading heavenly generals, and capturing spirits from the Three Realms. Marshal Zhu once practiced Taoism at Mount Taihua during the Shang Dynasty. He is depicted with a black face, a fierce expression, and red hair. He wears a black robe with golden armor, holding an iron chain in his left hand and a golden hammer in his right. He is tasked with driving away evil spirits, purging evil from the world, and eliminating ominous influences.",
      "fuluTitle181": "Yòu Lu Marshal Zhu Talisman Secret",
      "fuluDes181": "Da Xie Mie Wu Marshal Zhu. He enters and exits the Upper Clear Fire Rhinoceros Palace, overseeing the fire department of the Thunder Department. He governs the wind, thunder, and rain, commanding the spirits of Fengdu and Yue Du, leading heavenly generals, and capturing spirits from the Three Realms. Marshal Zhu once practiced Taoism at Mount Taihua during the Shang Dynasty. He is depicted with a black face, a fierce expression, and red hair. He wears a black robe with golden armor, holding an iron chain in his left hand and a golden hammer in his right. He is tasked with driving away evil spirits, purging evil from the world, and eliminating ominous influences.",
      "fuluTitle182": "Zhao Xuantan Talisman Secret",
      "fuluDes182": "Marshal Zhao Gongming, is the leader of the Five Wealth Gods. Appointed by Zhang Tianshi to guard the Xuantan, he is respected as the Supreme Marshal of the Xuantan in Taoism. His image features a black tiger as his mount, also known as the Black Tiger Xuantan. This talisman attracts wealth, promotes career advancement, and invites prosperity.",
      "fuluTitle183": "Yòu Zhao Xuantan Talisman Secret",
      "fuluDes183": "Marshal Zhao Gongming, is the leader of the Five Wealth Gods. Appointed by Zhang Tianshi to guard the Xuantan, he is respected as the Supreme Marshal of the Xuantan in Taoism. His image features a black tiger as his mount, also known as the Black Tiger Xuantan. This talisman attracts wealth, promotes career advancement, and invites prosperity.",
      "fuluTitle184": "Right Yin General Talisman Secret",
      "fuluDes184": "Du Lei Tai Sui Marshal Yin. Marshal Yin, also known as Yin Jiao, is a disciple of the Golden Cauldron Taoist Master Shen Zhenren and one of the 36 Marshals of the Thunder Department. As the Tai Sui of the Earth Deity, he governs the good and bad luck of people throughout the year. The son of King Zhou, he was abandoned after birth and thus named \"Jiao\" and took his kingdom as his surname. He is capable of calming the land, dispelling evil, and protecting the household from harm.",
      "fuluTitle185": "Earthly Supervisor Chief General Marshal Yin Talisman Secret",
      "fuluDes185": "Du Lei Tai Sui Marshal Yin. Marshal Yin, also known as Yin Jiao, is a disciple of the Golden Cauldron Taoist Master Shen Zhenren and one of the 36 Marshals of the Thunder Department. As the Tai Sui of the Earth Deity, he governs the good and bad luck of people throughout the year. The son of King Zhou, he was abandoned after birth and thus named \"Jiao\" and took his kingdom as his surname. He is capable of calming the land, dispelling evil, and protecting the household from harm.",
      "fuluTitle186": "Marshal Yin Talisman Secret",
      "fuluDes186": "Du Lei Tai Sui Marshal Yin. Marshal Yin, also known as Yin Jiao, is a disciple of the Golden Cauldron Taoist Master Shen Zhenren and one of the 36 Marshals of the Thunder Department. As the Tai Sui of the Earth Deity, he governs the good and bad luck of people throughout the year. The son of King Zhou, he was abandoned after birth and thus named \"Jiao\" and took his kingdom as his surname. He is capable of calming the land, dispelling evil, and protecting the household from harm.",
      "fuluTitle187": "Doulao Talisman Secret",
      "fuluDes187": "Doumu Yuanjun. Also known as Doulao, Doumu, or the Primal Mother of Taoism. She is the primordial Yin energy of the Primordial Celestial Venerable, transformed into the Western country of Xizhu, where she became the queen of the royal family, known as \"Purple Light Madam.\" She is depicted as a goddess with three eyes, four heads, and eight arms, holding various magical instruments while seated on a lotus throne. Doumu Yuanjun can eliminate disasters, resolve difficulties, and prolong life. People with illnesses often seek her help.",
      "fuluTitle188": "Yuebai Zhu Tianjun Talisman Secret",
      "fuluDes188": "Yuebo Zhu Tianjun. Named Zhu Guang, a celestial figure with disheveled hair and a naked body, obscured by black clouds around the waist and wearing red shoes. He holds the head of a drought demon in his left hand and a sword in his right hand while riding a jade dragon. His form changes to resemble a blue-faced, fang-toothed demon, wearing a scarlet robe and holding a sword, commanding a bear. In Taoism, he is worshiped as one of the stars of the \"Eleven Major Stars\" and is known as the \"Taiyi Yuebo Star Lord.\" He has the power to drive away evil, avoid disasters, protect peace, and maintain justice, as well as communicate with deities and govern mortals.",
      "fuluTitle189": "Yuebai Zhu Tianjun Talisman Secret",
      "fuluDes189": "Yuebo Zhu Tianjun. Named Zhu Guang, a celestial figure with disheveled hair and a naked body, obscured by black clouds around the waist and wearing red shoes. He holds the head of a drought demon in his left hand and a sword in his right hand while riding a jade dragon. His form changes to resemble a blue-faced, fang-toothed demon, wearing a scarlet robe and holding a sword, commanding a bear. In Taoism, he is worshiped as one of the stars of the \"Eleven Major Stars\" and is known as the \"Taiyi Yuebo Star Lord.\" He has the power to drive away evil, avoid disasters, protect peace, and maintain justice, as well as communicate with deities and govern mortals.",
      "fuluTitle190": "Yuebai Zhu Tianjun Talisman Secret",
      "fuluDes190": "Yuebo Zhu Tianjun. Named Zhu Guang, a celestial figure with disheveled hair and a naked body, obscured by black clouds around the waist and wearing red shoes. He holds the head of a drought demon in his left hand and a sword in his right hand while riding a jade dragon. His form changes to resemble a blue-faced, fang-toothed demon, wearing a scarlet robe and holding a sword, commanding a bear. In Taoism, he is worshiped as one of the stars of the \"Eleven Major Stars\" and is known as the \"Taiyi Yuebo Star Lord.\" He has the power to drive away evil, avoid disasters, protect peace, and maintain justice, as well as communicate with deities and govern mortals.",
      "fuluTitle191": "Deng Tianjun Talisman Secret",
      "fuluDes191": "Shang Qing Deng Tianjun Evil-Dispelling Talisman. Deng Tianjun, one of the gods of the Thunder Department, is the great deity of thunder and fire laws, the Emperor of Fire. He is tasked with punishing the disloyal, unfilial, malevolent spirits, and all evil that harms people. He was the leader of the Deng tribe during the Yan and Huang periods and fought alongside Emperor Huang against Chiyou, earning the title of General. Deng Tianjun is depicted wearing the Tian Ding Crown, a phoenix beak, with wings and holding a thunder drill. He is known for driving away harmful energies and preventing邪气.",
      "fuluTitle192": "Marshal Xin Zi Ri Manager Talisman",
      "fuluDes192": "Marshal Xin Zi Ri Official. Named Xin Xing, courtesy name Zhenyu, Marshal Xin is one of the 36 Marshals of the Thunder Department and the Taiji Five Thunder Judge of the Celestial Venerable. He is the Chief Commander of the 33 Heavens of Thunder, wearing a cow-ear headgear and a crimson cap, with an iron face and silver teeth resembling swords. He wears a green robe, iron boots, and wields a thunder register in his left hand and a thunder pen in his right hand, surrounded by flames. He oversees the laws of the heavens, the weather, and the recording of good and evil.",
      "fuluTitle193": "Marshal Deng Chou Ri Manager Talisman",
      "fuluDes193": "Marshal Deng Chou Ri Official, also called Deng Bowen, Deng Zhong, or Deng Hua, is the chief of the 36 Marshals of the Thunder Department, the Great Deity of Fire Laws, and in charge of the Chou day. He is depicted with red hair and a green face, three eyes, a sharp mouth, wings, and three claws. He holds a thunder drill in his left hand and a thunder hammer in his right hand. He is responsible for controlling lightning and punishing evil.",
      "fuluTitle194": "Marshal Zhao Yin Ri Manager Talisman",
      "fuluDes194": "Marshal Zhao Yin Ri Official, also known as Zhao Lang, courtesy name Gongming, is the Chief Commander of the Divine Mysterious Jade Palace and the Marshal of the Black Tiger Xuantan. He is depicted with an iron-colored face, round eyes, black beard, wearing an iron crown, yellow headband, golden armor, and green boots. He holds an iron whip in his right hand and an iron chain in his left, riding a black tiger. He is responsible for controlling thunder and lightning, calling forth rain and wind, dispelling plagues, managing wealth, and administering justice.",
      "fuluTitle195": "Marshal Zhang Mao Ri Manager Talisman",
      "fuluDes195": "Feijie Baoying Marshal Zhang, also called Jue (or Yu, Fan), courtesy name Yuanbo, is an important deity of the Thunder Department and is one of the \"Three Marshals of Thunder\" alongside Deng and Xin. He is depicted with a red face, golden armor, holding an iron axe, and riding lightning. He is responsible for controlling thunderclouds, rain, and fire, punishing the Five Evils, rewarding good, and punishing evil.",
      "fuluTitle196": "Marshal Zhang Mao Ri Manager Talisman",
      "fuluDes196": "Feijie Baoying Marshal Zhang, also called Jue (or Yu, Fan), courtesy name Yuanbo, is an important deity of the Thunder Department and is one of the \"Three Marshals of Thunder\" alongside Deng and Xin. He is depicted with a red face, golden armor, holding an iron axe, and riding lightning. He is responsible for controlling thunderclouds, rain, and fire, punishing the Five Evils, rewarding good, and punishing evil.",
      "fuluTitle197": "Marshal  Yang Chen Ri Manager Talisman",
      "fuluDes197": "Shuifu Zhan Guai Marshal Yang, also known as Yang Biao, is one of the 36 Marshals of the Thunder Department, one of the Ten Protectors of the Eastern Mountain, and the Deity of the Water Court, tasked with slaying demons. He is depicted in battle armor with a general’s helmet, strong and sharp features, holding a sword for slaying demons. He is responsible for purging evil, maintaining peace in the water realm, and patrolling the Three Realms.",
      "fuluTitle198": "Marshal Ma Si Ri Manager Talisman",
      "fuluDes198": "Chong Xiao Ling Guan Marshal Ma, also known as Ma Rongrong, courtesy name Lingyao, is the head of the 36 Marshals of the Thunder Department and the chief of the Four Protectors of Taoism. He has three heads, nine eyes, and is depicted with a fiery appearance. He wears a golden flower hat, a red robe, and wields a golden spear and brick. He is known for driving away evil, curing diseases, assisting with childbirth, and protecting wealth.",
      "fuluTitle199": "Wang Marshal Wu Ri Manager Talisman",
      "fuluDes199": "Marshal Wang Wu Ri Manager Talisman. Wang Lingguan,originally named Wang E, later changed to Wang Shan, is the chief of the Five Hundred Spiritual Officials and the foremost protector deity in Taoism. A disciple of Song Huizong's Master Sa Shoujian.His image is depicted with three eyes, holding a steel whip, wearing golden armor and a red robe. This talisman is used for protecting households and expelling evil, ensuring safety and peace.",
      "fuluTitle200": "Marshal Yin Wei Ri Manager Talisman",
      "fuluDes200": "Du Lei Tai Sui Marshal Yin. Marshal Yin, also known as Yin Jiao, is a disciple of the Golden Cauldron Taoist Master Shen Zhenren and one of the 36 Marshals of the Thunder Department. He is the Tai Sui of the Earth Deity, responsible for governing the good and bad luck of people throughout the year. As the son of King Zhou, abandoned at birth, he took his surname from his country. He is known for calming the land, dispelling evil, and protecting households.",
      "fuluTitle201": "Marshal Wen Shen Ri Manager Talisman",
      "fuluDes201": "Yiling Zhaowu Marshal Wen, originally named Wen Qiong, courtesy name Ziyu, with the childhood name Zhuolang, is one of the 36 Marshals of the Thunder Department and the chief of the Ten Protectors of the Eastern Mountain. He is depicted with a blue face, red hair, and red beard, golden eyes, sharp fangs, and a mouth like a fire basin. His appearance is fierce and powerful. He wears a purple scarf, black robe, and iron armor, with a golden belt at his waist, green boots on his feet, holding a jade ring in his left hand and an iron tablet or wolf tooth staff in his right hand. He is responsible for driving away evil, assisting justice, patrolling the Five Mountains, controlling life and death, curing diseases, and dispelling malevolent forces.",
      "fuluTitle202": "Marshal Kang You Ri Manager Talisman",
      "fuluDes202": "Di Ji Tai Bao Marshal Kang, originally named Kang Baoyi, Taoist name Miaowei, is one of the 36 Marshals of the Thunder Department and one of the Ten Protectors under the Eastern Mountain Emperor. He is depicted wearing either armor or a Taoist robe, with a golden helmet or crown, a kind and dignified expression, and a compassionate yet authoritative gaze. He holds a golden axe in his left hand and a melon hammer in his right hand. He is responsible for managing the land gods of the Four Directions, sending messages to ward off plagues, guarding the earth's peace, and helping those in need.",
      "fuluTitle203": "Marshal Guan Xu Ri Manager Talisman",
      "fuluDes203": "Jiutian Jiangmo Marshal Guan, also known as Guan Sheng, courtesy name Yun Chang, also referred to as \"The Great Deity Who Subdues Demons,\" \"The Great Emperor Who Conquers Demons,\" or \"Emperor Guan,\" is one of the 36 Marshals of the Thunder Department and one of the Four Protectors of Taoism. He is known as the Divine Martial God, the God of Wealth, and is depicted with a red face, long beard, phoenix eyes, and a dignified appearance. He wears a green robe with golden armor, holding a Green Dragon Crescent Blade, and riding a red rabbit horse. He is responsible for subduing evil spirits, promoting wealth, curing diseases, eliminating disasters, punishing rebels, and patrolling the underworld.",
      "fuluTitle204": "Marshal Zhu Hai Ri Manager Talisman",
      "fuluDes204": "Lei Men Huo Ku Marshal Zhu, originally named Zhu Yan, is one of the 36 Marshals of the Thunder Department and oversees the Thunder Gate Fire Vault, responsible for managing and commanding the power of thunder and fire. He is depicted with a black face, terrifying features, and multiple eyes. He wears golden armor and green boots, carrying a soul-chasing bag on his back, an iron chain in his left hand, and a golden mallet in his right hand, riding a black tiger. He is responsible for punishing evil sorcerers, commanding the spirits of the Three Realms, and investigating ghosts and gods.",
      "fuluTitle205": "Southern Flag Collection and Barbarian Thunder Command Talisman",
      "fuluDes205": "Lei Bu Jin Jun, Wu Fang Man Lei, Marshal Guo, is one of the Five Thunder Generals of the Southern Thunder Department, known as the Thunder Saint of Mixed Origin. He is responsible for attacking and healing, nurturing souls, restoring vitality, and slaying ghosts and demons. He is one of the five powerful Thunder Generals.",
      "fuluTitle206": "Eastern Flag Collection and Barbarian Thunder Command Talisman",
      "fuluDes206": "Lei Bu Jin Jun, Wu Fang Man Lei, Marshal Ma, originally named Ma Rongrong, Taoist name Lingyao, is one of the Five Thunder Generals of the Eastern Thunder Department and the chief protector deity of Taoism. He is responsible for driving away evil, curing diseases, assisting in childbirth, and protecting wealth. He is also known as the head of the Four Protectors of Taoism.",
      "fuluTitle207": "Northern Flag Collection and Barbarian Thunder Command Talisman",
      "fuluDes207": "Lei Bu Jin Jun, Wu Fang Man Lei, Marshal Deng, originally named Deng Bowen, is the chief of the 36 Marshals of the Thunder Department and one of the Five Thunder Generals. He oversees the Northern Thunder Department, managing lightning, punishing evil, and overseeing the Thunder Department's affairs.",
      "fuluTitle208": "Western Flag Collection and Barbarian Thunder Command Talisman",
      "fuluDes208": "Lei Bu Jin Jun, Wu Fang Man Lei, Marshal Fang, originally named Dao Zhang, also known as Fei Yang Lie Ling Tian Jun, is one of the Five Thunder Generals of the Western Thunder Department. He is responsible for eliminating evil, capturing spirits, driving away malevolent forces, curing diseases, and invoking rain and wind.",
      "fuluTitle209": "Central Flag Collection and Barbarian Thunder Command Talisman",
      "fuluDes209": "Lei Bu Jin Jun, Wu Fang Man Lei, Marshal Tian, also known as Tian Huan, is one of the Five Thunder Generals of the Central Thunder Department, the head of the Thunder Department's medicine and healing forces. He is known for subduing evil energies, extending life, and nurturing souls.",
      "fuluTitle210": "Hehe Marshal Tian Talisman",
      "fuluDes210": "San Tian. Refers to \"Tian Gou Liu,\" \"Tian Hong Yi,\" and \"Tian Zhi Biao,\" three deities who serve in the \"Chongtian Fenghuo Courtyard,\" tasked with controlling and combining good karma. These deities were summoned during the prosperous Kaiyuan period to serve as musicians in the palace under the direction of the Thunder General.",
      "fuluTitle211": "Zhang Tianjun Shadow Collection Talisman",
      "fuluDes211": "Fei Jie Bao Ying Marshal Zhang. Known as Zuo (or Yu, Fan), courtesy name Yuanbo, is an important deity in Taoism’s Thunder Department. He is one of the \"Three Thunder Generals\" alongside Marshal Deng and Marshal Xin. He has a red face, golden armor, and wields an iron axe, riding on thunder and lightning. He is responsible for controlling thunderclouds, lightning, and rain, exterminating evil forces, rewarding good and punishing evil, conveying orders, and healing diseases for the masses.",
      "fuluTitle212": "Zhang Tianjun Shadow Collection Talisman",
      "fuluDes212": "Fei Jie Bao Ying Marshal Zhang. Known as Zuo (or Yu, Fan), courtesy name Yuanbo, is an important deity in Taoism’s Thunder Department. He is one of the \"Three Thunder Generals\" alongside Marshal Deng and Marshal Xin. He has a red face, golden armor, and wields an iron axe, riding on thunder and lightning. He is responsible for controlling thunderclouds, lightning, and rain, exterminating evil forces, rewarding good and punishing evil, conveying orders, and healing diseases for the masses.",
      "fuluTitle213": "Marshal Zhang Primordial Shadow Cutting Talisman",
      "fuluDes213": "Fei Jie Bao Ying Marshal Zhang. Known as Zuo (or Yu, Fan), courtesy name Yuanbo, is an important deity in Taoism’s Thunder Department. He is one of the \"Three Thunder Generals\" alongside Marshal Deng and Marshal Xin. He has a red face, golden armor, and wields an iron axe, riding on thunder and lightning. He is responsible for controlling thunderclouds, lightning, and rain, exterminating evil forces, rewarding good and punishing evil, conveying orders, and healing diseases for the masses.",
      "fuluTitle214": "Yin Gong Shadow Cutting Self Talisman",
      "fuluDes214": "Du Lei Tai Sui Marshal Yin. Marshal Yin, known as Yin Jiao, is a disciple of the Taoist master Shen Zhenren and one of the 36 Marshals of the Thunder Department. He serves as the official in charge of the Earth’s Yearly Fate, overseeing people’s fortune and misfortune. Born as the son of King Zhou, he was abandoned after birth, which led to his name \"Jiao\" and surname \"Yin.\" He can calm the earth, expel evil spirits, protect homes, and ensure safety.",
      "fuluTitle215": "Northern Second Star Talisman",
      "fuluDes215": "Northern Second Star Talisman. The second star charm of the Northern Star system, used to invoke the protection of the second star of the Twenty-Eight Mansions in the Northern sky. This charm is mainly used for home protection, resolving difficulties, and improving the wearer’s fortune. It is particularly useful in times of obstacles or adversity, helping to overcome unfavorable factors and bringing assistance in career and wealth.",
      "fuluTitle216": "Left Inviting Thunder Tian Da Zi Talisman",
      "fuluDes216": "Left Inviting Thunder Tian Da Zi Talisman. A charm used to summon the Thunder God of the Great Son of Heaven, responsible for driving away all evil spirits and demons, suppressing negative energies, and protecting the wearer’s peace. The charm contains powerful thunder and lightning magic that can purify the surrounding environment and balance negative energy, safeguarding the wearer from external harmful influences.",
      "fuluTitle217": "Yòu Guan Five Thunder Command Talisman",
      "fuluDes217": "Yòu Guan Five Thunder Command Talisman. A charm used to harness the power of the Five Thunders, responsible for driving away evil spirits, suppressing malevolent forces, and ensuring the safety of one’s home. This charm integrates the essence of the Five Thunders, using thunder and lightning to subdue evil spirits and negative energy. It is particularly effective against ill fortune, disasters, and other harmful influences.",
      "fuluTitle218": "Messenger Five Thunder Flag Binding Talisman Secret",
      "fuluDes218": "Wu Lei Fa. Also known as the Five Thunder Method, this was originally a spell used for expelling evil, curing diseases, and praying for good weather. Later, it was said to have been refined into a spell to call down thunder to exterminate evil and to promote good fortune.",
      "fuluTitle219": "Heavenly Emperor Flag Binding Talisman Device",
      "fuluDes219": "Heavenly Emperor Flag Binding Talisman Device. Also known as the \"Western Tai Chi Heavenly Emperor,\" \"Gou Chen Shang Gong Heavenly Emperor,\" or \"Gou Chen Da Di,\" he is one of the \"Six Lords.\" As the ruler of all stars, he governs the spiritual beings and controls the universe's deities. His status is comparable to the Polar Emperor. He assists the Jade Emperor in overseeing the South and North Poles, as well as the heavens, earth, and humans, and is responsible for military affairs in the mortal world.",
      "fuluTitle220": "Heavenly Emperor Flag Binding Talisman Device",
      "fuluDes220": "Heavenly Emperor Flag Binding Talisman Device. Also known as the \"Western Tai Chi Heavenly Emperor,\" \"Gou Chen Shang Gong Heavenly Emperor,\" or \"Gou Chen Da Di,\" he is one of the \"Six Lords.\" As the ruler of all stars, he governs the spiritual beings and controls the universe's deities. His status is comparable to the Polar Emperor. He assists the Jade Emperor in overseeing the South and North Poles, as well as the heavens, earth, and humans, and is responsible for military affairs in the mortal world.",
      "fuluTitle221": "Heavenly Emperor Flag Binding Talisman Device",
      "fuluDes221": "Heavenly Emperor Flag Binding Talisman Device. Also known as the \"Western Tai Chi Heavenly Emperor,\" \"Gou Chen Shang Gong Heavenly Emperor,\" or \"Gou Chen Da Di,\" he is one of the \"Six Lords.\" As the ruler of all stars, he governs the spiritual beings and controls the universe's deities. His status is comparable to the Polar Emperor. He assists the Jade Emperor in overseeing the South and North Poles, as well as the heavens, earth, and humans, and is responsible for military affairs in the mortal world.",
      "fuluTitle222": "Zhang Messenger Flag Binding Grand Law Talisman",
      "fuluDes222": "Fei Jie Bao Ying Marshal Zhang. Known as Zuo (or Yu, Fan), courtesy name Yuanbo, is an important deity in Taoism’s Thunder Department. He is one of the \"Three Thunder Generals\" alongside Marshal Deng and Marshal Xin. He has a red face, golden armor, and wields an iron axe, riding on thunder and lightning. He is responsible for controlling thunderclouds, lightning, and rain, exterminating evil forces, rewarding good and punishing evil, conveying orders, and healing diseases for the masses.",
      "fuluTitle223": "Tai Sui Exorcism Talisman",
      "fuluDes223": "Tai Sui. Responsible for managing the annual luck and overseeing the year’s energy. Primarily tasked with resolving negative influences caused by the Year of the Tai Sui, protecting homes, preventing harmful energy from entering, and enhancing personal fortune. This charm is particularly powerful for those whose year conflicts with Tai Sui.",
      "fuluTitle224": "Xuantan Rallying Troops Talisman",
      "fuluDes224": "Marshal Xuan Tan. One of the Thunder Division Marshals, is tasked with repelling external evil forces, suppressing demons and monsters, and ensuring the safety of the nation and households. He is depicted wearing battle armor, wielding a large blade, exuding an imposing aura with a dignified expression.The Xuantan Returning Soldiers Talisman is primarily used to suppress external malicious energy and evil spirits, resolving the disturbances caused by hostile forces and safeguarding household peace. This talisman effectively eliminates bad luck and external negative influences, enhancing the wearer’s fortune and protective capabilities.",
      "fuluTitle225": "Reverse Secret Essential Call Marshal Zhu Talisman",
      "fuluDes225": "Lei Men Huo Ku Marshal Zhu. Also known as Zhu Yan, a Marshal of the Thunder Department, responsible for managing the Thunder Fire Treasury. With a dark face and a fierce appearance, he wears golden armor, green boots, and wields a gold mallet, riding a black tiger. He is tasked with punishing evil sorcerers, capturing spirits, and preventing evil. The charm is used to counteract evil forces and protect against malevolent spirits.",
      "fuluTitle226": "Divine Tiger Shape Talisman",
      "fuluDes226": "Shen Hu. A fierce and powerful deity, often appearing in the form of a tiger, specializing in eliminating bad luck and evil spirits. Known for his agility and strong deterrence power. The Shen Hu Charm is used to expel evil forces, especially in environments with strong negative energy. It protects homes and wards off external disasters, enhancing defensive power.",
      "fuluTitle227": "Hundred Solutions Essential Secret Talisman",
      "fuluDes227": "Hundred Solutions Essential Secret Talisman. A secretive Taoist charm used to resolve a variety of disasters and difficulties. It contains intricate and mysterious Taoist techniques capable of transforming negative energy and clearing away misfortune. The charm is mainly used to drive away various harmful influences, improve personal and household fortune, and bring new opportunities.",
      "fuluTitle228": "Tai Bao Scroll Talisman",
      "fuluDes228": "Tai Bao Scroll Talisman. Responsible for cleansing negative energy and impurities from the home, restoring the flow of positive energy. The Tai Bao wears official robes and holds a scroll, symbolizing authority and purity. This charm has strong purification abilities, clearing negative energies and ensuring harmony in the household.",
      "fuluTitle229": "Zhu Yan Attack and Treatment Grand Law Talisman",
      "fuluDes229": "Marshal Zhu, a deity in charge of dispelling misfortune and curing illnesses, is depicted wielding an iron whip and dressed in an official robe, exuding both power and dignity.The Zhu Yan Great Healing and Exorcism Talisman primarily governs disaster relief, healing, demon-slaying, and exorcism. Marshal Zhu is particularly skilled at using divine power to cure illnesses and neutralize negative energy.This talisman’s functions extend beyond expelling evil spirits; it is also effective in treating physical and mental issues caused by obstructed fortune or unfavorable Feng Shui. Wearing this talisman helps the bearer gain the deity's protection, eliminate disturbances, and restore health and harmony.",
      "fuluTitle230": "Home Safety Talisman",
      "fuluDes230": "Home Safety Talisman. A charm dedicated to protecting the home from external negative influences. Its ancient symbol holds sacred protective powers, ensuring the home is filled with positive energy, away from harmful spirits. This charm is used for peace, health, and harmony within the family, ensuring the safety of the household.",
      "fuluTitle231": "Minor Residence Evil Prevention Talisman",
      "fuluDes231": "Minor Residence Evil Prevention Talisman. Specializes in resolving small-scale evil energy and negative influences. Suitable for short-term protection and space purification. Its small size makes it easy to carry, particularly effective for preventing minor negative forces from invading. Ideal for homes, offices, and other spaces, ensuring harmony and peace.",
      "fuluTitle232": "Exorcising Evil Talisman",
      "fuluDes232": "Exorcising Evil Talisman. One of the most commonly used Taoist talismans, it drives away all evil spirits and negative energy, ensuring the safety of the wearer and their family. The talisman’s inscription focuses on \"Bi Xie,\" symbolizing the complete elimination of evil forces. It is used for personal protection, home defense, and exorcism, helping the wearer avoid disasters and misfortunes while attracting good luck.",
      "fuluTitle233": "Pu An Demon-Slaying Talisman",
      "fuluDes233": "Pu An Demon-Slaying Talisman, Created by the Chan Master Pu An, this talisman is used to exorcise evil spirits and monsters, suppress negative energy. It combines the powers of Buddhism and Taoism, effectively removing all harmful influences from the environment. Wearing this charm helps protect the wearer from evil spirits and ensures safety in homes and offices.",
      "fuluTitle234": "Puyan Heart Calming Talisman",
      "fuluDes234": "Puyan Heart Calming Talisman. Helps the wearer balance inner restlessness and anxiety, maintaining mental stability. This talisman has calming and peace-promoting effects, helping the wearer resolve worries and restlessness, keeping the mind calm and reducing external emotional disturbances. It is ideal for those under significant mental stress or often feeling anxious, helping to improve internal stability and emotional control.",
      "fuluTitle235": "Puyan Anti-Backstabber Talisman",
      "fuluDes235": "Puyan Anti-Backstabber Talisman. Designed to prevent harm from malicious individuals and resolve interpersonal conflicts. This talisman contains the power to suppress negative influences from others, ensuring a harmonious environment. Wearing it enhances positive energy around the wearer, protecting them from malicious forces and ensuring peaceful relationships.",
      "fuluTitle236": "Puyan Emergency Travel Talisman",
      "fuluDes236": "Puyan Emergency Travel Talisman. A talisman that provides swift action protection, enhancing the wearer’s speed and adaptability. Especially useful in situations requiring quick reactions and problem-solving, this charm boosts decision-making and action efficiency in emergencies, ensuring situations are handled promptly.",
      "fuluTitle237": "Puyan Emergency Travel Talisman",
      "fuluDes237": "Puyan Emergency Travel Talisman. A talisman that provides swift action protection, enhancing the wearer’s speed and adaptability. Especially useful in situations requiring quick reactions and problem-solving, this charm boosts decision-making and action efficiency in emergencies, ensuring situations are handled promptly.",
      "fuluTitle238": "Puyan Heaven-Suppressing Talisman",
      "fuluDes238": "Puyan Heaven-Suppressing Talisman.Used to suppress external evil forces and negative energy, ensuring a peaceful environment. The talisman contains powerful suppression energy to counteract destabilizing negative forces, maintaining safety and peace in the wearer’s surroundings. It helps prevent the intrusion of malicious influences.",
      "fuluTitle239": "Puyan Body Protection Spirit Talisman",
      "fuluDes239": "Puyan Body Protection Spirit Talisman. Offers powerful protective energy, shielding the wearer from harmful energies and evil spirits. This talisman uses the combined strength of Buddhism and Taoism to defend against negative energy, disasters, and bad events, ensuring the wearer’s safety and health. Ideal for those who frequently go outside or live in unstable environments.",
      "fuluTitle240": "Puyan Major Illness Life-Commanding Talisman",
      "fuluDes240": "Puyan Major Illness Life-Commanding Talisman. Specifically designed to address severe illness and health challenges. This talisman harnesses the healing energy of the Pu An method, balancing the body’s internal energy and promoting recovery. It helps the wearer recover from illness, resolving negative energy fields that cause health problems, and providing protection from sudden ailments. It is especially useful for those who are weak or frequently ill.",
      "fuluTitle241": "Puyan Evil Spirit Exorcism Talisman",
      "fuluDes241": "Puyan Evil Spirit Exorcism Talisman. Specializes in suppressing external negative energy and unstable forces, ensuring the peace and safety of homes and living environments. This talisman effectively eliminates all negative energies and evil forces, restoring positive energy and protecting the wearer from disasters and disturbances. It is a powerful protective charm, ideal for defending against external negative influences, harmful emotions, or evil spirits.",
      "fuluTitle242": "Puyan Major Illness Major Fight Talisman",
      "fuluDes242": "Puyan Major Illness Major Fight Talisman. Designed for the treatment and resolution of serious illnesses and difficult circumstances, combining the healing abilities and exorcism powers of the Pu An method. This talisman helps the wearer overcome severe illness and unfavorable fortunes, aiding in health recovery. Its strong healing and exorcism functions help restore balance to the body and mind, ensuring the recovery process is not interrupted.",
      "fuluTitle243": "Puyan Zhu Lei Talisman",
      "fuluDes243": "Puyan Zhu Lei Talisman. Merging the powers of lightning and the healing and exorcism abilities of the Pu An method, this talisman provides strong protective energy. It can resolve stubborn negative energies and evil forces, acting as a deterrent to enemies and driving away evil spirits. Wearing this talisman grants the wearer powerful energy support, ensuring effective protection in the face of danger and adversity.",
      "fuluTitle244": "Puyan Marshal Deng Talisman",
      "fuluDes244": "Puyan Marshal Deng Talisman. Specifically designed to protect against and suppress external evil forces, with powerful abilities to dispel negativity and evil energy. This talisman effectively stops harmful external influences, safeguarding the wearer from disturbances. Wearing this charm ensures safety and prevents the wearer from being affected by negative energies and evil spirits.",
      "fuluTitle245": "Puyan Emergency Travel Talisman",
      "fuluDes245": "Puyan Emergency Travel Talisman. A talisman that provides swift action protection, enhancing the wearer’s speed and adaptability. Especially useful in situations requiring quick reactions and problem-solving, this charm boosts decision-making and action efficiency in emergencies, ensuring situations are handled promptly.",
      "fuluTitle246": "Puyan Emergency Travel Talisman",
      "fuluDes246": "Puyan Emergency Travel Talisman. A talisman that provides swift action protection, enhancing the wearer’s speed and adaptability. Especially useful in situations requiring quick reactions and problem-solving, this charm boosts decision-making and action efficiency in emergencies, ensuring situations are handled promptly.",
      "fuluTitle247": "Puyan Emergency Travel Talisman",
      "fuluDes247": "Puyan Emergency Travel Talisman. A talisman that provides swift action protection, enhancing the wearer’s speed and adaptability. Especially useful in situations requiring quick reactions and problem-solving, this charm boosts decision-making and action efficiency in emergencies, ensuring situations are handled promptly.",
      "fuluTitle248": "Puyan Emergency Travel Talisman",
      "fuluDes248": "Puyan Emergency Travel Talisman. A talisman that provides swift action protection, enhancing the wearer’s speed and adaptability. Especially useful in situations requiring quick reactions and problem-solving, this charm boosts decision-making and action efficiency in emergencies, ensuring situations are handled promptly.",
      "fuluTitle249": "Puyan Celestial Master Jade Thunder Sect Talisman",
      "fuluDes249": "Puyan Celestial Master Jade Thunder Sect Talisman,Combines the Pu An method and Taoist thunder magic, possessing powerful deterrence capabilities. The jade thunder magic contained within this talisman can drive away various evil spirits and negative energies, protecting the wearer from disasters. Wearing this talisman enhances the wearer's spiritual power and energy field, providing stronger protection and exorcism effects.",
      "fuluTitle250": "Puyan Heart Calming Talisman",
      "fuluDes250": "Puyan Heart Calming Talisman. Helps the wearer balance inner restlessness and anxiety, maintaining mental stability. This talisman has calming and peace-promoting effects, helping the wearer resolve worries and restlessness, keeping the mind calm and reducing external emotional disturbances. It is ideal for those under significant mental stress or often feeling anxious, helping to improve internal stability and emotional control."
    }
  },
  zh: {
    translation: {
      "fuluTitle1": "上清招财符",
      "fuluDes1": "上清赵公明招财符。赵公明元帅，五路财神首领，武财神。汉代第一代张天师张道陵入鹤鸣山修道时收其为徒。该形象右手执二十四节铁鞭，左手执铁索。主管天下财源禄库，依人之善行，赐人财富。",
      "fuluTitle2": "上清破魔符",
      "fuluDes2": "上清天蓬元帅破魔符。天蓬元帅，道教护法神北极四圣之一，酆都北帝之护法，北斗破军星化身，又为金眉老君后身。自东晋以来，秘传于道教上清派中。其形象“身长千尺口齿方，四头八臂显神光。手持金尺摇帝钟，铜牙铁瓜灭凶狂”。此符主用于驱邪和护持，可护身祈福，驱除疾病。",
      "fuluTitle3": "上清辟邪符",
      "fuluDes3": "上清雷祖辟邪符。雷祖，九天应元雷声普化天尊，是浮黎元始天尊第九子玉清真王的化身。主雷雨之神。雷祖为其封号。形象威武庄重，常被描绘为三目披发、手持金鞭或铁锤，坐骑为黑麒麟。主要用于辟邪驱鬼，保护家庭和个人免遭邪灵侵扰。",
      "fuluTitle4": "上清镇宅符",
      "fuluDes4": "上清王灵官镇宅符。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle5": "上清招财符",
      "fuluDes5": "上清赵公明黑虎招财符。赵公明元帅，五路财神首领。张天师命其守护玄坛，因此道教尊为正一玄坛元帅。该形象是其坐骑黑虎，又称黑虎玄坛。招财镇宝财运亨通，主要作用于升职加薪，广进财源。",
      "fuluTitle6": "上清寿星符",
      "fuluDes6": "上清南极长生大帝寿星符。南极长生大帝，玉清真王，道教四御之一，协助玉皇执掌天道。元始天王九子。符中暗藏“福禄寿”三星。祈福健康长寿，吉祥如意。",
      "fuluTitle7": "上清和合符",
      "fuluDes7": "上清和合二仙符。汉族民间传说之神，又名和合二圣。二圣亲如兄弟，虽共爱一女，却双双放弃成全对方，后两人俱为僧，共立庙“寒山寺”。清代雍正时，复以唐代诗僧“寒山，拾得”。和合符主人事婚姻，加强夫妻之间的感情消除矛盾，使双方关系更加和谐稳定。",
      "fuluTitle8": "上清平安符",
      "fuluDes8": "上清钟馗平安符。钟馗，道教“镇宅真君”或“驱魔真君”，道教三伏魔帝君之一。传说是唐朝终南山人士，因貌丑落第后撞死殿前。形象豹头环眼、铁面虬鬓，常被描绘成身穿蓝袍、足蹬朝靴、手持宝剑的模样。主要作用家宅平安，抵御邪恶，带来福祉。",
      "fuluTitle9": "上清镇煞除邪一善符",
      "fuluDes9": "上清镇煞除邪一善符。是用于镇压邪气与煞气的符箓，融合了上清派的道法力量，能够有效化解周围环境中的不良气场。符中的一善力量能够将不良气场转化为正能量，恢复平衡。此符适合用于清除家庭或工作场所中的负面能量，帮助佩戴者恢复内外的和谐，增强个人的运势和健康",
      "fuluTitle10": "上清送子观音符",
      "fuluDes10": "上清慈航真人送子观音符。慈航真人，亦称观音大士、慈航道人，是道教与佛教共同尊奉的大慈大悲救世神祇。据传慈航真人原为妙庄王三公主妙善，因立志修行而历经磨难，最终修成正果。形象为一位端庄优雅的女性，头戴宝冠，面容慈悲宁静。主要作用帮助求子家庭实现心愿。",
      "fuluTitle11": "上清文昌符",
      "fuluDes11": "上清魁星文昌符。魁斗星君，北斗七星第一星，二十八星宿之一。曾连中三元，后蒙冤跳海，玉皇深悯其人，赐朱笔一支，命其掌管人间科举文运。其神袛兴起于东汉科举时代。其形象赤发蓝面，左手捧着大斗，右手握着如椽大笔。主宰文运官运，保佑自己考运亨通。",
      "fuluTitle12": "上清辟邪符",
      "fuluDes12": "上清雷祖辟邪符。雷祖，九天应元雷声普化天尊，是浮黎元始天尊第九子玉清真王的化身。主雷雨之神。雷祖为其封号。形象威武庄重，常被描绘为三目披发、手持金鞭或铁锤。主要用于辟邪驱鬼，保护家庭和个人免遭邪灵侵扰。",
      "fuluTitle13": "上清百无禁忌符",
      "fuluDes13": "上清姜太公百无禁忌符。姜太公，即吕尚，字子牙，号飞熊，又称太公望等. 是玉虚宫元始天尊门下的弟子，奉师命下山封神，代元始天尊行使封神大权，敕封三百六十五路正神。除邪扶正，驱魔役鬼，化解不吉之事，起到镇宅护院、阻挡邪祟入侵的作用。",
      "fuluTitle14": "上清百无禁忌符",
      "fuluDes14": "上清姜太公百无禁忌符。姜太公，即吕尚，字子牙，号飞熊，又称太公望等. 是玉虚宫元始天尊门下的弟子，奉师命下山封神，代元始天尊行使封神大权，敕封三百六十五路正神。除邪扶正，驱魔役鬼，化解不吉之事，起到镇宅护院、阻挡邪祟入侵的作用。",
      "fuluTitle15": "上清辟邪符",
      "fuluDes15": "上清邓天君辟邪符。邓天君邓伯温雷部诸神之一，雷霆欻火律令大神炎帝，受令断天下不忠不孝之人、鬼祟之魅、一切害民之类。为炎黄时期邓氏部落的首领，跟随黄帝战蚩尤而封将军。邓天君头戴天丁冠、凤喙、背生肉翅、手持雷钻。具有明显的驱除不良气息的效果，免受邪气侵扰。",
      "fuluTitle16": "上清平安符",
      "fuluDes16": "上清殷元帅平安符。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle17": "清微静心符",
      "fuluDes17": "曹国舅。黄华洞天帝主超凡入圣天尊。曹国舅名佾，字公伯，又传名景休，大将曹彬之孙，仁宗曹皇后之弟，吕洞宾弟子。常头戴纱帽，身着红袍官服，手持檀板。阴阳板象征着调和阴阳、平衡人心，有心态平和、精神安宁的作用。",
      "fuluTitle18": "清微健康符",
      "fuluDes18": "韩湘子。瑶华洞天帝主圆通早觉天尊。本名韩湘，字清夫，是韩愈的侄儿，得钟、吕二仙传授修行之术，隐居于终南山修道，得成正果。形象为一手执长笛的英俊少年。他的笛声能让天地花开万物生，代表生命的活力，象征着生生不息、和谐平安。",
      "fuluTitle19": "清微转运符",
      "fuluDes19": "铁拐李。西华洞天帝主超登莲化天尊。姓李名玄，为八仙之首，唐玄宗开元时人，学道于终南山，得太上老君传授。形象跛脚、背一药葫芦，周游江湖，治病救人。手持的葫芦被称为“福禄葫芦”，盛有长生不老的丹药，象征逢凶化吉和延年益寿。",
      "fuluTitle20": "清微转运符",
      "fuluDes20": "蓝采和。万华洞天帝主流光耀采天尊。原名许坚，是唐末至五代时人。其形象常衣着褴褛，六娉黑，木腰带，阔三寸余。一脚着靴，一脚赤足，手持大拍板。手提花篮篮内盛满了仙品，象征生活的富足、幸福，寓意繁荣昌盛。",
      "fuluTitle21": "清微长生符",
      "fuluDes21": "张果老。中条洞天帝主通玄应变天尊。名张果。修炼道士，据传有长生不老，返老还童之术。形象是常倒骑白驴，云游四方，劝化世人。鱼鼓是他游历江湖时常敲打的法器，传说它能知过去未来。象征长寿，更有顺天应命、知足常乐的内涵。",
      "fuluTitle22": "清微辟邪符",
      "fuluDes22": "朱帅。火犀雷府打邪灭巫朱元帅，出入上清火犀宫，主治火府雷部。主管风雷雨部，下辖酆都岳渎，统领天将天兵，收摄三界鬼神。商时，朱元帅曾入太华山修道。黑面怒相，环眼，朱发举头，皂袍金甲，左手提铁索，右手执金槌。驱逐不正鬼神，扫荡天下妖恶，屏除不祥。",
      "fuluTitle23": "清微招财符",
      "fuluDes23": "赵公明元帅，五路财神首领，武财神。汉代第一代张天师张道陵入鹤鸣山修道时收其为徒。该形象右手执二十四节铁鞭，左手执铁索。主管天下财源禄库，依人之善行，赐人财富。",
      "fuluTitle24": "清微祛病符",
      "fuluDes24": "钟离权。正阳继派终南洞天帝主飞行救劫天尊。号“和谷子”、“正阳子”，为东汉、魏晋时期人物，道教北五祖之一，全真道祖师，“正阳祖师”。钟离权的形象是坦胸露腹，手摇棕扇，大眼睛，红脸面，头上扎着两个小譬，神态怡然。传说钟离权的扇子拥有复生的力量，有去除病痛、增福延寿的作用。",
      "fuluTitle25": "清微辟邪符",
      "fuluDes25": "姜太公，即吕尚，字子牙，号飞熊，又称太公望等. 是玉虚宫元始天尊门下的弟子，奉师命下山封神，代元始天尊行使封神大权，敕封三百六十五路正神。除邪扶正，驱魔役鬼，化解不吉之事，起到镇宅护院、阻挡邪祟入侵的作用。",
      "fuluTitle26": "清微护产符",
      "fuluDes26": "九天锡麟金阙监生金盆送子高元帅。雷霆诸帅之中专管生育的元帅，位居九天监生司，司天下生育之事，催产保生，送子。受气于始元太乙之精，托胎于苍州高春公家。他左手持一金盆，右手执金鞭，胯下骑麒麟。保祐妇女儿童，能催生保产、救护生灵，能解孤辰寡宿、祛邪。",
      "fuluTitle27": "清微辟邪符",
      "fuluDes27": "吕纯阳。纯阳演正警化孚佑帝君兴行妙道天尊。名岩，字洞宾，号纯阳子，为道教丹鼎派祖师，被尊为“吕祖”、“纯阳祖师”。吕洞宾师从钟离权，后曾传道予刘海蟾及王重阳，全真道“北五祖”之一。其形象常常背一长剑。宝剑以镇邪除魔的威力著称，曾以剑斩妖除魔，保护百姓，寓意镇邪驱魔祛邪避凶。",
      "fuluTitle28": "清微安神符",
      "fuluDes28": "何仙姑。清霞洞天仙姑宏慈妙法元君。原名何琼，是八仙中唯一的女性，唐朝人，十三岁时遇吕洞宾，赐给一桃。形象是常手举一莲，象征着冰清玉洁、不染世尘、纯净脱俗。",
      "fuluTitle29": "清微辟邪符",
      "fuluDes29": "朱元帅。火犀雷府打邪灭巫朱元帅，出入上清火犀宫，主治火府雷部。主管风雷雨部，下辖酆都岳渎，统领天将天兵，收摄三界鬼神。商时，朱元帅曾入太华山修道。黑面怒相，环眼，朱发举头，皂袍金甲，左手提铁索，右手执金槌。驱逐不正鬼神，扫荡天下妖恶，屏除不祥。",
      "fuluTitle30": "清微百解符",
      "fuluDes30": "主要用于化解灾煞、不顺心的事情以及驱邪避凶。它适用于各种场合，如化解流年不利、招纳吉祥、处理一些不太严重的撞邪问题等‌。",
      "fuluTitle31": "清微辟邪招财符",
      "fuluDes31": "关帝。讳羽，字云长，三界伏魔大帝关圣帝君，护法四帅之一，武财神。汉封侯，宋封王，明封大帝，历朝加尊号，一生忠义仁勇，诚信名冠天下。主掌收邪、伏魔、催财、升官。",
      "fuluTitle32": "清微平安镇宅符",
      "fuluDes32": "虎头殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle33": "清微事业学业符",
      "fuluDes33": "魁星。魁斗星君，北斗七星第一星，二十八星宿之一。曾连中三元，后蒙冤跳海，玉皇深悯其人，赐朱笔一支，命其掌管人间科举文运。其神袛兴起于东汉科举时代。其形象赤发蓝面，左手捧着大斗，右手握着如椽大笔。主宰文运官运，保佑自己考运亨通。",
      "fuluTitle34": "清微招财符",
      "fuluDes34": "刘海。本名刘操，号海蟾子，称为刘海蟾。曾为辽朝进士，后为丞相辅佐燕主刘宗光。刘海用计收付了修行多年的金蟾，得道成仙。刘海戏金蟾，金蟾吐金钱。代表着财运。",
      "fuluTitle35": "清微平安镇宅符",
      "fuluDes35": "马元帅。运元鼎化三十三天金臂圆光火犀大仙，部领六天吏兵，降伏天下妖魔。系道教护法四圣之一。正一灵官马元帅，讳胜，又称华光大帝。元帅本无姓氏，南方属午，午属马，故借马字为姓。可以 禳解灾厄，保平安，镇宅护身。",
      "fuluTitle36": "清微平安镇宅符",
      "fuluDes36": "清微平安镇宅符。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle37": "清微平安镇宅符",
      "fuluDes37": "清微平安镇宅符。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle38": "清微平安镇宅符",
      "fuluDes38": "清微平安镇宅符。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle39": "清微辟邪祛病符",
      "fuluDes39": "温元帅。翊灵昭武温元帅温琼，三十六天将之一，护法四帅之一，东岳十太保中的第一太保。唐朝温州人，随郭子仪平乱。青面赤发之神，头顶盔，身披甲，左手执玉环，右手握铁锏。可以镇邪祛恶，祛瘟剿邪，免除灾祸。",
      "fuluTitle40": "清微辟邪祛病符",
      "fuluDes40": "温元帅。翊灵昭武温元帅温琼，三十六天将之一，护法四帅之一，东岳十太保中的第一太保。唐朝温州人，随郭子仪平乱。青面赤发之神，头顶盔，身披甲，左手执玉环，右手握铁锏。可以镇邪祛恶，祛瘟剿邪，免除灾祸。",
      "fuluTitle41": "清微祛瘟疫符",
      "fuluDes41": "康元帅。地衹太保，东岳大帝属下十太保之一， 仁圣元帅，雷部“三十六元帅”之一，掌管四方土地神。康元帅讳保裔，北宋朝将领，慈惠悯生，从不伤害幼小者，照顾孤寡，百姓称其为“四方谓之能仁”。浓眉虬髯，威严勇猛， 一手执金斧，一手执瓜锤。主掌传讯押瘟。",
      "fuluTitle42": "清微辟邪平安符",
      "fuluDes42": "殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle43": "清微辟邪平安符",
      "fuluDes43": "殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle44": "清微招财符",
      "fuluDes44": "岳元帅。伏魔荡寇天尊，护法四帅之一，雷部三十六帅之一。讳飞，字鹏举，宋相州人，抗金名将，南宋中兴四将之首。形象手持长枪、斧钺。掌握九天雄兵神将之权，救民正直，护国无私，主掌收鬼、财库。",
      "fuluTitle45": "清微招财符",
      "fuluDes45": "岳元帅。伏魔荡寇天尊，护法四帅之一，雷部三十六帅之一。讳飞，字鹏举，宋相州人，抗金名将，南宋中兴四将之首。形象手持长枪、斧钺。掌握九天雄兵神将之权，救民正直，护国无私，主掌收鬼、财库。",
      "fuluTitle46": "清微转运符",
      "fuluDes46": "清微岳云符，是专门为岳元帅之子岳云而设的符箓。岳云是岳元帅的继承者，继承了父亲的神力，主要职责为守护家宅与安抚鬼怪。此符具有镇煞除邪的功能，能够清除周围的煞气与负能量，保卫佩戴者的安全。佩戴此符，能够提升佩戴者的运势，避免不吉之事发生，确保安宁与顺遂。",
      "fuluTitle47": "清微招财符",
      "fuluDes47": "上清赵公明黑虎招财符。赵公明元帅，五路财神首领。张天师命其守护玄坛，因此道教尊为正一玄坛元帅。该形象是其坐骑黑虎，又称黑虎玄坛。招财镇宝财运亨通，主要作用于升职加薪，广进财源。",
      "fuluTitle48": "清微镇宅平安符",
      "fuluDes48": "钟馗，道教“镇宅真君”或“驱魔真君”，道教三伏魔帝君之一。传说是唐朝终南山人士，因貌丑落第后撞死殿前。形象豹头环眼、铁面虬鬓，常被描绘成身穿蓝袍、足蹬朝靴、手持宝剑的模样。主要作用家宅平安，抵御邪恶，带来福祉。",
      "fuluTitle49": "清微太岁退煞符秘",
      "fuluDes49": "殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle50": "清微平安镇宅符",
      "fuluDes50": "清微平安镇宅符。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle51": "清微王帅符秘",
      "fuluDes51": "王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle52": "清微马公白蛇符秘",
      "fuluDes52": "清微马公白蛇符秘，是清微门派的独特符箓，专为保护家宅及镇压妖邪而设。马公，即马元帅，他的座下白蛇为镇煞神器，能够吞噬鬼怪妖邪，守护居住地的安宁。此符融合了马公的神力和白蛇的镇压之力，能够有效避免不祥之事，保持周围环境的清明与平和。",
      "fuluTitle53": "清微辟邪祛温符",
      "fuluDes53": "温元帅。翊灵昭武温元帅温琼，三十六天将之一，护法四帅之一，东岳十太保中的第一太保。唐朝温州人，随郭子仪平乱。青面赤发之神，头顶盔，身披甲，左手执玉环，右手握铁锏。可以镇邪祛恶，祛瘟剿邪，免除灾祸。",
      "fuluTitle54": "清微开隔符秘",
      "fuluDes54": "清微开隔符秘，专门用于调解人与人之间的隔阂与沟通障碍。符主赵元帅，他能够帮助打开人与人之间的隔阂，促进理解与和解。此符适用于解决职场、人际关系中的紧张或隔阂，帮助缓解冲突、增进沟通。",
      "fuluTitle55": "清微神虎符",
      "fuluDes55": "微神虎符，是清微门派的符箓之一，主要用于召唤神虎的力量。神虎象征着勇猛与镇压，能够有效驱除外来邪气与妖魔。此符适用于居家、办公等场所的镇煞与驱邪。佩戴此符，能够增加佩戴者的威慑力，增强个人运势，防止外界的恶意侵扰。其作用也能在精神层面上给佩戴者带来鼓舞与力量。",
      "fuluTitle56": "清微斩妖符",
      "fuluDes56": "水府斩怪杨元帅，水府斩怪杨元帅名叫杨彪。雷部三十六元帅之一，东岳十太保之一，地祗元帅，水府斩怪之职。身着战甲，头戴帅盔，身姿矫健，面容刚毅，眼神犀利且威严，手持斩妖剑。主掌：斩怪除妖，维护水府安宁，巡查三界。",
      "fuluTitle57": "清微使者符",
      "fuluDes57": "张珏，九天飞捷符使，旸谷神君，身着金甲朱衣、红履，手持斧头，青色面容，神情威严。作为清微门派的使者，张珏专责捉拿恶鬼、镇压妖邪，清除不正之气。符内斧头象征砍断邪恶力量，迅速清理煞气，保护佩戴者免受邪灵侵扰。",
      "fuluTitle58": "天师辟邪符",
      "fuluDes58": "酆都杀鬼吕元帅。九州社令陽雷都提舉激剝檢校使者，為雷聲普化天尊座下大將。名魁，字貴祥，生於南宋，相傳為北宋宰相呂公著之後人，拜雷祖光耀大帝為師，專制心惡之徒及不孝之子。形象赤发金眼，绿面红臂，具有较强的驱邪和惩治邪恶之力。",
      "fuluTitle59": "天师平安辟邪符",
      "fuluDes59": "玄天上帝。真武帝君，真武荡魔大帝，北方之神。相传是太上老君的第八十二次化身，因受太阳精气降圣为净乐国太子，入太和山修道四十二载，被玉帝敕封为北方真武大帝。形象被发，黑衣，仗剑，蹈龟蛇， 可以镇宅辟邪、祛邪卫正、司命延寿、保育广嗣。",
      "fuluTitle60": "天师平安镇宅符",
      "fuluDes60": "天师平安镇宅符。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle61": "天师平安镇宅符",
      "fuluDes61": "天师平安镇宅符。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle62": "天师驱邪符",
      "fuluDes62": "打邪灭巫朱元帅。出入上清火犀宫，主治火府雷部。主管风雷雨部，下辖酆都岳渎，统领天将天兵，收摄三界鬼神。商时，朱元帅曾入太华山修道。黑面怒相，环眼，朱发举头，皂袍金甲，左手提铁索，右手执金槌。驱逐不正鬼神，扫荡天下妖恶，屏除不祥。",
      "fuluTitle63": "天师祛温符",
      "fuluDes63": "地袛太保康元帅。东岳大帝属下十太保之一， 仁圣元帅，雷部“三十六元帅”之一，掌管四方土地神。康元帅讳保裔，北宋朝将领，慈惠悯生，从不伤害幼小者，照顾孤寡，百姓称其为“四方谓之能仁”。浓眉虬髯，威严勇猛， 一手执金斧，一手执瓜锤。主掌传讯押瘟。",
      "fuluTitle64": "天师辟邪招财符",
      "fuluDes64": "精忠义勇岳元帅。伏魔荡寇天尊，护法四帅之一，雷部三十六帅之一。讳飞，字鹏举，宋相州人，抗金名将，南宋中兴四将之首。形象手持长枪、斧钺。掌握九天雄兵神将之权，救民正直，护国无私，主掌收鬼、财库。",
      "fuluTitle65": "天师辟邪符",
      "fuluDes65": "雷霆火部苟元帅。阳雷神君，九天应运宣灵演化真君。原名苟章，后被天帝封为雷神。形象头戴宝冠，面赤如血，三目怒睁，左手握凿，右手执锤。可以收火邪、五雷荡凶，荡妖革魔。",
      "fuluTitle66": "天师镇宅平安符",
      "fuluDes66": "六甲六丁金元帅。行风雷、治鬼神，统管六丁六甲十二大将军。名为金游，可以镇宅安家、消灾降福、驱除不详。",
      "fuluTitle67": "天师招财符",
      "fuluDes67": "金轮如意赵元帅。赵公明元帅，五路财神首领。张天师命其守护玄坛，因此道教尊为正一玄坛元帅。该形象是其坐骑黑虎，又称黑虎玄坛。招财镇宝财运亨通，主要作用于升职加薪，广进财源。",
      "fuluTitle68": "天师除邪护身符",
      "fuluDes68": "五雷判官辛元帅。雷霆三十三天大都督青帝天君，玉帝敕封为“雷部元帅“。姓辛名兴，字震宇。吞食火丹后，竟化为雷公，脚踏五雷鼓，升天而去。奉命行天，翦灭邪魔恶鬼，主掌除邪、护身。",
      "fuluTitle69": "天师祛病护身符 ",
      "fuluDes69": "五雷天医张元帅。张元伯，形象是肉角，红发，青面，双目，鹰喙，青身，双肉翅，龙爪手足，红裙飞仙带。遣召雷神，执敕召雷神皂旗，腰悬巨斧，摇撼旗帜。主掌护身、祛病，尤其是阴性疾病如精神分裂症、鬼怪病和附体类。",
      "fuluTitle70": "天师辟邪符",
      "fuluDes70": "神雷之首石元帅。神雷之首石元帅，石元帅讳圆或远信 ，又称神雷主首石元帅、诛戮执法石元帅。雷部三十六帅之一，五雷之长 ，居神雷首府 ，为雷部中主掌杀伐的重要元帅。黑面红袍，头戴关帅巾，身系长巾带。主掌：代天行化，杀伐不正，维护秩序。",
      "fuluTitle71": "天师辟邪招财符",
      "fuluDes71": "九天降魔关元帅。讳羽，字云长，三界伏魔大帝关圣帝君，护法四帅之一，武财神。汉封侯，宋封王，明封大帝，历朝加尊号，一生忠义仁勇，诚信名冠天下。主掌收邪、伏魔、催财、升官。",
      "fuluTitle72": "天师海运平安符",
      "fuluDes72": "沧海涌潮伍元帅，伍元帅名伍员，字子胥。雷部三十六元帅之一，沧海涌潮之职。手持钢鞭、头戴幞头帽或天丁冠，具有三目，身随十二潮神或十八潮神。主掌：镇妖除魔，管理水府事务，调节潮汛，护佑航海安全。",
      "fuluTitle73": "天师镇宅护身符",
      "fuluDes73": "斗口魁神马元帅。运元鼎化三十三天金臂圆光火犀大仙，部领六天吏兵，降伏天下妖魔。系道教护法四圣之一。正一灵官马元帅，讳胜，又称华光大帝。元帅本无姓氏，南方属午，午属马，故借马字为姓。可以 禳解灾厄，保平安，镇宅护身。",
      "fuluTitle74": "天师祛病符",
      "fuluDes74": "天蓬提督伊元帅，天蓬元帅全称祖师九天尚父五方都总管北极左垣上将都统大元帅天蓬真君 ，姓卞名庄 ，是北斗破军星的化身，金眉老君后身。北极紫微大帝座下护佑天际的道教护法神。三头六臂的大忿怒像 ，身长五十丈 ，赤发、绯衣或黑衣 ，玄冠、金甲或赤甲 ，跣足，执钺斧、弓箭、剑、铎、戟、索六物。主要用于驱除邪恶、避免疾病、灾祸的侵扰。",
      "fuluTitle75": "天师祛病符",
      "fuluDes75": "南极乌旸吴元帅，吴元帅名叫吴明达 ，又称南宫火府祖师乌旸雷师吴真君。雷部三十六元帅之一，南极星君部下。欻火相青身赤发肉角，黄抹额左手皂旗旗上有离卦右手仗火剑，驾火龙偏身出火赫奕可畏。主掌：祛病辟邪，祈福封禁，行驱禳灾。",
      "fuluTitle76": "天师镇邪符",
      "fuluDes76": "酆都拷鬼孟元帅，孟元帅名锷，本名孟山。雷部三十六元帅之一，酆都巨天力士追摄行刑元帅，东岳大帝十太保之一。赤枣面，团眼，四方口，须胡，紫方巾，掩心铁甲，内青衫，外皂袍，穿靴，短裙，执八角大铁槌。主掌：拷鬼行刑，追摄魂魄，管理地府狱事，镇伏邪祟。",
      "fuluTitle77": "天师祛病驱魔符",
      "fuluDes77": "雷府驱魔庞元帅，庞元帅原名庞乔，字长清。雷部三十六帅之一 ，被敕封为混气元帅。眼神锐利，眉宇之间透露着神圣的威严，身着雷霆战甲，手持雷锤。主掌：驱除阴魔，消除旧恶，转运旺运，祛病无灾。",
      "fuluTitle78": "天师辟邪符",
      "fuluDes78": "流金火铃宋元帅，流金火铃宋元帅名叫宋无忌 ，字迪 ，又称九凤玉华司宋元帅、南方九凤破秽宋迪元帅、火铃督雷宋元帅。雷部三十六帅之一 ，是道教中负责驱邪、保护信徒和辟邪驱鬼等任务的护法神。青面，三目，三头，六臂，金甲。执火枪、火索、火剑、火铃 ，乘火云。主掌：驱邪除秽，督雷摄魔，考邪炼神，护佑信徒。",
      "fuluTitle79": "天师送子催生符",
      "fuluDes79": "三五梅仙张元帅，三五梅仙张元帅名叫张四郎，又称上清三吾梅仙大帝、九天辅元开化灵应张大帝、七曲毓圣天尊。雷部三十六帅之一 ，三五天仙中的一员，与天界的神祇一起参与天宫事务，维护天地之间的平衡与和谐。身着华丽道袍，头戴道冠，面色和蔼可亲。主掌：送子催生，驱魔抓妖。",
      "fuluTitle80": "天师辟邪符",
      "fuluDes80": "正一箓官陈元帅，陈元帅名叫陈元远。雷部三十六帅之一，正一箓官，负责管理正一派的禄位之事。陈元帅三眼金睛，眉眼及鬓玉色，朱发，面粉团色，无须，头戴蹙金罗帽，金甲锦袍，手执金枪，背火瓢，踏火车，下有白蛇，足着紫靴，腰带弓箭，项上有火色炁，金光束带。主掌：禄位管理，考召鬼神，驱邪镇魔，辅助行法。",
      "fuluTitle81": "天师辟邪祛病符",
      "fuluDes81": "神威驱邪周元帅。讳巨夫（或称广泽），是道教雷部三十六元帅之一。他通常被称为“北方风轮荡鬼周元帅”，赤发獠牙，面如蓝靛，朱砂发。银牙巨目，手持金鞭或大月，脚踏风火轮。\n主要作用荡秽、驱邪、治病、灭魔。",
      "fuluTitle82": "天师水行护身符",
      "fuluDes82": "水府斩怪杨元帅。名叫杨彪。雷部三十六元帅之一，东岳十太保之一，地祗元帅，水府斩怪之职。身着战甲，头戴帅盔，身姿矫健，面容刚毅，眼神犀利且威严，手持斩妖剑。主掌：斩怪除妖，维护水府安宁，巡查三界。 可以水行护身。",
      "fuluTitle83": "天师河海平安符",
      "fuluDes83": "斗府枢灵刘元帅，刘元帅名吉，字神光 ，一说名甫 ，一说名后 ，又称为斗府枢灵、玄化、慈济真君等。雷部三十六元帅之一，雷部二十四天君之一，上殿卷帘大将军九天云路护驾使。洞玄主帅苍牙铁面九阳上将天君，黑面，少颜，怒容，绊袍，红发，执金鞭，擎火轮，披金甲，足蹑水车 。主掌：掌管风雷雨电雪雹，接引护送亡者超升，保农工收成河海平安，赍送表文。",
      "fuluTitle84": "天师镇邪祛恶符",
      "fuluDes84": "翊灵昭武温元帅。温琼，三十六天将之一，护法四帅之一，东岳十太保中的第一太保。唐朝温州人，随郭子仪平乱。青面赤发之神，头顶盔，身披甲，左手执玉环，右手握铁锏。可以镇邪祛恶，祛瘟剿邪，免除灾祸。",
      "fuluTitle85": "天师保产符",
      "fuluDes85": "金阙监生高元帅。九天锡麟金阙监生金盆送子高元帅。雷霆诸帅之中专管生育的元帅，位居九天监生司，司天下生育之事，催产保生，送子。受气于始元太乙之精，托胎于苍州高春公家。他左手持一金盆，右手执金鞭，胯下骑麒麟。保祐妇女儿童，能催生保产、救护生灵，能解孤辰寡宿、祛邪。",
      "fuluTitle86": "天师辟邪祛病符",
      "fuluDes86": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle87": "天师辟邪平安符",
      "fuluDes87": "地司太岁殷元帅，姓殷名郊，道教雷部三十六帅之一，被尊为“正将地司太岁”、“地司荡凶院都雷杀伐主帅”。碧色面，项带十二骷髅，绯袍，跣足，左手黄钺，右手金钟。总管人间祸福、祛除邪魅，奖善罚恶、消灾解厄。",
      "fuluTitle88": "天师护身平安符",
      "fuluDes88": "冲霄主雷邓元帅，名燮，讳伯温，雷霆三帅之首，也是三十六天将的领袖。被尊为“太乙雷霆普化天尊”、“九天欻火律令大神炎帝”，以及“雷主中天歘火律令邓元帅”。赤发金冠，三目青面，凤觜肉翅。左手持雷钻，右手执雷槌，赤体珠缠络，手足皆五爪。上带金环，绿风带，红吊裙，两翼下二头，左主风，右主雨。遍体烈火，乘赤龙。主掌：护身、平安。",
      "fuluTitle89": "天师平安符",
      "fuluDes89": "东狱拷鬼甯元帅符，是专门为调动甯元帅的神力而设的符箓。甯元帅是掌管阴司和捉拿鬼怪妖邪的神明，特别擅长在冥界审判与惩罚恶鬼。此符具有强大的驱鬼能力，能够为佩戴者除去冥界之鬼，净化周围环境，保卫佩戴者免受阴魂、妖邪的困扰。适用于防止鬼魅与邪灵的侵扰，保障家宅和个人的安宁。",
      "fuluTitle90": "天师镇魔符",
      "fuluDes90": "五雷执法毕元帅。讳宗远或崱，号阴雷神君，是道教雷部三十六元帅之一。他被尊称为“三界通真黑灵辅化天尊”、“五雷荡凶副元帅”、“雷门右伐魔天吏”。玉冠，面微赤，无髭，金甲，皂衣，玄履，执斧。主管天地水旱、纠察群魅出没、引导亡魂炼度。",
      "fuluTitle91": "天师镇魔符",
      "fuluDes91": "北极冰池李元帅，讳玄真（一说是自真），是道教中一位重要的战神，通常被认为是真武大帝的化身。他以“凝冰结雪冰池大将”著称，圣诞在农历八月十七日。黑面皂衣，左手执冰袋，右手擎雪轮。主要作用呼风降雪、退热降温、催斩魔群、扫荡妖氛、掌握生死枢机。",
      "fuluTitle92": "天师护身招财符",
      "fuluDes92": "冲霄灵官马元帅，马元帅原名马容容，讳灵耀 ，名马胜 ，又称马天君、三眼灵光、华光天王、五显华光大帝。雷部三十六帅之一，道教护法四帅之首。三头九目，相貌奇特而威猛，头戴蹙金罗花帽，身穿红锦花袍，手执金枪、金砖，足踏火轮，背负火瓢，伴有火鸦万群。主掌：驱邪祛疫，治病伏魔，催生助产，护佑财运。",
      "fuluTitle93": "天师辟邪化煞符",
      "fuluDes93": "上清枢雷程元帅，程元帅，全名为程元帅雷霆大帝或程元帅，有时也称为程雷元帅,雷部三十六帅之一 ,承担着雷部与上清派之间的沟通和协调职责。身着甲胄，手持雷电的武器，面容严肃，英姿飒爽。主掌：天雷与雷电，驱除邪祟、灾祸。化解灾病、行运解煞。",
      "fuluTitle94": "天师招财符",
      "fuluDes94": "正一玄坛赵元帅。赵公明元帅，五路财神首领，武财神。汉代第一代张天师张道陵入鹤鸣山修道时收其为徒。该形象右手执二十四节铁鞭，左手执铁索。主管天下财源禄库，依人之善行，赐人财富。",
      "fuluTitle95": "天师辟邪祛病符",
      "fuluDes95": "南方九凤破秽宋将军。流金火铃宋将军，太阳之精，宋将军，讳迪，字无忌。青面，三目，三头，六臂，金甲，持枪，乘驾火轮降真炁助神笔化金龙，口吐火光，烧阴魅疾。不限于荡秽解厌，而且有驱邪治病、降雨开晴、沐浴疗魂等效果",
      "fuluTitle96": "天师辟邪平安符",
      "fuluDes96": "都雷太岁殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle97": "天师平安镇宅符",
      "fuluDes97": "天师平安镇宅符。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle98": "天师平安符",
      "fuluDes98": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle99": "天师护身平安符",
      "fuluDes99": "冲霄主雷邓元帅，名燮，讳伯温，雷霆三帅之首，也是三十六天将的领袖。被尊为“太乙雷霆普化天尊”、“九天欻火律令大神炎帝”，以及“雷主中天歘火律令邓元帅”。赤发金冠，三目青面，凤觜肉翅。左手持雷钻，右手执雷槌，赤体珠缠络，手足皆五爪。上带金环，绿风带，红吊裙，两翼下二头，左主风，右主雨。遍体烈火，乘赤龙。主掌：护身、平安。",
      "fuluTitle100": "天师辟邪祛病符",
      "fuluDes100": "神威驱邪周元帅。讳巨夫（或称广泽），是道教雷部三十六元帅之一。他通常被称为“北方风轮荡鬼周元帅”，赤发獠牙，面如蓝靛，朱砂发。银牙巨目，手持金鞭或大月，脚踏风火轮。\n主要作用荡秽、驱邪、治病、灭魔。",
      "fuluTitle101": "天师辟邪平安符",
      "fuluDes101": "雷门灭魔邹元帅，讳铨，字节义，又称酆都灭魔邹元帅或酆都刑台铁身公显大将军。他是道教雷部三十六元帅之一，同时也是酆都三十六帅之一，其圣诞在农历七月初四日。朱发面如蓝靛青，铁面铜锤，金甲加身。手执铁简（或称铁枷）。主要作用诛魔灭邪巫、驱邪净坛场、治病驱邪。",
      "fuluTitle102": "天师除妖驱魔符",
      "fuluDes102": "雷霆火部苟元帅，苟元帅原名苟章，也叫苟翌冲、苟留吉 ，号阳雷神君，又称九天应运宣灵演化真君。苟元帅通常面部金冠，黑面或青面，三目怒睁，赤发，金甲，朱衣，朱履，手执金锤钻。主掌：收火邪、五雷荡凶大元帅，扫荡妖氛革除魔试。",
      "fuluTitle103": "天师驱魔符",
      "fuluDes103": "六甲六丁金元帅，六甲六丁金元帅名叫金常胜，讳腾或游、龚，是道教雷部三十六帅之一，天游雷院北极上帅，诰玄帝虎翸神将，统领十二军，督察廿四治。三面六臂，赤发铜目，身着金甲玄袍，显怒威而雷彻九垒，震慑九泉幽冥。主掌：驱鬼镇邪。",
      "fuluTitle104": "天师辟邪祛病符",
      "fuluDes104": "天蓬提督伊元帅，天蓬元帅全称祖师九天尚父五方都总管北极左垣上将都统大元帅天蓬真君 ，姓卞名庄 ，是北斗破军星的化身，金眉老君后身。北极紫微大帝座下护佑天际的道教护法神。三头六臂的大忿怒像 ，身长五十丈 ，赤发、绯衣或黑衣 ，玄冠、金甲或赤甲 ，跣足，执钺斧、弓箭、剑、铎、戟、索六物。主掌：驱除邪恶、避免疾病、灾祸的侵扰。",
      "fuluTitle105": "天师祛温符",
      "fuluDes105": "南极乌旸吴元帅，南极乌旸吴元帅名叫吴明达 ，又称南宫火府祖师乌旸雷师吴真君。雷部三十六帅之一 ，南宫火府赤炎洞主乌旸雷师流金火铃上将。欻火相青身赤发肉角，黄抹额左手皂旗旗上有离卦右手仗火剑，驾火龙偏身出火赫奕可畏。主掌：收邪驱精、祛煞、祛瘟。",
      "fuluTitle106": "天师驱魔符",
      "fuluDes106": "流金火铃宋元帅，流金火铃宋元帅名叫宋无忌 ，字迪 ，又称九凤玉华司宋元帅、南方九凤破秽宋迪元帅、火铃督雷宋元帅。雷部三十六帅之一 ，是道教中负责驱邪、保护信徒和辟邪驱鬼等任务的护法神。青面，三目，三头，六臂，金甲。执火枪、火索、火剑、火铃 ，乘火云。主掌：驱邪除秽，督雷摄魔，考邪炼神，护佑信徒。",
      "fuluTitle107": "天师驱魔符",
      "fuluDes107": "正一箓官陈元帅，陈元帅名叫陈元远。雷部三十六帅之一，正一箓官，负责管理正一派的禄位之事。陈元帅三眼金睛，眉眼及鬓玉色，朱发，面粉团色，无须，头戴蹙金罗帽，金甲锦袍，手执金枪，背火瓢，踏火车，下有白蛇，足着紫靴，腰带弓箭，项上有火色炁，金光束带。主掌：禄位管理，考召鬼神，驱邪镇魔，辅助行法。",
      "fuluTitle108": "天师辟邪化煞符",
      "fuluDes108": "上清枢雷程元帅，程元帅，全名为程元帅雷霆大帝或程元帅，有时也称为程雷元帅,雷部三十六帅之一 ,承担着雷部与上清派之间的沟通和协调职责。身着甲胄，手持雷电的武器，面容严肃，英姿飒爽。主掌：天雷与雷电，驱除邪祟、灾祸。",
      "fuluTitle109": "天师祛温符",
      "fuluDes109": "斗口魁神马元帅，马元帅原名马容容 ，字德之 ，讳灵耀 ，又名马胜。雷部三十六帅之一，主管南极天枢院 、风火驿，部领六天吏兵。青面朱发 ，六臂蓝身 ，蹙金罗帽 ，绯抹额 ，坚红发 ，天带绿吊鞹靴，身着绛袍金甲 ，玉束带，两手火铃火索 ，两手金枪金砖。主掌：驱邪祛疫，考召捉命，辅正除邪辟瘟，纠察三界邪魔，考召捉命。",
      "fuluTitle110": "天师水行平安符",
      "fuluDes110": "北极冰池李元帅，北极冰池李元帅本名李封 ，字青天。雷部三十六帅之一 ，掌管北极冰池。面容冷峻，身穿甲胄，头戴法冠，披着寒冬的披风，手持冰剑。主掌：运用冰力镇妖，降霜雪，调气候，护佑水域安全。",
      "fuluTitle111": "天师驱邪治病符",
      "fuluDes111": "雷霆天医陶元帅，陶元帅，讳公济，雷部三十六帅之一，九霄掌法医主令元帅雷声应化真君，在以雷祖主法妙道上帝为主法的《天医八卦洞神五雷大法》中担任主坛元帅。头戴天丁冠，凤喙肉翅赤足，右手持剑，左手持药瓶。主掌：驱邪治病，调理阴阳，诛邪揖鬼，救旱除妖，修护持药。",
      "fuluTitle112": "天师送子催生符",
      "fuluDes112": "三五梅仙张元帅，三五梅仙张元帅名叫张四郎，又称上清三吾梅仙大帝、九天辅元开化灵应张大帝、七曲毓圣天尊。雷部三十六帅之一 ，三五天仙中的一员，与天界的神祇一起参与天宫事务，维护天地之间的平衡与和谐。身着华丽道袍，头戴道冠，面色和蔼可亲。主掌：送子催生，驱魔抓妖。",
      "fuluTitle113": "天师辟邪护身符",
      "fuluDes113": "五雷判官辛元帅，辛元帅名叫辛兴，字震宇，讳汉臣 ，道教尊其为 “太乙五雷判府天尊”“雷霆三十三天大都督青帝天君”“雷霆正令都督尚书辛天君”。雷部三十六帅之一 ，为雷部中主簿神 ，是雷霆正令大神。戴牛耳幞头，朱发，铁面，银牙如剑，披翠云裘，着皂靴 ，左手执雷簿，右手执雷笔，上有火光。主掌：执行刑罚，监督善恶，维护秩序，辟邪护身。",
      "fuluTitle114": "天师驱邪招财符",
      "fuluDes114": "冲霄灵官马元帅，马元帅原名马容容，讳灵耀 ，名马胜 ，又称马天君、三眼灵光、华光天王、五显华光大帝。雷部三十六帅之一，道教护法四帅之首。三头九目，相貌奇特而威猛，头戴蹙金罗花帽，身穿红锦花袍，手执金枪、金砖，足踏火轮，背负火瓢，伴有火鸦万群。主掌：驱邪祛疫，治病伏魔，催生助产，护佑财运。",
      "fuluTitle115": "天师平安符",
      "fuluDes115": "神雷之首石元帅，石元帅讳圆或远信 ，又称神雷主首石元帅、诛戮执法石元帅。雷部三十六帅之一，五雷之长 ，居神雷首府 ，为雷部中主掌杀伐的重要元帅。黑面红袍，头戴关帅巾，身系长巾带。主掌：代天行化，杀伐不正，维护秩序。",
      "fuluTitle116": "天师祛病符",
      "fuluDes116": "五雷天医张元帅，五雷天医张元帅讳元伯 。雷部三十六帅之一 ，为雷霆飞捷使者 ，又称太乙捷疾直符使者、雷霆六一直符飞捷报应使者。朱发，獬豸冠，青面，三目，出火，绯袍，绿飞天带，金甲，手仗火戟，鬼形，旁出獠牙 ，赤足，驾火龙。主掌：治疗疾病，攻气治病，传讯报应。",
      "fuluTitle117": "天师驱邪符",
      "fuluDes117": "翊灵昭武温元帅，温元帅原名温琼，字子玉，乳名卓郎。雷部三十六帅之一，东岳十太保之首，道教护法将神。青面赤发赤须，金睛獠牙，口若火盆，相貌狰狞威猛，头戴紫巾，身着皂袍铁甲，腰束金束带，脚蹬绿靴，左手执玉环，右手执铁简或狼牙杖。主掌：驱邪辅正，巡察五岳，掌生掌死，治病救人，镇邪祛恶。",
      "fuluTitle118": "天师驱邪符",
      "fuluDes118": "东狱拷鬼甯元帅，雷部三十六元帅之一 ，主要司职于东狱，专职拷鬼之事。身着道教法袍，头戴道冠，面色严肃庄重，眼神犀利威严，手持拷鬼棒。主掌：拷鬼审邪，协助司法，辟邪驱祟。",
      "fuluTitle119": "天师辟邪招财符",
      "fuluDes119": "精忠义勇岳元帅，岳元帅即南宋名将岳飞，字鹏举，相州汤阴（今河南）人。雷部三十六帅之一，道教护法四帅之一，东岳速报司之神。三峰挺耸，头戴帅盔，身披雪花银铠，手执沥泉神枪，五部齐眉，英气逼人。主掌：收鬼镇邪，守护财库，收惊安神，惩恶扬善，速报善恶。",
      "fuluTitle120": "天师转运符",
      "fuluDes120": "雷府驱魔庞元帅，庞元帅原名庞乔，字长清。雷部三十六帅之一 ，被敕封为混气元帅。眼神锐利，眉宇之间透露着神圣的威严，身着雷霆战甲，手持雷锤。主掌：驱除阴魔，消除旧恶，转运旺运，祛病无灾。",
      "fuluTitle121": "天师祛温符",
      "fuluDes121": "地袛太保康元帅，康元帅原名康保裔，道号妙威。雷部三十六元帅之一，东岳大帝属下的十太保之一。身着铠甲或道袍，头戴金盔或道冠，面容和蔼且庄重，眼神中透露出慈悲和威严，左手执金斧，右手执瓜锤。主掌：掌管四方土地神，传讯押瘟，守护大地安宁，救助贫病之人。",
      "fuluTitle122": "天师辟邪招财符",
      "fuluDes122": "九天降魔关元帅，关元帅讳羽，字云长，又称 “荡魔天尊”“伏魔大帝”“关圣帝君” ，简称关帝。雷部三十六帅之一，道教护法四帅之一，三界伏魔大帝神威远镇天尊，武财神。红脸长须，丹凤眼，卧蚕眉，面如重枣，唇若涂脂，身着绿袍金甲，手持青龙偃月刀，威风凛凛，气宇轩昂，坐骑为赤兔马。主掌：收邪伏魔，催财升官，治病除灾，诛罚叛逆，巡察冥司。",
      "fuluTitle123": "天师平安符",
      "fuluDes123": "酆都杀鬼吕元帅，吕元帅名魁，字贵祥，相传为北宋宰相吕公著之后人，也是北宋吕蒙正之后嗣。雷部三十六元帅之一 ，归位后纳入雷部，是雷声普化天尊座下大将。面容威严，赤发金眼绿面红臂，身着道袍或铠甲，手持驱鬼杀鬼法器。主掌：斩杀恶鬼，惩治凶恶，维护地府安宁。",
      "fuluTitle124": "天师五雷辟邪符",
      "fuluDes124": "五雷执法毕元帅，毕元帅原名田华 ，讳宗远。雷部三十六元帅之一，五雷荡凶副元帅，雷门右伐魔天吏，阴雷神君。身着黑袍，头戴道冠，面色威严庄重，眼神犀利如电，手中持有雷符、法剑，身环雷电光芒或阴黑之气。主掌：执掌五雷，诛瘟役鬼，管理天地水旱，惩治不仁不义，守护景阳宫。",
      "fuluTitle125": "天师平安符",
      "fuluDes125": "斗府枢灵刘元帅，刘元帅名吉，字神光 ，一说名甫 ，一说名后 ，又称为斗府枢灵、玄化、慈济真君等。雷部三十六元帅之一，雷部二十四天君之一，上殿卷帘大将军九天云路护驾使。洞玄主帅苍牙铁面九阳上将天君，黑面，少颜，怒容，绊袍，红发，执金鞭，擎火轮，披金甲，足蹑水车 。主掌：掌管风雷雨电雪雹，接引护送亡者超升，保农工收成河海平安，赍送表文。",
      "fuluTitle126": "天师镇邪符",
      "fuluDes126": "酆都拷鬼孟元帅，孟元帅名锷，本名孟山。雷部三十六元帅之一，酆都巨天力士追摄行刑元帅，东岳大帝十太保之一。赤枣面，团眼，四方口，须胡，紫方巾，掩心铁甲，内青衫，外皂袍，穿靴，短裙，执八角大铁槌。主掌：拷鬼行刑，追摄魂魄，管理地府狱事，镇伏邪祟。",
      "fuluTitle127": "天师斩妖符",
      "fuluDes127": "水府斩怪杨元帅，水府斩怪杨元帅名叫杨彪。雷部三十六元帅之一，东岳十太保之一，地祗元帅，水府斩怪之职。身着战甲，头戴帅盔，身姿矫健，面容刚毅，眼神犀利且威严，手持斩妖剑。主掌：斩怪除妖，维护水府安宁，巡查三界。",
      "fuluTitle128": "天师镇妖符",
      "fuluDes128": "雷门火库朱元帅，朱元帅本名朱彦。雷部三十六元帅之一，主管雷门火库，负责掌管和调度雷火之力。黑面恶貌，诸发环眼，身穿金甲，足蹬绿靴，背负追魂袋，左提铁索，右执金槌，胯下黑虎腾云兽 。主掌：考打邪巫毁道之人，收摄三界鬼神，警愚化俗，考勘鬼神。",
      "fuluTitle129": "天师送子治病符",
      "fuluDes129": "金阙监生高元帅，高元帅讳员，或讳刁 ，法名员。雷部三十六元帅之一，烈性九天降生元帅，九天监生大神。貌如冠玉，左手持一金盆，右手执金鞭，胯下骑麒麟。主掌：送子与催生保产，救护生灵与祛邪，治病救人。",
      "fuluTitle130": "天师航海安全符",
      "fuluDes130": "沧海涌潮伍元帅，伍元帅名伍员，字子胥。雷部三十六元帅之一，沧海涌潮之职。手持钢鞭、头戴幞头帽或天丁冠，具有三目，身随十二潮神或十八潮神。主掌：镇妖除魔，管理水府事务，调节潮汛，护佑航海安全。",
      "fuluTitle131": "邓天君符秘",
      "fuluDes131": "上清邓天君辟邪符。邓天君邓伯温雷部诸神之一，雷霆欻火律令大神炎帝，受令断天下不忠不孝之人、鬼祟之魅、一切害民之类。为炎黄时期邓氏部落的首领，跟随黄帝战蚩尤而封将军。邓天君头戴天丁冠、凤喙、背生肉翅、手持雷钻。具有明显的驱除不良气息的效果，免受邪气侵扰。",
      "fuluTitle132": "辛天君符秘",
      "fuluDes132": "雷部辛天君，讳汉臣（或称辛兴，字震宇），是道教雷部三十六元帅之一，全称为“雷霆正令都督尚书辛天君”，又名“银牙猛吏辛天君”、“太乙五雷判府天尊”。传说辛天君原名辛兴，字震宇，是雍州人。据传他曾误将五只雷神变化的鸡带回家给母亲食用，导致母亲被雷震而死。戴牛耳幞头，朱发铁面，银牙如剑。披翠云裘，皂靴，左手执雷簿，右手执雷笔，雷笔上有火光。主管护佑苍生执行、执行雷霆正令。",
      "fuluTitle133": "辛天君符秘",
      "fuluDes133": "雷部辛天君，讳汉臣（或称辛兴，字震宇），是道教雷部三十六元帅之一，全称为“雷霆正令都督尚书辛天君”，又名“银牙猛吏辛天君”、“太乙五雷判府天尊”。传说辛天君原名辛兴，字震宇，是雍州人。据传他曾误将五只雷神变化的鸡带回家给母亲食用，导致母亲被雷震而死。戴牛耳幞头，朱发铁面，银牙如剑。披翠云裘，皂靴，左手执雷簿，右手执雷笔，雷笔上有火光。主管护佑苍生执行、执行雷霆正令。",
      "fuluTitle134": "使者符秘",
      "fuluDes134": "使者符秘赤鸦符，专为召唤赤鸦神使的符箓，主要用于驱逐妖邪与煞气。赤鸦是道教中的神祇之一，能够清除邪恶气息，消灾解难。此符能够帮助佩戴者排除身边的负能量，净化周围环境，保证自身的安稳与安全。适合用于防止家庭或个人遭遇不幸、灾难或不明的困扰。",
      "fuluTitle135": "又录使者符秘",
      "fuluDes135": "\"又录使者\"指的是\"都录使者\"，这一称谓源自于古代官职\"都录事\"的简称。在道教阴间官职体系中，“都录使者”是负责记录人所作善恶并进行审判的神职，掌管“生死簿”。这个职位在地府中具有重要的地位，与人间的善恶记录和死后的审判密切相关。",
      "fuluTitle136": "关帅符秘",
      "fuluDes136": "关帅，即关羽，又称“关圣帝君”、“三界伏魔大帝神威远镇天尊关圣帝君”，简称“关帝”。原为三国时期蜀汉的名将，以忠义著称。他与刘备、张飞结为桃园三结义，成为历史上著名的英雄人物。关羽死后，其忠义精神被广为传颂，逐渐被神化，并被纳入道教神谱关帅集节、义、忠、孝、仁、智、信于一身具有司命禄、祐科举、治病除灾、驱邪辟恶、诛罚叛逆、巡查冥司、庇护商贾招财进宝等职能。他既是武神，又是财神，被尊为“荡魔天尊”、“伏魔大帝”，并被奉为护法四帅之一。",
      "fuluTitle137": "和合三位田元帥符秘",
      "fuluDes137": "田都元帅指的是田苟留、田洪义、田智彪三兄弟，他们被世人尊称为音乐之神、戏剧之神、和合之神。三田都元帅在唐玄宗时期因擅长音乐和戏剧而被召入宫中，成为御前乐师。他们擅长歌舞，能够以音乐和戏剧治愈疾病，曾为帝母治病，使她病愈田苟留。（孟田苟留）形象为白面笑容，口边黑如意，头戴三山帽，半红半绿，双钱花袍，皂靴，双手执金合；田洪义（仲田洪义）形象为白面笑容，口边黑如意，头戴三山帽，半黄半紫，锦袍，白靴，左手执银锭，右手垂袖。主要涉及感情和合、转运、镇宅、化解矛盾等方面。",
      "fuluTitle138": "和合三位田元帥符秘",
      "fuluDes138": "田都元帅指的是田苟留、田洪义、田智彪三兄弟，他们被世人尊称为音乐之神、戏剧之神、和合之神。三田都元帅在唐玄宗时期因擅长音乐和戏剧而被召入宫中，成为御前乐师。他们擅长歌舞，能够以音乐和戏剧治愈疾病，曾为帝母治病，使她病愈田苟留。（孟田苟留）形象为白面笑容，口边黑如意，头戴三山帽，半红半绿，双钱花袍，皂靴，双手执金合；田洪义（仲田洪义）形象为白面笑容，口边黑如意，头戴三山帽，半黄半紫，锦袍，白靴，左手执银锭，右手垂袖。主要涉及感情和合、转运、镇宅、化解矛盾等方面。",
      "fuluTitle139": "琰摩罗朱元帅符秘",
      "fuluDes139": "朱元帅，名彦，是助大道振兴的官将，奉命考打不信之人。他主管风雷雨部，下辖酆都岳渎，统领天将天兵，收摄三界鬼神。驱逐罡风浩炁不正鬼神，扫荡天下妖氛恶毒之炁，屏除不祥。对于不信正道之人，能剪缚阳魂，虚空悬吊，即行考治，警愚化俗。他通常被称作雷府管不信道法打生死邪巫黑面大神朱将军或火犀雷府伐生死邪巫朱将军。朱元帅的形象多样，但常表现为黑面怒相，环眼，朱发举头，皂袍金甲，汗袴绿靴。左手提铁索，右手执金槌或执铁简，背负追魂袋，跨黑虎。",
      "fuluTitle140": "神虎鲁将军符秘",
      "fuluDes140": "神虎鲁将军的本名分别为赵垕和梁伟。主要职能是在斋醮仪式中引领亡魂受度，尤其在超度亡魂、追摄亡魂的仪式中扮演着重要角色。神虎鲁将军符秘在道教中具有重要的宗教意义，主要用于超度亡魂和追摄亡魂的仪式中，通过特定的符咒和法术实践，帮助亡魂得到超度和安宁。",
      "fuluTitle141": "雄左大力天丁何元帅符秘",
      "fuluDes141": "何元帅，讳昌，即阳神大圣，又称神虎雄左玉曹统制百节三魂玄伯大圣。隶属北魁玄范府的神虎追摄司，在道法斋仪中经常被召请追摄亡魂临坛受度。它主要的作用包括驱邪避灾、护佑信众平安吉祥等。",
      "fuluTitle142": "温公符秘",
      "fuluDes142": "翊灵昭武温元帅，温元帅原名温琼，字子玉，乳名卓郎。雷部三十六帅之一，东岳十太保之首，道教护法将神。青面赤发赤须，金睛獠牙，口若火盆，相貌狰狞威猛，头戴紫巾，身着皂袍铁甲，腰束金束带，脚蹬绿靴，左手执玉环，右手执铁简或狼牙杖。主掌：驱邪辅正，巡察五岳，掌生掌死，治病救人，镇邪祛恶。",
      "fuluTitle143": "右朱帅符秘",
      "fuluDes143": "雷门火库朱元帅，朱元帅本名朱彦。雷部三十六元帅之一，主管雷门火库，负责掌管和调度雷火之力。黑面恶貌，诸发环眼，身穿金甲，足蹬绿靴，背负追魂袋，左提铁索，右执金槌，胯下黑虎腾云兽 。主掌：考打邪巫毁道之人，收摄三界鬼神，警愚化俗，考勘鬼神。",
      "fuluTitle144": "康帅符秘",
      "fuluDes144": "地袛太保康元帅，康元帅原名康保裔，道号妙威。雷部三十六元帅之一，东岳大帝属下的十太保之一。身着铠甲或道袍，头戴金盔或道冠，面容和蔼且庄重，眼神中透露出慈悲和威严，左手执金斧，右手执瓜锤。主掌：掌管四方土地神，传讯押瘟，守护大地安宁，救助贫病之人。",
      "fuluTitle145": "又康帅符秘",
      "fuluDes145": "地袛太保康元帅，康元帅原名康保裔，道号妙威。雷部三十六元帅之一，东岳大帝属下的十太保之一。身着铠甲或道袍，头戴金盔或道冠，面容和蔼且庄重，眼神中透露出慈悲和威严，左手执金斧，右手执瓜锤。主掌：掌管四方土地神，传讯押瘟，守护大地安宁，救助贫病之人。",
      "fuluTitle146": "张太保符秘",
      "fuluDes146": "宣灵昭庆使张元帅。岳府十大太保之一。本名张巡，生于唐中宗景龙二年，安史之乱时，起兵守卫雍丘，抵抗叛军。不幸英勇就义后被民间立祠祀奉，被敕封为通天斩邪大将东平忠靖威烈元帅。可以消冤绝祸、祈晴祷雨。",
      "fuluTitle147": "酆都魏帅符秘",
      "fuluDes147": "魏元帅。魏伯贤，眇莽九丑大圣，酆都内台九泉魔首。统领整个北阴酆都所有阴司元帅。青鬼面，三目，金睛，赤发，项带九骷髅，头戴铁蛇冠，皂大袍，结袖，金甲，皂履，盘坐九条白莽蛇上，仗火剑。主掌杀伐邪魔，辅正除邪。",
      "fuluTitle148": "酆都魏帅符秘",
      "fuluDes148": "魏元帅。魏伯贤，眇莽九丑大圣，酆都内台九泉魔首。统领整个北阴酆都所有阴司元帅。青鬼面，三目，金睛，赤发，项带九骷髅，头戴铁蛇冠，皂大袍，结袖，金甲，皂履，盘坐九条白莽蛇上，仗火剑。主掌杀伐邪魔，辅正除邪。",
      "fuluTitle149": "八煞九蟒杨元帅符秘",
      "fuluDes149": "水府斩怪杨元帅。名叫杨彪。雷部三十六元帅之一，东岳十太保之一，地祗元帅，水府斩怪之职。身着战甲，头戴帅盔，身姿矫健，面容刚毅，眼神犀利且威严，手持斩妖剑。主掌：斩怪除妖，维护水府安宁，巡查三界。 可以水行护身。",
      "fuluTitle150": "八煞九蟒杨元帅符秘",
      "fuluDes150": "水府斩怪杨元帅。名叫杨彪。雷部三十六元帅之一，东岳十太保之一，地祗元帅，水府斩怪之职。身着战甲，头戴帅盔，身姿矫健，面容刚毅，眼神犀利且威严，手持斩妖剑。主掌：斩怪除妖，维护水府安宁，巡查三界。 可以水行护身。",
      "fuluTitle151": "刘元帅符秘",
      "fuluDes151": "斗府枢灵刘元帅，刘元帅名吉，字神光 ，一说名甫 ，一说名后 ，又称为斗府枢灵、玄化、慈济真君等。雷部三十六元帅之一，雷部二十四天君之一，上殿卷帘大将军九天云路护驾使。洞玄主帅苍牙铁面九阳上将天君，黑面，少颜，怒容，绊袍，红发，执金鞭，擎火轮，披金甲，足蹑水车 。主掌：掌管风雷雨电雪雹，接引护送亡者超升，保农工收成河海平安，赍送表文。",
      "fuluTitle152": "雌右武鸾乔将军符秘",
      "fuluDes152": "雷府驱魔庞元帅，庞元帅原名庞乔，字长清。雷部三十六帅之一 ，被敕封为混气元帅。眼神锐利，眉宇之间透露着神圣的威严，身着雷霆战甲，手持雷锤。主掌：驱除阴魔，消除旧恶，转运旺运，祛病无灾。",
      "fuluTitle153": "丹田火铃宋将军符秘",
      "fuluDes153": "流金火铃宋元帅，流金火铃宋元帅名叫宋无忌 ，字迪 ，又称九凤玉华司宋元帅、南方九凤破秽宋迪元帅、火铃督雷宋元帅。雷部三十六帅之一 ，是道教中负责驱邪、保护信徒和辟邪驱鬼等任务的护法神。青面，三目，三头，六臂，金甲。执火枪、火索、火剑、火铃 ，乘火云。主掌：驱邪除秽，督雷摄魔，考邪炼神，护佑信徒。",
      "fuluTitle154": "北极仓将伍元帅符秘",
      "fuluDes154": "沧海涌潮伍元帅，伍元帅名伍员，字子胥。雷部三十六元帅之一，沧海涌潮之职。手持钢鞭、头戴幞头帽或天丁冠，具有三目，身随十二潮神或十八潮神。主掌：镇妖除魔，管理水府事务，调节潮汛，护佑航海安全。",
      "fuluTitle155": "黑面捉疾雷元帅符秘",
      "fuluDes155": "雷元帅，讳轰，即捷疾黑面雷元帅，又称掣电轰光王雷轰大神，天神雷轰将军等。形象多执符命，腰系铁索。多用于解冤释结、解救刑罚、寄名赎身。",
      "fuluTitle156": "催主保产高元帅符秘",
      "fuluDes156": "金阙监生高元帅，高元帅讳员，或讳刁 ，法名员。雷部三十六元帅之一，烈性九天降生元帅，九天监生大神。貌如冠玉，左手持一金盆，右手执金鞭，胯下骑麒麟。主掌：送子与催生保产，救护生灵与祛邪，治病救人。",
      "fuluTitle157": "天医赵元帅符秘",
      "fuluDes157": "混元八卦天医主帅赵元帅，讳邦英，九天云路神捷上将、速捉如意天医赵元帅，雷霆天医三十六元帅之首。雷霆天医法不仅可以救治生人实证，还能医疗残魂。治邪怪则恶祟潜形，祛疫病则瘟魔束首。",
      "fuluTitle158": "吊鬼邬元帅符秘",
      "fuluDes158": "庚寅太岁邬桓大将军，六十甲子神之一，虎相，持仙丹，在元代时降生在新昌，在宣德年间任溧阳县县丞，扶助弱小，压制强暴，去除腐败。本命神，主要负责趋吉避凶。",
      "fuluTitle159": "酆都邓元帅符秘",
      "fuluDes159": "酆都太守馘灭邪精邓元帅，讳艾济明，于治鬼、进贤、决讼、检奸、任免掾吏，多见于酆都考召法中。可以治邪怪祛疫病。",
      "fuluTitle160": "风轮荡鬼周元帅符秘",
      "fuluDes160": "周元帅。讳巨夫（或称广泽），是道教雷部三十六元帅之一。他通常被称为“北方风轮荡鬼周元帅”，赤发獠牙，面如蓝靛，朱砂发。银牙巨目，手持金鞭或大月，脚踏风火轮。\n主要作用荡秽、驱邪、治病、灭魔。",
      "fuluTitle161": "洞清张神君符秘",
      "fuluDes161": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle162": "社令程使者符秘",
      "fuluDes162": "社令程使者符秘，是一种用于调动程使者神力的符箓，主要用于镇守家宅与社稷。程使者是道教中专责保卫地方、镇守民众的神祇。此符能够化解不安定的气场，增强家宅的安全感与稳固性。适用于防止外来侵扰、保障家人平安，特别是在环境不稳或遭遇灾难时，能够迅速恢复平静。符力强大，能够调动程使者的庇佑。",
      "fuluTitle163": "左伐魔苟元帅符秘",
      "fuluDes163": "雷霆火部苟元帅，苟元帅原名苟章，也叫苟翌冲、苟留吉 ，号阳雷神君，又称九天应运宣灵演化真君。苟元帅通常面部金冠，黑面或青面，三目怒睁，赤发，金甲，朱衣，朱履，手执金锤钻。主掌：收火邪、五雷荡凶大元帅，扫荡妖氛革除魔试。",
      "fuluTitle164": "右伐魔毕元帅符秘",
      "fuluDes164": "毕元帅。讳宗远或崱，号阴雷神君，是道教雷部三十六元帅之一。他被尊称为“三界通真黑灵辅化天尊”、“五雷荡凶副元帅”、“雷门右伐魔天吏”。玉冠，面微赤，无髭，金甲，皂衣，玄履，执斧。主管天地水旱、纠察群魅出没、引导亡魂炼度。",
      "fuluTitle165": "庞元帅符秘",
      "fuluDes165": "雷府驱魔庞元帅，庞元帅原名庞乔，字长清。雷部三十六帅之一 ，被敕封为混气元帅。眼神锐利，眉宇之间透露着神圣的威严，身着雷霆战甲，手持雷锤。主掌：驱除阴魔，消除旧恶，转运旺运，祛病无灾。",
      "fuluTitle166": "神霄主首石元帅符秘",
      "fuluDes166": "神雷之首石元帅，石元帅讳圆或远信 ，又称神雷主首石元帅、诛戮执法石元帅。雷部三十六帅之一，五雷之长 ，居神雷首府 ，为雷部中主掌杀伐的重要元帅。黑面红袍，头戴关帅巾，身系长巾带。主掌：代天行化，杀伐不正，维护秩序。",
      "fuluTitle167": "谢元帅符秘",
      "fuluDes167": "斗中运火大圣玉梵谢元帅,斗法中经常召请的六位使者之一，居上清枢阴外院。玉梵使者和妙梵使者，被认为斗中执掌水火的使者，随雷祖大帝斩勘杀伐、解冤释结、助国救民。有关水旱、虫蝗等事往往申奏雷祖大帝，投告玉梵使者和妙梵使者。",
      "fuluTitle168": "甯元帅符秘",
      "fuluDes168": "东狱拷鬼甯元帅，雷部三十六元帅之一 ，主要司职于东狱，专职拷鬼之事。身着道教法袍，头戴道冠，面色严肃庄重，眼神犀利威严，手持拷鬼棒。主掌：拷鬼审邪，协助司法，辟邪驱祟。",
      "fuluTitle169": "任元帅符秘",
      "fuluDes169": "任元帅。讳赤阳，斗中运火大圣玉梵使者。是斗法中经常召请的六位使者之一，居上清枢阴外院。常见多是双臂三目的番人相，也有三头六臂的愤怒相。在道法中，代天行化，助国救民。",
      "fuluTitle170": "高元帅符秘",
      "fuluDes170": "金阙监生高元帅，高元帅讳员，或讳刁 ，法名员。雷部三十六元帅之一，烈性九天降生元帅，九天监生大神。貌如冠玉，左手持一金盆，右手执金鞭，胯下骑麒麟。主掌：送子与催生保产，救护生灵与祛邪，治病救人。",
      "fuluTitle171": "王元帅符秘",
      "fuluDes171": "王元帅符秘。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle172": "刘元帅符秘",
      "fuluDes172": "斗府枢灵刘元帅，刘元帅名吉，字神光 ，一说名甫 ，一说名后 ，又称为斗府枢灵、玄化、慈济真君等。雷部三十六元帅之一，雷部二十四天君之一，上殿卷帘大将军九天云路护驾使。洞玄主帅苍牙铁面九阳上将天君，黑面，少颜，怒容，绊袍，红发，执金鞭，擎火轮，披金甲，足蹑水车 。主掌：掌管风雷雨电雪雹，接引护送亡者超升，保农工收成河海平安，赍送表文。",
      "fuluTitle173": "张元帅符秘",
      "fuluDes173": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle174": "王元帅符秘",
      "fuluDes174": "王元帅符秘。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle175": "先天主将符秘",
      "fuluDes175": "先天主将符秘。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle176": "右主将符符秘",
      "fuluDes176": "‌道教右主将包括邓元帅、张元帅、庞元帅、勾元帅、朱元帅和岳元帅‌‌等。这些右主将在降妖除魔的“行军作战”过程中，通常被称为大元帅或者元帅，是全军的最高指挥、主将和统帅。这些元帅不仅在法术中最主要的统帅角色还负责调遣和命令其他各路神兵将领‌。",
      "fuluTitle177": "右主将符符秘",
      "fuluDes177": "‌道教右主将包括邓元帅、张元帅、庞元帅、勾元帅、朱元帅和岳元帅‌‌等。这些右主将在降妖除魔的“行军作战”过程中，通常被称为大元帅或者元帅，是全军的最高指挥、主将和统帅。这些元帅不仅在法术中最主要的统帅角色还负责调遣和命令其他各路神兵将领‌。",
      "fuluTitle178": "马帅符秘",
      "fuluDes178": "冲霄灵官马元帅，马元帅原名马容容，讳灵耀 ，名马胜 ，又称马天君、三眼灵光、华光天王、五显华光大帝。雷部三十六帅之一，道教护法四帅之首。三头九目，相貌奇特而威猛，头戴蹙金罗花帽，身穿红锦花袍，手执金枪、金砖，足踏火轮，背负火瓢，伴有火鸦万群。主掌：驱邪祛疫，治病伏魔，催生助产，护佑财运。",
      "fuluTitle179": "马帅符秘",
      "fuluDes179": "冲霄灵官马元帅，马元帅原名马容容，讳灵耀 ，名马胜 ，又称马天君、三眼灵光、华光天王、五显华光大帝。雷部三十六帅之一，道教护法四帅之首。三头九目，相貌奇特而威猛，头戴蹙金罗花帽，身穿红锦花袍，手执金枪、金砖，足踏火轮，背负火瓢，伴有火鸦万群。主掌：驱邪祛疫，治病伏魔，催生助产，护佑财运。",
      "fuluTitle180": "灭巫朱雷君符秘",
      "fuluDes180": "打邪灭巫朱元帅。出入上清火犀宫，主治火府雷部。主管风雷雨部，下辖酆都岳渎，统领天将天兵，收摄三界鬼神。商时，朱元帅曾入太华山修道。黑面怒相，环眼，朱发举头，皂袍金甲，左手提铁索，右手执金槌。驱逐不正鬼神，扫荡天下妖恶，屏除不祥。",
      "fuluTitle181": "又录朱帅符秘",
      "fuluDes181": "打邪灭巫朱元帅。出入上清火犀宫，主治火府雷部。主管风雷雨部，下辖酆都岳渎，统领天将天兵，收摄三界鬼神。商时，朱元帅曾入太华山修道。黑面怒相，环眼，朱发举头，皂袍金甲，左手提铁索，右手执金槌。驱逐不正鬼神，扫荡天下妖恶，屏除不祥。",
      "fuluTitle182": "赵玄坛符秘",
      "fuluDes182": "赵公明元帅，五路财神首领。张天师命其守护玄坛，因此道教尊为正一玄坛元帅。该形象是其坐骑黑虎，又称黑虎玄坛。招财镇宝财运亨通，主要作用于升职加薪，广进财源。",
      "fuluTitle183": "又赵玄坛符秘",
      "fuluDes183": "赵公明元帅，五路财神首领。张天师命其守护玄坛，因此道教尊为正一玄坛元帅。该形象是其坐骑黑虎，又称黑虎玄坛。招财镇宝财运亨通，主要作用于升职加薪，广进财源。",
      "fuluTitle184": "右殷帅符秘",
      "fuluDes184": "都雷太岁殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle185": "地司主将殷元帅符秘",
      "fuluDes185": "都雷太岁殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle186": "殷帅符秘",
      "fuluDes186": "都雷太岁殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle187": "斗姥符秘",
      "fuluDes187": "斗姆元君，又称斗姥、斗母或先天道母。乃元始天尊之先天阴气，托化西竺国中，为周御国王之后妃，号“紫光夫人”。形象通常被描绘为三目、四首、八臂的女神，手持各种法器，坐于莲华宝座之上。斗姆元君可以消灾解厄，保命延生也。世人有病，多向斗姥求救。",
      "fuluTitle188": "月孛朱天君符秘",
      "fuluDes188": "月孛朱天君。姓朱讳光，天人相，披发裸体，黑云掩脐，红履鞋，左手提旱魃头，右手仗剑，骑玉龙，变相青面獠牙，绯衣，仗剑，驾熊。在道教中是被崇奉的星宿神之一，属于“十一大曜”（日、月、五星、罗睺、计都、紫炁、月孛）中的“太一月孛星君”。具有驱邪避灾、保护平安，也涉及维护正义、辅助正真，以及与神灵沟通、制御生民等多个方面。",
      "fuluTitle189": "月孛朱天君符秘",
      "fuluDes189": "月孛朱天君。姓朱讳光，天人相，披发裸体，黑云掩脐，红履鞋，左手提旱魃头，右手仗剑，骑玉龙，变相青面獠牙，绯衣，仗剑，驾熊。在道教中是被崇奉的星宿神之一，属于“十一大曜”（日、月、五星、罗睺、计都、紫炁、月孛）中的“太一月孛星君”。具有驱邪避灾、保护平安，也涉及维护正义、辅助正真，以及与神灵沟通、制御生民等多个方面。",
      "fuluTitle190": "月孛朱天君符秘",
      "fuluDes190": "月孛朱天君。姓朱讳光，天人相，披发裸体，黑云掩脐，红履鞋，左手提旱魃头，右手仗剑，骑玉龙，变相青面獠牙，绯衣，仗剑，驾熊。在道教中是被崇奉的星宿神之一，属于“十一大曜”（日、月、五星、罗睺、计都、紫炁、月孛）中的“太一月孛星君”。具有驱邪避灾、保护平安，也涉及维护正义、辅助正真，以及与神灵沟通、制御生民等多个方面。",
      "fuluTitle191": "邓天君符秘",
      "fuluDes191": "上清邓天君辟邪符。邓天君邓伯温雷部诸神之一，雷霆欻火律令大神炎帝，受令断天下不忠不孝之人、鬼祟之魅、一切害民之类。为炎黄时期邓氏部落的首领，跟随黄帝战蚩尤而封将军。邓天君头戴天丁冠、凤喙、背生肉翅、手持雷钻。具有明显的驱除不良气息的效果，免受邪气侵扰。",
      "fuluTitle192": "辛元帅子日管事",
      "fuluDes192": "辛元帅子日管事，辛元帅名叫辛兴 ，字震宇 。雷部三十六元帅之一，太乙五雷判府天尊，雷霆三十三天大都督青帝天君，雷部中主簿。戴牛耳幞头，朱发，铁面银牙如剑，披翠云裘皂靴，左手执雷簿，右手执雷笔，上有火光。主掌：代天执法，掌风云雷雨电雪雹，提点三界三十二天，记录善恶。",
      "fuluTitle193": "邓元帅丑日管事",
      "fuluDes193": "邓元帅丑日管事，邓元帅名叫邓伯温 ，也称为邓忠 、邓化。雷部三十六元帅之首，欻火律令大神，丑日管事。红发青面，三目尖嘴，肉翅，青浑身，三爪，风带汗衫 ，左手雷钻，右手雷锤。主掌：主掌雷电，惩处邪恶，管理雷部事务。",
      "fuluTitle194": "赵元帅寅日管事",
      "fuluDes194": "赵元帅寅日管事，赵元帅名叫赵朗，字公明 ，也称为赵玄坛 、黑虎玄坛赵元帅。神霄玉府大都督，五方巡察使，九州社令，直殿大将军等。铁色面，圆眼，黑胡须 ，头戴铁幞头，黄抹额，身披金甲，绿靴 ，右手执二十四节铁鞭，左手执铁索，身跨黑虎。主掌：驱雷役电，致雨呼风，除瘟剪疟，保病禳灾，招财进宝，主管财富，主持公道，解讼冤抑。",
      "fuluTitle195": "张元帅卯日管事",
      "fuluDes195": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle196": "张元帅卯日管事",
      "fuluDes196": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle197": "杨元帅辰日管事",
      "fuluDes197": "水府斩怪杨元帅。名叫杨彪。雷部三十六元帅之一，东岳十太保之一，地祗元帅，水府斩怪之职。身着战甲，头戴帅盔，身姿矫健，面容刚毅，眼神犀利且威严，手持斩妖剑。主掌：斩怪除妖，维护水府安宁，巡查三界。 可以水行护身。",
      "fuluTitle198": "马元帅巳日管事",
      "fuluDes198": "冲霄灵官马元帅，马元帅原名马容容，讳灵耀 ，名马胜 ，又称马天君、三眼灵光、华光天王、五显华光大帝。雷部三十六帅之一，道教护法四帅之首。三头九目，相貌奇特而威猛，头戴蹙金罗花帽，身穿红锦花袍，手执金枪、金砖，足踏火轮，背负火瓢，伴有火鸦万群。主掌：驱邪祛疫，治病伏魔，催生助产，护佑财运。",
      "fuluTitle199": "王元帅午日管事",
      "fuluDes199": "王元帅午日管事。王灵官，原名王恶，后改名为王善。五百灵官之首，道教第一护法神都天大灵官。宋徽宗萨守坚真人的弟子。他形象为三目怒视、手持钢鞭、身穿金甲红袍。主要用于镇宅驱邪，保家平安。",
      "fuluTitle200": "殷元帅未日管事",
      "fuluDes200": "都雷太岁殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle201": "温元帅申日管事",
      "fuluDes201": "翊灵昭武温元帅，温元帅原名温琼，字子玉，乳名卓郎。雷部三十六帅之一，东岳十太保之首，道教护法将神。青面赤发赤须，金睛獠牙，口若火盆，相貌狰狞威猛，头戴紫巾，身着皂袍铁甲，腰束金束带，脚蹬绿靴，左手执玉环，右手执铁简或狼牙杖。主掌：驱邪辅正，巡察五岳，掌生掌死，治病救人，镇邪祛恶。",
      "fuluTitle202": "康元帅酉日管事",
      "fuluDes202": "地袛太保康元帅，康元帅原名康保裔，道号妙威。雷部三十六元帅之一，东岳大帝属下的十太保之一。身着铠甲或道袍，头戴金盔或道冠，面容和蔼且庄重，眼神中透露出慈悲和威严，左手执金斧，右手执瓜锤。主掌：掌管四方土地神，传讯押瘟，守护大地安宁，救助贫病之人。",
      "fuluTitle203": "关元帅戌日管事",
      "fuluDes203": "九天降魔关元帅，关元帅讳羽，字云长，又称 “荡魔天尊”“伏魔大帝”“关圣帝君” ，简称关帝。雷部三十六帅之一，道教护法四帅之一，三界伏魔大帝神威远镇天尊，武财神。红脸长须，丹凤眼，卧蚕眉，面如重枣，唇若涂脂，身着绿袍金甲，手持青龙偃月刀，威风凛凛，气宇轩昂，坐骑为赤兔马。主掌：收邪伏魔，催财升官，治病除灾，诛罚叛逆，巡察冥司。",
      "fuluTitle204": "朱元帅亥日管事",
      "fuluDes204": "雷门火库朱元帅，朱元帅本名朱彦。雷部三十六元帅之一，主管雷门火库，负责掌管和调度雷火之力。黑面恶貌，诸发环眼，身穿金甲，足蹬绿靴，背负追魂袋，左提铁索，右执金槌，胯下黑虎腾云兽 。主掌：考打邪巫毁道之人，收摄三界鬼神，警愚化俗，考勘鬼神。",
      "fuluTitle205": "南方收旗蛮雷摄",
      "fuluDes205": "雷部禁军，五方蛮雷，其神威极烈。郭元帅也是阳五方蛮雷使者之一，负责南方雷部‌。混元雷霆天医大圣郭元帅，攻炁治病、疗魂育魄、整具形神、斩尸却鬼。",
      "fuluTitle206": "东方收旗蛮雷摄",
      "fuluDes206": "雷部禁军，五方蛮雷，其神威极烈。马元帅是阳五方蛮雷使者之一，负责东方雷部‌。冲霄灵官马元帅，马元帅原名马容容，讳灵耀 ，名马胜。雷部三十六帅之一，道教护法四帅之首。主掌：驱邪祛疫，治病伏魔，催生助产，护佑财运。",
      "fuluTitle207": "北方收旗蛮雷摄",
      "fuluDes207": "雷部禁军，五方蛮雷，其神威极烈。邓元帅是阳五方蛮雷使者之一，负责北方雷部‌。邓元帅名叫邓伯温，雷部三十六元帅之首，欻火律令大神。主掌：主掌雷电，惩处邪恶，管理雷部事务。",
      "fuluTitle208": "西方收旗蛮雷摄",
      "fuluDes208": "雷部禁军，五方蛮雷，其神威极烈。方元帅同样是阳五方蛮雷使者之一，负责西方雷部‌。方元帅，讳道彰，又称飞扬烈令天君。五云冠、美貌相、金甲、青衣、朱履、掷风轮。可以灭恶擒精，驱邪治病，降雨兴风。",
      "fuluTitle209": "中央收旗蛮雷摄",
      "fuluDes209": "雷部禁军，五方蛮雷，其神威极烈。田元帅是阳五方蛮雷使者之一，负责中央雷部‌。混元天医五雷神将田元帅，混元雷法主将、天医五雷之首。可以邪降炁，续命，疗魂育魄。",
      "fuluTitle210": "和合田元帅符",
      "fuluDes210": "所谓的“三田”，分别是指的“田苟留”，“田洪义”，“田智彪”这三位任职于“冲天风火院”，专门执掌和合善缘的神明。在开元盛世时被进宫应召归与当时的雷总管部下作为宫廷乐师。专门执掌和合善缘。",
      "fuluTitle211": "张天君收影符",
      "fuluDes211": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle212": "张天君收影符",
      "fuluDes212": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle213": "张元帅先天削影",
      "fuluDes213": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle214": "殷公削影本身符",
      "fuluDes214": "都雷太岁殷元帅。殷元帅殷郊，金鼎妙化申真人弟子，雷部三十六帅之一，正将地司太岁，掌管着一年中人们的吉凶祸福。纣王之子，出生后被抛弃于荒野，故以郊为名，以国为姓。可以安土化煞，驱邪制魅，镇宅保安。",
      "fuluTitle215": "北方第二星符",
      "fuluDes215": "北方第二星符，是与北方星宿体系相关的符箓，专门用于呼唤北方二十八宿中的第二颗星的庇佑。此符主掌镇宅安居，化解一切困厄，提升佩戴者的运势。特别适用于遭遇阻碍或遇到困境时，能够帮助化解不利因素，带来事业和财运上的助力、，主要依托北方星君的神力进行运作。",
      "fuluTitle216": "左遍请雷天大子符",
      "fuluDes216": "左遍请雷天大子符，是用于召唤雷天大子，专责驱除一切邪祟与妖魔，镇压不安定的煞气，保卫佩戴者的安宁。符内包含强大的雷电法力，能够利用雷霆之力清除邪气，净化身边环境。适合用于平衡负能量、化解灾祸，保护佩戴者免受外界不良影响。依托雷霆的威慑力量进行防护。",
      "fuluTitle217": "右关五雷勒令符",
      "fuluDes217": "右关五雷勒令符，主要用于调动五雷之力，专责驱邪镇煞、保家护院。符中融合了五雷的精髓，通过释放雷电之力来压制邪灵与煞气，确保家宅与个人安宁。此符适用于防止小人、灾难和不吉之事，能够为佩戴者带来正气，排除外部干扰，增进运势。流派上源于道教雷法，具有强大的外部防护能力。",
      "fuluTitle218": "使者五雷结旙符秘",
      "fuluDes218": "五雷法，又称五雷、五雷法、五雷天心正法。最早是用来祛邪治病、祈晴祷雨的法术。后来据说改进成了引天雷下凡来诛邪崇的法术。",
      "fuluTitle219": "天皇结旙符机符",
      "fuluDes219": "天皇大帝，又称“西方太极天皇大帝”“勾陈上宫天皇大帝”“勾陈大帝”等，为“六御”之一。作为万星之宗主，天皇大帝主御群灵，执万神图，统率天地的万神，其地位与北极紫微大帝相当。协助玉皇大帝执掌南北两极和天、地、人三才,统御众星，并主持人间兵革之事。",
      "fuluTitle220": "天皇结旙符机符",
      "fuluDes220": "天皇大帝，又称“西方太极天皇大帝”“勾陈上宫天皇大帝”“勾陈大帝”等，为“六御”之一。作为万星之宗主，天皇大帝主御群灵，执万神图，统率天地的万神，其地位与北极紫微大帝相当。协助玉皇大帝执掌南北两极和天、地、人三才,统御众星，并主持人间兵革之事。",
      "fuluTitle221": "天皇结旙符机符",
      "fuluDes221": "天皇大帝，又称“西方太极天皇大帝”“勾陈上宫天皇大帝”“勾陈大帝”等，为“六御”之一。作为万星之宗主，天皇大帝主御群灵，执万神图，统率天地的万神，其地位与北极紫微大帝相当。协助玉皇大帝执掌南北两极和天、地、人三才,统御众星，并主持人间兵革之事。",
      "fuluTitle222": "张使者结旙大法符",
      "fuluDes222": "飞捷报应张元帅。讳珏（或作钰、燔），字元伯，是道教雷部的重要神祇之一。他与邓元帅、辛元帅合称为“雷霆三帅”。面带赤色，身穿黄金甲，执铁斧，乘雷电火光。掌管雷云电雨、诛灭五逆，赏善罚恶、传奏号召、治疾普济。",
      "fuluTitle223": "太岁退煞符",
      "fuluDes223": "太岁，掌管岁运，主宰一年之气运，专责化解流年煞气、避免不祥之事。太岁符主要用于化解因流年犯太岁带来的不利影响，保护家宅平安，避免煞气侵扰，增强个人运势，特别是对冲犯太岁的年份和人群有强大的护佑作用。",
      "fuluTitle224": "玄坛回兵符",
      "fuluDes224": "玄坛元帅，雷部元帅之一，专责回击外来恶势力，镇压邪魔妖怪，确保国家与家宅的安宁。身披战甲，手持大刀，气吞万里，神情威严。玄坛回兵符主要用于镇压外来的煞气与邪气，化解敌对势力的侵扰，保卫家宅的安宁。此符有效消除恶运与外部负面影响，增强佩戴者的气运与防护能力。",
      "fuluTitle225": "翻解秘要召朱帅符",
      "fuluDes225": "雷门火库朱元帅，朱元帅本名朱彦。雷部三十六元帅之一，主管雷门火库，负责掌管和调度雷火之力。黑面恶貌，诸发环眼，身穿金甲，足蹬绿靴，背负追魂袋，左提铁索，右执金槌，胯下黑虎腾云兽 。主掌：考打邪巫毁道之人，收摄三界鬼神，警愚化俗，考勘鬼神。",
      "fuluTitle226": "神虎形符",
      "fuluDes226": "神虎，威猛无比，常以虎形现身，专责消除凶煞与妖邪。身形矫健，猛虎下山，眼神锐利，具备极强的震慑力。神虎符用于驱逐凶煞、妖邪，尤其适合用于有强烈负面能量或不安定因素的环境。佩戴此符，能保家宅安宁，镇宅避邪，避免外界灾难侵扰，增强防护效果。",
      "fuluTitle227": "百解要秘符",
      "fuluDes227": "百解要秘符，源自道教中的秘法，用于化解百种灾难、解除百般困厄。其符文精细复杂，蕴含深奥的道家秘术，能够解除各种不利运势与灾祸。符身金光闪烁，充满神秘气息，主掌调和五行，化解万象不吉。百解要秘符主要用于驱除各种煞气，化解个人和家宅中的困境，转运解灾，帮助佩戴者迎接新机遇。",
      "fuluTitle228": "太保刷卷符",
      "fuluDes228": "太保刷卷符，专责清理家宅中的煞气与污秽，恢复家中正气。太保身着官服，手持卷轴，象征权威与清明。此符具有强大的清洁净化功能，能够迅速消除空间中的负面能量，恢复原本的祥和气息。佩戴此符能够清理家宅中的阴气和邪祟，保持环境的和谐，适合用于长期居住的地方以保持安宁。",
      "fuluTitle229": "朱彦攻治大法符",
      "fuluDes229": "朱元帅，司掌破煞与治病的神祇，手持铁鞭，身着官袍，威猛庄严。朱彦攻治大法符，主掌除灾治病、斩妖驱邪，特别擅长用神力治愈疾病，破解不利气场。此符的功能不仅仅是驱邪除怪，还能有效治疗因气运不畅或风水不佳引起的身体与精神问题。佩戴此符能帮助佩戴者获得神祇的庇佑，驱除困扰，恢复健康和顺利。",
      "fuluTitle230": "家宅平安符",
      "fuluDes230": "家宅平安符，专门用于保家护宅，保护家宅免受外界不良因素的侵扰。其符文古老，蕴含神圣的护宅法力，能够使家宅内外充满正气，远离煞气与阴煞。象征着全方位的守护。此符用于镇宅安神，驱逐邪气，增强家庭成员的运势与健康，确保家宅的平安与和谐。",
      "fuluTitle231": "小见住邪符",
      "fuluDes231": "小见住邪符，专责化解小范围内的邪气与煞气，适合用于短期护身与净化空间。其符身较小，携带方便，通常以防小范围的邪气入侵。符身呈现出道家清净之象，简洁明了，带有保护气场的力量。此符适用于家居、办公室等地方，尤其有效于防止小规模的煞气与邪灵影响，确保周围环境的和谐与安宁。",
      "fuluTitle232": "辟邪符",
      "fuluDes232": "辟邪符，是最常用的道教符箓之一，主掌驱逐一切邪祟与负面能量，确保佩戴者及其家庭平安无事。其符文带有强大的镇压与化解作用，通常以符号中的“辟邪”二字为主，象征对邪气的彻底排除。此符用于护身、镇宅、驱邪，能够消除所有形式的负面气场，帮助佩戴者远离灾难与困厄，迎来好运。",
      "fuluTitle233": "普庵斩邪符",
      "fuluDes233": "普庵大德禅师所创斩邪符，主用于斩除邪祟与妖魔，镇压不良气场。符中融合了佛道的法力，携带强大的驱邪除怪能力，能够消解周围一切负面能量，清除邪恶气场。佩戴此符能够有效避免邪灵入侵，保护佩戴者的安全与平安，适用于居家、办公等环境。",
      "fuluTitle234": "普庵镇心符",
      "fuluDes234": "普庵镇心符，专责安抚心神，解除内心焦虑、烦恼与不安。此符融合了佛道的智慧，旨在帮助佩戴者保持心境平和，化解精神上的压力与困扰。特别适合那些情绪不稳或精神状态较差的人使用，能够帮助镇定心神，避免外界负面情绪的干扰，提升内心的稳定与平和。",
      "fuluTitle235": "普庵防小人符",
      "fuluDes235": "普庵防小人符，专为防止小人作祟、化解人际中的矛盾与不和而设。符身内含镇压小人之力，能够有效避免小人陷害与背后捣乱，化解周围环境中的小人干扰。佩戴此符，能增强周围环境的正气，确保人与人之间的和谐，保护佩戴者不受不良势力影响。",
      "fuluTitle236": "普庵急行符",
      "fuluDes236": "普庵急行符，专门为佩戴者提供急速行动的庇佑，能够提升行动速度与应变能力，尤其适用于需要快速反应与解决问题的情境。此符以普庵大德禅师的法力加持，帮助佩戴者应对紧急情况下的挑战，提升决策和行动效率，确保事态能够及时得到控制。",
      "fuluTitle237": "普庵急行符",
      "fuluDes237": "普庵急行符，专门为佩戴者提供急速行动的庇佑，能够提升行动速度与应变能力，尤其适用于需要快速反应与解决问题的情境。此符以普庵大德禅师的法力加持，帮助佩戴者应对紧急情况下的挑战，提升决策和行动效率，确保事态能够及时得到控制。",
      "fuluTitle238": "普庵沉天符",
      "fuluDes238": "普庵沉天符，专责镇压外来恶煞与邪气，确保周围环境的安宁。符身内含强大的镇压能量，能够对抗外界不安定的负面气场，起到沉天压地的作用。佩戴此符能够有效地压制强大的煞气，避免一切邪恶力量的侵袭，确保佩戴者与家宅的平安。",
      "fuluTitle239": "普庵护身灵符",
      "fuluDes239": "普庵护身灵符，具有强大的护身防护力量，能够屏蔽外界的恶气与邪灵，保护佩戴者免受外来侵扰。此符融合了佛道的力量与普庵法门的神力，专门用于抵御恶气、灾难与不良事件，确保佩戴者的安全与健康。适用于常常外出或生活在不安定环境中的人，提供全方位的防护。",
      "fuluTitle240": "普庵大病司命符",
      "fuluDes240": "普庵大病司命符，专门为化解大病、疾病困扰而设计。此符融合了普庵法门的治病能量，能够调和体内的气血，恢复健康。佩戴此符能有效辅助身体恢复，化解不良疾病气场，并通过普庵大德禅师的庇佑，保护佩戴者免受突发病痛的困扰。此符尤为适用于身体虚弱、长时间患病的人群。",
      "fuluTitle241": "普庵镇煞符",
      "fuluDes241": "普庵镇煞符，专门用于镇压外来煞气与不安定气场，保卫家宅、生活环境的安宁。此符的功能非常强大，能够彻底压制一切负能量与煞气，恢复正气，确保佩戴者免受灾难与邪祟的干扰。普庵镇煞符适用于防止外界煞气、负面情绪或妖邪的侵扰，是一种强有力的防护符。",
      "fuluTitle242": "普庵大病大斗符",
      "fuluDes242": "普庵大病大斗符，针对重病与困厄时的治疗与化解，融合普庵法门的治病能力与斗煞功效。此符能够帮助佩戴者化解重大疾病、斗战不利的运势，助力健康恢复。其强大的治病与驱邪功能，能够帮助佩戴者恢复身心的平衡，确保恢复健康的进程不受干扰。",
      "fuluTitle243": "普庵朱雷符",
      "fuluDes243": "普庵朱雷符，融合雷电与普庵法门的治病驱邪功能，能够带来震慑力极强的护佑。朱雷符能够化解极为顽固的邪气与煞气，起到震慑敌人、驱邪退祟的作用。佩戴此符，可以获得强大的能量支持，在面对困境与危险时能获得有效的保护。",
      "fuluTitle244": "普庵邓帅符",
      "fuluDes244": "普庵邓帅符，专为防护与镇压外部邪气而设，具有强大的破邪化煞之力。邓帅符能够有效地制止来自外界的负面力量与邪气，守护佩戴者免受侵扰。佩戴此符能够确保安全，避免被外界不良气场与邪灵所困扰。",
      "fuluTitle245": "普庵急行符",
      "fuluDes245": "普庵急行符，专门为佩戴者提供急速行动的庇佑，能够提升行动速度与应变能力，尤其适用于需要快速反应与解决问题的情境。此符以普庵大德禅师的法力加持，帮助佩戴者应对紧急情况下的挑战，提升决策和行动效率，确保事态能够及时得到控制。",
      "fuluTitle246": "普庵急行符",
      "fuluDes246": "普庵急行符，专门为佩戴者提供急速行动的庇佑，能够提升行动速度与应变能力，尤其适用于需要快速反应与解决问题的情境。此符以普庵大德禅师的法力加持，帮助佩戴者应对紧急情况下的挑战，提升决策和行动效率，确保事态能够及时得到控制。",
      "fuluTitle247": "普庵急行符",
      "fuluDes247": "普庵急行符，专门为佩戴者提供急速行动的庇佑，能够提升行动速度与应变能力，尤其适用于需要快速反应与解决问题的情境。此符以普庵大德禅师的法力加持，帮助佩戴者应对紧急情况下的挑战，提升决策和行动效率，确保事态能够及时得到控制。",
      "fuluTitle248": "普庵急行符",
      "fuluDes248": "普庵急行符，专门为佩戴者提供急速行动的庇佑，能够提升行动速度与应变能力，尤其适用于需要快速反应与解决问题的情境。此符以普庵大德禅师的法力加持，帮助佩戴者应对紧急情况下的挑战，提升决策和行动效率，确保事态能够及时得到控制。",
      "fuluTitle249": "普庵天师玉雷教符",
      "fuluDes249": "普庵天师玉雷教符，结合普庵法门与道教雷法的力量，具有强大的震慑能力。符中蕴含的玉雷法力能够驱除各种邪灵和煞气，保护佩戴者远离灾祸。佩戴此符能增强佩戴者的法力和气场，具备更强的防护和驱邪效果。",
      "fuluTitle250": "普庵镇心符",
      "fuluDes250": "普庵镇心符，帮助佩戴者调和内心的躁动与焦虑，保持心神安定。此符具有镇心安神的功效，帮助佩戴者消解烦恼与不安，保持内心的平和，减少外界情绪的干扰。适合精神压力较大或经常感到焦虑的人群，帮助提升内在的稳定性和情绪控制力。"
    }
  }
};