
import { 
    Flex, 
    Image,
    Text,
 } from "@chakra-ui/react";

import ImageEditor from "../../components/blueprint";

import { cdnUrl } from "../../utils";

function Blueprint() {
    return (
        <Flex
          width='100%'
          height='100vh'
          justifyContent='center'
          overflow="hidden"
          alignItems='center'
          direction='column'
          bgImage={`url(${cdnUrl}/BG2-1.svg)`}
          bgSize='100%'
        >
            <Flex pt='20'>
                <ImageEditor />
            </Flex>
        </Flex>
    )
}

export default Blueprint;